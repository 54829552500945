import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gt-subpage-hero',
  templateUrl: './subpage-hero.component.html',
  styleUrls: ['./subpage-hero.component.scss'],
})
export class SubpageHeroComponent {
  @Input() scheme: 'sand' | 'dark' = 'dark';
  @Input() illustrationMobilePosition: 'right-to-title' | 'below-desc';
  @Input() imagePositionWhenNextToCopy: 'right' | 'left';
  @Input() imagePositionWhenBelowCopy: 'right' | 'left';
  @Input() size: 'normal' | 'reducedForPageNavigation';
  @Input() subTitleTranslationString: string;
  @Input() userName?: string;
  @Input() title1TranslationString: string;
  @Input() titleTranslationStringAnim: string;
  @Input() title2TranslationString: string;
  @Input() textTranslationString: string;
  @Input() illustrationUrl: string;
  @Input() imgUrl: string;
  @Input() animationColor: string = '#fe8389';
  @Input() illustrationStyle = { top: '-35%', left: '33%', width: '37%' };
  @Input() imgAltText: string;
  @Input() animType: 'z' | 'linear' | 'wavy' = 'z';

  showAnimation = true;

  constructor(public translateService: TranslateService) {
    if (this.translateService.currentLang) {
      this.showAnimation = this.translateService.currentLang === 'en';
    }
    this.translateService.onLangChange.subscribe(langChange => {
      if (langChange.lang !== 'en') {
        this.showAnimation = false;
      }
    });
  }
}
