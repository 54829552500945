export interface DownloadMappingItem {
  assetId: string;
  downloadId: string;
  languageCode: string;
}

export const DownloadMapping: DownloadMappingItem[] = [
  {
    assetId: 'growthTalks_downloads_2x3_en.pptx',
    downloadId: '3eaeef32-1270-4147-b0e9-e389108e0ee0',
    languageCode: 'en',
  },
  {
    assetId: 'Well-being OnePager_for all employess_EN.pdf',
    downloadId: '426d4114-b3a3-4ceb-8a77-ba4495381e7b',
    languageCode: 'en',
  },
  {
    assetId: 'MSF_Keep_Stop_Start.pdf',
    downloadId: '745392af-7faf-4d90-bafa-e53340289c70',
    languageCode: 'en',
  },
  {
    assetId: 'MSF_Self-reflection_guide',
    downloadId: 'fda538c8-e2b9-4db2-9278-8a06bb9028f4',
    languageCode: 'en',
  },
  {
    assetId: 'MGC_Mission.pdf',
    downloadId: '21286e18-0f5a-432e-8192-a16914618777',
    languageCode: 'en',
  },
  {
    assetId: 'MyGrowth_Coaching–Discovery Sheet.pdf',
    downloadId: 'bd8013de-281b-49f5-aed1-2ef0960077da',
    languageCode: 'en',
  },
  {
    assetId: 'MyGrowth Coaching_Requirements to become a coach.pdf',
    downloadId: '88910bcf-fe26-4ba7-ba3e-46c8793f67d0',
    languageCode: 'en',
  },
  {
    assetId: 'My Community Learning_cheat sheet.pdf',
    downloadId: 'b0d4d146-a6d4-44ac-b6b3-b39366faebd4',
    languageCode: 'en',
  },
  {
    assetId: 'MyGrowth_Your guide to crafting a great people profile-1.pdf',
    downloadId: '2cad1506-01db-4bcf-9911-697bf09a5dcb',
    languageCode: 'en',
  },
  {
    assetId: '20221214_Shadowing_How to start.pdf',
    downloadId: '00902696-f9ea-4c6c-8b78-92770bcba106',
    languageCode: 'en',
  },
  {
    assetId: '20211011_download_mentoring.pdf',
    downloadId: '0369ddaa-440e-4d00-87a2-b68413619d42',
    languageCode: 'en',
  },
  {
    assetId: '20221214_Reverse Mentoring_How to start.pdf',
    downloadId: 'cd1b730c-205a-43cc-97d8-3c70bdf50b8f',
    languageCode: 'en',
  },
  {
    assetId: 'Job Tagging_short summary-1.pdf',
    downloadId: 'cc9579e6-3677-4d4d-8807-4ffc6267c661',
    languageCode: 'en',
  },
  {
    assetId: '211012_GT_Cheat_Sheet_Emp_GP_2.pptx',
    downloadId: 'c0a7f7c9-66b0-43e4-a650-dddfb35ec846',
    languageCode: 'en',
  },
  {
    assetId: '20220823_Well-being OnePager_for Leaders_EN.pdf',
    downloadId: '3acccf62-2403-4c3b-9f68-878cec78b6ea',
    languageCode: 'en',
  },
  {
    assetId: '221123_React_to_a_job_tag.pdf',
    downloadId: '35e04a0a-295d-4413-99bd-c9c6bf1159b5',
    languageCode: 'en',
  },
  {
    assetId: 'MSF_Self-reflection_guide.pdf',
    downloadId: 'fda538c8-e2b9-4db2-9278-8a06bb9028f4',
    languageCode: 'en',
  },
  {
    assetId: 'Well-being OnePager_for all employess_DE.pdf',
    downloadId: '674915e2-2adb-4f81-b4c1-4dc6f2e64826',
    languageCode: 'de',
  },
  {
    assetId: 'MSF_Self-reflection_guide.pdf',
    downloadId: 'bc811c45-1491-455b-8705-edb66229c553',
    languageCode: 'de',
  },
  {
    assetId: 'MSF_Keep_Stop_Start.pdf',
    downloadId: '2a339798-0458-45b2-a428-b7e446386038',
    languageCode: 'de',
  },
  {
    assetId: 'My Community Learning_cheat sheet.pdf',
    downloadId: 'c2b6071e-0c3a-40c3-bf86-a508063aee0f',
    languageCode: 'de',
  },
  {
    assetId: '20221214_Shadowing_How to start.pdf',
    downloadId: 'da5d6c9f-cf6a-44e3-9e5c-3a49df63a531',
    languageCode: 'de',
  },
  {
    assetId: 'Job Tagging_short summary-1.pdf',
    downloadId: '7ba01100-8ecc-478f-8fbb-609a1754ac4c',
    languageCode: 'de',
  },
  {
    assetId: '20210820_Siemens_GT_Cheat_Sheet_Emp_TR_1.pptx',
    downloadId: 'd5f3399d-24e3-49a4-9aff-ece5d988a447',
    languageCode: 'de',
  },
  {
    assetId: 'growthTalks_downloads_2x3_de.pptx',
    downloadId: 'c7be2034-1e5d-434f-b629-7c7f640cd2c6',
    languageCode: 'de',
  },
  {
    assetId: 'Intro_MyGrowth_DE.pptx',
    downloadId: '628bb23f-26f7-4d8c-a94c-ec010bb39692',
    languageCode: 'de',
  },
  {
    assetId: 'Intro_MyGrowth_EN.pptx',
    downloadId: '4b1eb4cd-3cb6-491e-862d-f414f82cd430',
    languageCode: 'en',
  },
  {
    assetId: '2301_Well-being OnePager_for Leaders_DE.pdf',
    downloadId: 'a458925c-2b87-4774-b094-e5700874032b',
    languageCode: 'de',
  },
  {
    assetId: 'My Community Learning_cheat sheet_de.pdf',
    downloadId: '450a1040-900d-4df7-8c1a-971f2d721238',
    languageCode: 'de',
  },
  {
    assetId: '20211011_download_shadowing.pdf',
    downloadId: '5bc7d19d-8dc0-47ac-afb7-27ce8f55a6cc',
    languageCode: 'de',
  },
  {
    assetId: '20211011_download_mentoring.pdf',
    downloadId: '09ef1202-b09b-4fea-bd37-c709264f28aa',
    languageCode: 'de',
  },
  {
    assetId: 'MyGrowth_Your guide to crafting a great people profile-1.pdf',
    downloadId: 'c4915402-d2a2-4a1b-83e2-a02c9e85e44b',
    languageCode: 'de',
  },
  {
    assetId: '221123_React_to_a_job_tag.pdf',
    downloadId: 'dca7e3ee-9e62-4522-980c-966cbb198e6b',
    languageCode: 'de',
  },
  {
    assetId: '20221130_Getting_Started_with_Growth_Talks-Testimonials_for_leaders.pdf',
    downloadId: 'd6d91a6e-a867-43c2-ac23-39a764d24b18',
    languageCode: 'de',
  },
  {
    assetId: '20221214_Reverse Mentoring_How to start.pdf',
    downloadId: '196161f1-beb9-4ba5-9ba8-cad711f3b42b',
    languageCode: 'de',
  },
  {
    assetId: '220920_Onepager_Feedforward_DE.pdf',
    downloadId: 'ee9ddebe-2705-449c-92b5-be2394a9c83d',
    languageCode: 'de',
  },
  {
    assetId: 'MSF_Keep_Stop_Start_de.pdf',
    downloadId: '0f655039-1f16-48c1-a2d9-12f97961b359',
    languageCode: 'de',
  },
  {
    assetId: 'MSF_Keep_Stop_Start_en.pdf',
    downloadId: '3bf56d05-4acf-4a07-9be8-f63a926a6486',
    languageCode: 'de',
  },
  {
    assetId: 'MyGrowth Coaching_Requirements to become a coach.pdf',
    downloadId: 'de58cf60-523f-4164-9c0c-3ef0513f8b19',
    languageCode: 'de',
  },
  {
    assetId: 'Well-being OnePager_for all employess_EN.pdf',
    downloadId: '16f2ef6a-0150-4ffd-ad02-feb8ecf2444d',
    languageCode: 'es',
  },
  {
    assetId: 'My Community Learning_cheat sheet.pdf',
    downloadId: '341cbe61-6498-4ceb-89f1-bbd86a4823fd',
    languageCode: 'es',
  },
  {
    assetId: '20221214_Shadowing_How to start.pdf',
    downloadId: 'a2ccfc01-4201-4762-ab10-a4695b3b6210',
    languageCode: 'es',
  },
  {
    assetId: '20211011_download_mentoring.pdf',
    downloadId: 'b283997a-fce8-4592-bab3-d0b1c1252864',
    languageCode: 'es',
  },
  {
    assetId: '20221214_Reverse Mentoring_How to start.pdf',
    downloadId: 'ed69004d-ede4-4335-b797-f6a45bcc3117',
    languageCode: 'es',
  },
  {
    assetId: 'Job Tagging_short summary-1.pdf',
    downloadId: 'd9b304db-9215-465e-ab2d-99bbd6dffb1f',
    languageCode: 'es',
  },
  {
    assetId: '211012_GT_Cheat_Sheet_Emp_GP_2.pptx',
    downloadId: '2d721f2e-12f7-4de9-95d3-4ddecd37c1c0',
    languageCode: 'es',
  },
  {
    assetId: '20210820_Siemens_GT_Cheat_Sheet_Man_EL_1.pptx',
    downloadId: 'c48b4672-fc23-4ebc-85e3-4881d2b33bc6',
    languageCode: 'es',
  },
  {
    assetId: '20211011_download_shadowing.pdf',
    downloadId: '5b1a257a-f2db-446a-a42f-8f7618d0b28b',
    languageCode: 'es',
  },
  {
    assetId: '20220630_Onepager_Feedforward.pdf ',
    downloadId: 'dd109e3d-b733-4ef5-b0a1-2035aff25614',
    languageCode: 'es',
  },
  {
    assetId: '20221130_Getting_Started_with_Growth_Talks-Testimonials_for_leaders.pdf',
    downloadId: '404cbd5a-8096-41ad-9d24-4d7ff9762402',
    languageCode: 'es',
  },
  {
    assetId: '221123_React_to_a_job_tag.pdf',
    downloadId: 'bb23c5d5-3d24-4e96-8293-27e38b3401ec',
    languageCode: 'es',
  },
  {
    assetId: 'growthTalks_downloads_2x3_en.pptx',
    downloadId: 'f32e32b6-a58c-4b81-b315-dfd13e68ee50',
    languageCode: 'es',
  },
  {
    assetId: 'MSF_Keep_Stop_Start_en.pdf',
    downloadId: 'e704cf99-06b5-4953-a98f-5ec14db32e77',
    languageCode: 'es',
  },
  {
    assetId: 'MyGrowth Coaching_Requirements to become a coach.pdf',
    downloadId: '1c6551ad-cc14-4aa0-afbd-191ff07c4837',
    languageCode: 'es',
  },
  {
    assetId: 'MyGrowth_Your guide to crafting a great people profile-1.pdf',
    downloadId: 'ba298f7f-465a-4cd4-9a8f-9957024c9973',
    languageCode: 'es',
  },
  {
    assetId: 'Well-being OnePager_for all employess_EN.pdf',
    downloadId: '9925d9bc-a565-4100-8343-8b90a6adb001',
    languageCode: 'fr-ca',
  },
  {
    assetId: 'My Community Learning_cheat sheet.pdf',
    downloadId: '4baf6f1b-1d9f-4f6c-8293-c7c310f6381c',
    languageCode: 'fr-ca',
  },
  {
    assetId: '20221214_Shadowing_How to start.pdf',
    downloadId: 'c1220051-db4d-4075-9d79-068ec736bd1b',
    languageCode: 'fr-ca',
  },
  {
    assetId: '20211011_download_mentoring.pdf',
    downloadId: 'a54fe500-cb69-472a-a516-8ebb183268a7',
    languageCode: 'fr-ca',
  },
  {
    assetId: '20221214_Reverse Mentoring_How to start.pdf',
    downloadId: '1dda8505-e293-4c7f-a7e9-59920cf02aaf',
    languageCode: 'fr-ca',
  },
  {
    assetId: 'Job Tagging_short summary-1.pdf',
    downloadId: '0816b499-f576-489d-a840-d803a00a8d54',
    languageCode: 'fr-ca',
  },
  {
    assetId: '20210820_Siemens_GT_Cheat_Sheet_Man_EL_1.pptx',
    downloadId: 'cac28f4f-85a3-45c8-8b39-57204ec51b4b',
    languageCode: 'fr-ca',
  },
  {
    assetId: '0210820_Siemens_GT_Cheat_Sheet_Emp_EL_2.pptx',
    downloadId: 'fcc065d6-42d4-4788-b0ca-05b7fe5e9738',
    languageCode: 'fr-ca',
  },
  {
    assetId: '20211011_download_shadowing.pdf',
    downloadId: '0394b6c0-abf0-49ab-a259-1978ecbd13cc',
    languageCode: 'fr-ca',
  },
  {
    assetId: '20220630_Onepager_Feedforward.pdf',
    downloadId: '3f4c85ff-bf35-49ef-b507-e4a873eaca01',
    languageCode: 'fr-ca',
  },
  {
    assetId: '20221130_Getting_Started_with_Growth_Talks-Testimonials_for_leaders.pdf',
    downloadId: '0660fe98-a6b2-48d5-bbe0-e5d033c08073',
    languageCode: 'fr-ca',
  },
  {
    assetId: '221123_React_to_a_job_tag.pdf',
    downloadId: 'd40c88fe-27ee-4b7c-a109-6700ca359f22',
    languageCode: 'fr-ca',
  },
  {
    assetId: 'growthTalks_downloads_2x3_en.pptx',
    downloadId: 'e23b77f5-698c-4df9-b528-23096d1bd6b0',
    languageCode: 'fr-ca',
  },
  {
    assetId: 'MSF_Keep_Stop_Start_en.pdf',
    downloadId: '696e669e-273f-4d6d-8c9b-609f958926cd',
    languageCode: 'fr-ca',
  },
  {
    assetId: 'MyGrowth Coaching_Requirements to become a coach.pdf',
    downloadId: 'ef1b46ad-a317-4ed6-98d5-475b7f8a4c58',
    languageCode: 'fr-ca',
  },
  {
    assetId: 'MyGrowth_Your guide to crafting a great people profile-1.pdf',
    downloadId: 'ba328c65-a6f3-46d7-9b6f-aeb5d6773079',
    languageCode: 'fr-ca',
  },
  {
    assetId: 'Well-being OnePager_for all employess_EN.pdf',
    downloadId: 'd889de45-32f1-4484-9e40-e3d11d3aeafa',
    languageCode: 'pl',
  },
  {
    assetId: 'My Community Learning_cheat sheet.pdf',
    downloadId: '73db2380-4d38-445b-97c8-7b3016b1ae21',
    languageCode: 'pl',
  },
  {
    assetId: '20221214_Shadowing_How to start.pdf',
    downloadId: '159a42dc-64bf-4771-95d4-6aa2b66e30ec',
    languageCode: 'pl',
  },
  {
    assetId: '20211011_download_mentoring.pdf',
    downloadId: '6f2a4984-3770-4ae4-9c3d-a7145db3ad1c',
    languageCode: 'pl',
  },
  {
    assetId: '20221214_Reverse%20Mentoring_How%20to%20start.pdf',
    downloadId: '1e8f2b09-bb1d-4c59-a224-f410d683aae6',
    languageCode: 'pl',
  },
  {
    assetId: 'Job Tagging_short summary-1.pdf',
    downloadId: '234f8312-16e2-481c-bb32-c0333b91dd38',
    languageCode: 'pl',
  },
  {
    assetId: '20210820_Siemens_GT_Cheat_Sheet_Man_EL_1.pptx',
    downloadId: '570de245-cd17-49b9-b988-028755bf6d87',
    languageCode: 'pl',
  },
  {
    assetId: '20210820_Siemens_GT_Cheat_Sheet_Emp_EL_1.pptx',
    downloadId: '628bca02-2888-4e1f-86e7-4d4967ec446e',
    languageCode: 'pl',
  },
  {
    assetId: '20211011_download_shadowing.pdf',
    downloadId: 'fce6e711-dede-4901-a667-aeb832a76b45',
    languageCode: 'pl',
  },
  {
    assetId: '20220630_Onepager_Feedforward.pdf',
    downloadId: 'f6be3b63-8241-4d12-a896-c63d83c7243d',
    languageCode: 'pl',
  },
  {
    assetId: '20221130_Getting_Started_with_Growth_Talks-Testimonials_for_leaders.pdf',
    downloadId: 'f2e07869-c4bd-46b7-8064-512fb5749b66',
    languageCode: 'pl',
  },
  {
    assetId: '211012_GT_Cheat_Sheet_Emp_GP_1.pptx',
    downloadId: 'caf01222-50c0-40bb-9301-a5a3a35a154f',
    languageCode: 'pl',
  },
  {
    assetId: '211012_GT_Cheat_Sheet_Emp_GP_2.pptx',
    downloadId: '56e71739-17c0-444b-a476-02e5f0680846',
    languageCode: 'pl',
  },
  {
    assetId: '221123_React_to_a_job_tag.pdf',
    downloadId: 'e3165d53-ffbf-48f6-9ed8-fd0aa29959d8',
    languageCode: 'pl',
  },
  {
    assetId: 'growthTalks_downloads_2x3_en.pptx',
    downloadId: '1d0e0807-4c21-491f-92e2-0eb3a2d9fbb4',
    languageCode: 'pl',
  },
  {
    assetId: 'MSF_Keep_Stop_Start_en.pdf',
    downloadId: 'fb39ad06-c481-4ba9-942b-f17324f80e0c',
    languageCode: 'pl',
  },
  {
    assetId: 'MyGrowth Coaching_Requirements to become a coach.pdf',
    downloadId: 'a8fba022-75d0-4dd2-8f46-15bf61ba6bcc',
    languageCode: 'pl',
  },
  {
    assetId: 'MyGrowth_Your guide to crafting a great people profile-1.pdf',
    downloadId: 'f66c47c5-18cd-4060-9d04-5047ee4b3be6',
    languageCode: 'pl',
  },
  {
    assetId: 'Well-being OnePager_for all employess_EN.pdf',
    downloadId: 'dfad9b1f-c2f5-40ee-a92d-e5408bd0ce97',
    languageCode: 'zh',
  },
  {
    assetId: 'My Community Learning_cheat sheet.pdf',
    downloadId: '0acad3a4-b23e-4d33-a09a-d5bc3f525113',
    languageCode: 'zh',
  },
  {
    assetId: '20221214_Shadowing_How to start.pdf',
    downloadId: '36e1c946-c77f-4ef7-a8ca-28eadc0da658',
    languageCode: 'zh',
  },
  {
    assetId: '20211011_download_mentoring.pdf',
    downloadId: '9a588525-f9a3-4475-8cf3-0b90b9bdf624',
    languageCode: 'zh',
  },
  {
    assetId: '20221214_Reverse Mentoring_How to start.pdf',
    downloadId: '23e3e998-94f0-489f-8804-0b1be87062e4',
    languageCode: 'zh',
  },
  {
    assetId: 'Job Tagging_short summary-1.pdf',
    downloadId: '1aa42529-0d1d-4f23-a0fc-6dc305caa9dd',
    languageCode: 'zh',
  },
  {
    assetId: '211012_GT_Cheat_Sheet_Emp_GP_1.pptx',
    downloadId: '19f95072-ed73-4e0a-bfb8-aa791c26aacb',
    languageCode: 'zh',
  },
  {
    assetId: '20210820_Siemens_GT_Cheat_Sheet_Man_EL_1.pptx',
    downloadId: '4da45bc6-bc4a-453d-a25f-76f60f4cabee',
    languageCode: 'zh',
  },
  {
    assetId: '20210820_Siemens_GT_Cheat_Sheet_Man_CE_2.pptx',
    downloadId: 'c266b94b-4bdb-49f0-9d2c-257fae5739ca',
    languageCode: 'zh',
  },
  {
    assetId: '20211011_download_shadowing.pdf',
    downloadId: '0f72a819-bca9-415d-ae01-3f6baabe7d1b',
    languageCode: 'zh',
  },
  {
    assetId: '20220630_Onepager_Feedforward.pdf',
    downloadId: 'ae962ed7-becb-49bb-997c-7e51c1d892d6',
    languageCode: 'zh',
  },
  {
    assetId: '20220630_Onepager_Feedforward.pdf',
    downloadId: '15389739-178f-4692-ba05-cb788995cad7',
    languageCode: 'en',
  },
  {
    assetId: '20221130_Getting_Started_with_Growth_Talks-Testimonials_for_leaders.pdf',
    downloadId: '718368d9-cc24-4502-9dfa-9531428ec450',
    languageCode: 'zh',
  },
  {
    assetId: '221123_React_to_a_job_tag.pdf',
    downloadId: '6720da39-8940-4a46-b56c-004716e27a6e',
    languageCode: 'zh',
  },
  {
    assetId: 'growthTalks_downloads_2x3_en.pptx',
    downloadId: '1cc3d27d-42c8-437a-95ee-a1a0bfedb2dc',
    languageCode: 'zh',
  },
  {
    assetId: 'MSF_Keep_Stop_Start_en.pdf',
    downloadId: '8925807a-6642-499b-9f38-99c71285a810',
    languageCode: 'zh',
  },
  {
    assetId: 'MyGrowth Coaching_Requirements to become a coach.pdf',
    downloadId: '3f65c638-1d6a-4d90-ac4f-a51216086238',
    languageCode: 'zh',
  },
  {
    assetId: 'MyGrowth_Your guide to crafting a great people profile-1.pdf',
    downloadId: '86560ecd-84ed-4c97-886f-dcf25fb52a79',
    languageCode: 'zh',
  },
];
