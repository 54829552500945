<div class="admin-dialog">
  <div class="content">
    <div class="dialog-header">
      <h4 class="heading strong">{{ "admin.translations.visibilityDialog.heading" | translate }}</h4>
      <span class="icon-Close" (click)="onClose()"></span>
    </div>
    <div>
      <div class="space-between">
        <h6>{{ "admin.translations.visibilityDialog.language" | translate }}</h6>
        <h6>{{ "admin.translations.visibilityDialog.inProd" | translate }}</h6>
      </div>

      <ul *ngFor="let language of data.languages; let i = index">
        <li class="space-between language-entry">
          <span class="strong">{{ language.code }}</span>
          <mat-checkbox [checked]="language.visible" (change)="onChange(i, $event)"></mat-checkbox>
        </li>
      </ul>
    </div>
    <br />
    <div class="action-area">
      <button class="btn btn-secondary" (click)="onClose()">{{ "admin.translations.visibilityDialog.cancelButton" | translate }}</button>
      <button class="btn btn-primary" (click)="onConfirm()">{{ "admin.translations.visibilityDialog.saveButton" | translate }}</button>
    </div>
  </div>
</div>
