import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'gt-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: StarRatingComponent,
      multi: true,
    },
  ],
})
export class StarRatingComponent implements ControlValueAccessor {
  @Input() rating: number = 0;
  @Output() ratingChanged = new EventEmitter<number>();

  onChange = rating => {};
  onTouched = () => {};

  touched: boolean = false;

  disabled: boolean = false;

  public hangleChange(index: number): void {
    this.markAsTouched();
    if (!this.disabled) {
      this.rating = index + 1;
      this.ratingChanged.emit(this.rating);
      this.onChange(this.rating);
    }
  }

  public writeValue(quantity: number): void {
    this.rating = quantity;
  }

  public registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  public markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  public setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
}
