<section class="sgt-round-list-card" [attr.data-scheme]="scheme">
  <div class="h2" *ngIf="sectionData.title || sectionData.titleAnim">
    <span [innerHTML]="sectionData.title | translate" *ngIf="sectionData.title"> </span>
    <gt-animated-underline *ngIf="sectionData.titleAnim"
      ><span class="strong"> {{ sectionData.titleAnim | translate }}</span></gt-animated-underline
    >
  </div>
  <ul [attr.data-columns]="sectionData.cards.length">
    <li *ngFor="let card of sectionData.cards">
      <img [src]="card.imgUrl" [alt]="card.imgAltText" />
      <div class="h3" data-text-align="center" *ngIf="card.title" [innerHTML]="card.title | translate"></div>
      <ul class="card-items" [ngClass]="listType">
        <li *ngFor="let item of card.points" [class]="color" [innerHTML]="item | translate"></li>
      </ul>
      <gt-custom-link *ngIf="card.linkUrl" [trackingString]="card.linkText" [url]="translateService.instant(card.linkUrl)" ng-prevent-drag="true">
        {{ card.linkText | translate }}
      </gt-custom-link>
    </li>
  </ul>
  <div style="grid-column: 2/14; margin-top: 30px">
    <ng-content></ng-content>
  </div>
</section>
