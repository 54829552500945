import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { flattenObject } from 'src/app/admin/translations/translations.component';

@Component({
  selector: 'gt-admin-recursive-translation-changes',
  templateUrl: './admin-recursive-translation-changes.component.html',
  styleUrls: ['./admin-recursive-translation-changes.component.scss'],
})
export class AdminRecursiveTranslationChangesComponent implements OnInit {
  constructor(private translate: TranslateService) {}

  @Input() data: any;
  @Input() path: string;
  @Input() selected: string[];
  @Output() select = new EventEmitter<{ path: string; checked: boolean }>();

  allSelected: boolean = false;

  ngOnInit(): void {}

  isObject(obj: any): boolean {
    return typeof obj === 'object';
  }

  isSelected(path: string): boolean {
    if (!this.selected) {
      return false;
    }
    return this.selected.includes(path);
  }

  areAllChildrenSelected(path: string, obj: any): boolean {
    let counter = 0;
    const keys = Object.keys(flattenObject(obj)).map(k => `${path}.${k}`);
    keys.forEach(key => {
      if (this.isSelected(key)) {
        counter += 1;
      }
    });
    this.allSelected = counter === keys.length;
    return counter === keys.length;
  }

  passEvent(event: { path: string; checked: boolean }): void {
    this.select.emit(event);
  }

  onSelect(path: string, event: any): void {
    this.select.emit({ path, checked: event.checked });
  }

  onSelectAll(path: string, obj: any): void {
    this.allSelected = !this.allSelected;
    const keys = Object.keys(flattenObject(obj)).map(k => `${path}.${k}`);

    for (let i = 0; i < keys.length; i++) {
      this.select.emit({ path: keys[i], checked: this.allSelected });
    }
  }
}
