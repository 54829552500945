import { Component, Input } from '@angular/core';
import { TRACKING_ITEM } from '../../enums/tracking_item.enum';

@Component({
  selector: 'gt-subnavigation',
  templateUrl: './subnavigation.component.html',
  styleUrls: ['./subnavigation.component.scss'],
})
export class SubnavigationComponent {
  @Input() subNavLinks: { name: string; link: string }[];
  @Input() section: 'learning' | 'career' | 'growth-talks' | 'help' | 'self-reflection';

  public mobileNavOpen = false;
  public TRACKING_ITEM = TRACKING_ITEM;
}
