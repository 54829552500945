import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ISelfReflectionJourneyUserData } from '../interfaces/ISelfReflectionJourneyUserData';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ISrjCompletedStep, ISrjCompletedStepBEModel } from '../interfaces/ISrjCompeltedStep';
import { Select, Store } from '@ngxs/store';
import { IUserData } from '../interfaces/IUserData';
import { UserState } from '../state-management/user/user.state';
import { filter, tap } from 'rxjs/operators';
import { SRJ_DIMENSIONS } from '../enums/srj_dimensions.enum';
import { SRJ_TOPIC } from '../enums/srj_topic.enum';
import {
  UpdateSRJSelectedTopic,
  UpdateSRJSelectedDimension,
  UpdateSRJGoal,
  UpdateSRJSelectedValues,
  UpdateSRJHelpfulValue,
  UpdateSRJUnhelpfulValue,
  UpdateSRJMnemonicPhrases,
  UpdateSRJDuration,
  UpdateSRJTimeSlot,
} from '../state-management/selfReflectionJourney/selfReflectionJourney.actions';
import { SRJ_VALUES } from '../enums/srj_values.enum';
import { selfReflectionJourneyConfig } from 'src/app/self-reflection-journeys/self-reflection-journey/srj.config';
import { srjTopics } from 'src/app/self-reflection-journeys/self-reflection-journey/srjTopics';

@Injectable({
  providedIn: 'root',
})
export class SelfReflectionJourneyService {
  @Select(UserState.loggedUser) userData$: Observable<IUserData>;
  private userGid: string;
  private saveAndCloseSource = new BehaviorSubject(false);
  public saveAndClose = this.saveAndCloseSource.asObservable();

  constructor(
    private http: HttpClient,
    private store: Store,
  ) {
    this.getUserData();
  }

  private getUserData(): void {
    this.userData$
      .pipe(
        filter(userData => !!userData),
        tap(userData => {
          this.userGid = userData.gid;
        }),
      )
      .subscribe();
  }

  public saveAndCloseUpdate(value: boolean): void {
    this.saveAndCloseSource.next(value);
  }

  public getSelfReflectionJourneyStatus(): Observable<ISelfReflectionJourneyUserData> {
    const mockedData = null;
    return of(mockedData);
  }

  public getSelfReflectionJourneyConfig(): Observable<any[]> {
    return of(selfReflectionJourneyConfig);
  }

  public getSRJUserData(): Observable<ISelfReflectionJourneyUserData> {
    return this.http.get<ISelfReflectionJourneyUserData>(environment.backendUrl + 'users/' + this.userGid + '/self-reflection-journey');
  }

  public saveSelfReflectionRecord(completedSteps?: ISrjCompletedStepBEModel[]): Observable<ISelfReflectionJourneyUserData> {
    return this.http.post<ISelfReflectionJourneyUserData>(environment.backendUrl + 'users/' + this.userGid + '/self-reflection-journey', {
      steps: completedSteps ? completedSteps : [],
    });
  }

  public updateSRJCompletedSteps(completedStep: ISrjCompletedStep): Observable<ISrjCompletedStepBEModel> {
    const newStep: ISrjCompletedStepBEModel = {
      stepId: completedStep.stepId,
    };
    if (completedStep.data) {
      newStep.userInput = {
        inputName: completedStep.stepId,
        value: completedStep.data.value,
      };
    }
    return this.http.post<ISrjCompletedStepBEModel>(environment.backendUrl + 'users/' + this.userGid + '/self-reflection-journey/steps', newStep);
  }

  public resetStatus(): Observable<any> {
    return this.http.delete<any>(environment.backendUrl + 'users/' + this.userGid + '/self-reflection-journey');
  }

  public updateStateData(completedSteps: ISrjCompletedStepBEModel[]): void {
    completedSteps.forEach(step => {
      switch (step.stepId) {
        case 'stage1_3-2-1':
          this.store.dispatch(new UpdateSRJSelectedTopic(step.userInput.value));
          break;
        case 'stage1_3-1-1':
          this.store.dispatch(new UpdateSRJSelectedTopic(SRJ_TOPIC[step.userInput.value]));
          const dimension = srjTopics.filter(topic => topic.topicTitle === step.userInput.value)[0].dimension;
          this.store.dispatch(new UpdateSRJSelectedDimension(dimension));
          break;
        case 'stage1_3-2-3':
          this.store.dispatch(new UpdateSRJSelectedDimension(SRJ_DIMENSIONS[step.userInput.value]));
          break;
        case 'stage1_5-2-7':
          this.store.dispatch(new UpdateSRJGoal(step.userInput.value));
          break;
        case 'stage1_5-1':
          this.store.dispatch(new UpdateSRJGoal(step.userInput.value));
          break;
        case 'stage2_9':
          this.store.dispatch(new UpdateSRJSelectedValues(step.userInput.value.split(',').map(s => SRJ_VALUES[s])));
          break;
        case 'stage3_2':
          this.store.dispatch(new UpdateSRJHelpfulValue(SRJ_VALUES[step.userInput.value]));
          break;
        case 'stage3_5':
          this.store.dispatch(new UpdateSRJUnhelpfulValue(SRJ_VALUES[step.userInput.value]));
          break;
        case 'stage3_4':
          this.store.dispatch(new UpdateSRJMnemonicPhrases([step.userInput.value]));
          break;
        case 'stage3_7':
          this.store.dispatch(new UpdateSRJMnemonicPhrases([step.userInput.value]));
          break;
        case 'stage4_5':
          this.store.dispatch(new UpdateSRJDuration(step.userInput.value));
          break;
        case 'stage4_6':
          this.store.dispatch(new UpdateSRJTimeSlot(step.userInput.value));
          break;
      }
    });
  }
}
