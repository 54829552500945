import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AudioService {
  public getAudioUrl(audioId: string, lang: string = 'en'): Observable<string> {
    return of('assets/audio/' + lang + '/' + audioId + '.mp3');
  }
}
