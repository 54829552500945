import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Md5 } from 'ts-md5';
import { TrackingSuiteService, TrackingRecord } from '@siemens/tracking-suite-angular-lib';
import { Observable, Subject, Subscription, zip } from 'rxjs';
import { TRACKING_ITEM } from '../enums/tracking_item.enum';
import { Select } from '@ngxs/store';
import { UserState } from '../state-management/user/user.state';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { IUserData } from '../interfaces/IUserData';

@Injectable({
  providedIn: 'root',
})
export class TrackingService implements OnDestroy {
  @Select(UserState.loggedUser) loggedUser$: Observable<IUserData>;
  @Select(UserState.accessToken) accessToken$: Observable<string>;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  userData: IUserData;
  guid: string; // hashed from GID
  visitToken: string; // hashed from accessToken
  engTranslations;
  rawAccessToken;
  subs: Subscription[] = [];

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private readonly trackingSuiteService: TrackingSuiteService,
    private activatedRoute: ActivatedRoute,
  ) {
    window['ste_statistic'] = window['ste_statistic'] || [];
    this.getUserData();
    this.loadEnglishTranslsations();
  }

  private getUserData(): void {
    zip(this.accessToken$, this.loggedUser$)
      .pipe(
        filter(([accesToken, userData]) => !!accesToken && !!userData),
        tap(([accesToken, userData]) => {
          this.userData = userData;
          this.guid = Md5.hashStr(userData.gid);
          this.visitToken = Md5.hashStr(accesToken);
          this.rawAccessToken = accesToken;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private loadEnglishTranslsations(): void {
    this.subs.push(
      this.translateService.getTranslation('en').subscribe(value => {
        if (value) {
          this.engTranslations = value;
        }
      }),
    );
  }

  public getEngTranslation(transKey: string): string {
    if (this.engTranslations) {
      const transl = transKey.split('.').reduce((p, c) => (p && p[c]) || null, this.engTranslations);
      return transl ? transl : transKey;
    } else {
      this.loadEnglishTranslsations();
      this.getEngTranslation(transKey);
    }
  }

  addTrackingItem(itemType: TRACKING_ITEM, item: string, resultCount?: number) {
    const newTrackingRecord = this.setUpNewRecordFormat(itemType, item);
    this.useNewTrackingService(newTrackingRecord);
    if (itemType === 'route' || itemType === 'faq') {
      this.addAdobetrackingItem(itemType, item, resultCount);
    }
  }

  private setUpNewRecordFormat(itemType: TRACKING_ITEM, item: string): TrackingRecord {
    let action: 'click' | 'navigate' | 'download' | 'upload' | 'bb' = 'click';
    if (itemType === 'route') {
      action = 'navigate';
    } else if (itemType === 'downloads') {
      action = 'download';
    } else {
      item = itemType + ' - ' + item;
    }
    return {
      accessToken: this.rawAccessToken,
      userGid: this.guid,
      actionType: action,
      actionValue: item,
      orgCode: this.userData && this.userData.orgCode ? this.userData.orgCode : 'unset',
      language: this.translateService.currentLang ?? 'en',
      userCountry: this.userData && this.userData.country ? this.userData.country : 'unset',
    };
  }

  private addAdobetrackingItem(itemType: 'route' | 'faq', item: string, resultCount?: number): void {
    let trackingData;
    if (itemType === 'route') {
      trackingData = this.getAdobeRouteTrackingData();
    } else if (itemType === 'faq') {
      if (item.includes('expandedFAQ')) {
        trackingData = this.getAdobeCollapsibleTrackingData(item);
      } else {
        trackingData = this.getAdobeSearchTrackingData(item.replace('searchBy:', ''), resultCount); // adobeanalytics asked for the string without the 'searchBy:' suffix
      }
    }
    window['ste_statistic'].push(trackingData);

    // logic added to remove the sitc param after we sent the adobe tracking data
    // MS 13.11.23 I think we should not remove the sitc parameter ourselfes? The script will take care of that.
    /*
    if (itemType === 'route' && item.includes('sitc')) {
     this.router.navigate([], {
       queryParams: {
         'sitc': null
       },
       fragment: item.includes('#') ? item.split('#').pop() : null,
       queryParamsHandling: 'merge'
     });
   }*/
  }

  private getLanguage(): string {
    // using the route param instead of the translate.service.currentLang because we send the tracking request before the lang is updated
    return this.activatedRoute.snapshot.queryParams.lang ? this.activatedRoute.snapshot.queryParams.lang : 'en';
  }

  public cleanRoute(route: string): string {
    if (route && route.includes('?')) {
      const startIndex = route.indexOf('?');
      return route.substring(0, startIndex);
    }
    return route;
  }

  private getAdobeRouteTrackingData() {
    const orgCode = this.userData && this.userData.orgCode ? this.userData.orgCode.split(' ') : null;
    return {
      action: 'page.ready',
      data: {
        page: {
          name: this.router.url.split('?')[0],
          country: 'WW',
          language: this.getLanguage(),
        },
        user: {
          country: this.userData ? this.userData.country : '',
          unit: orgCode ? orgCode[0] + ' ' + orgCode[1] : '',
        },
      },
    };
  }

  private getAdobeCollapsibleTrackingData(item: string) {
    return {
      action: 'collapsible.open',
      data: {
        collapsible: {
          name: item,
        },
      },
      clickTarget: '',
    };
  }

  private getAdobeSearchTrackingData(item: string, resultCount: number) {
    return {
      action: 'search.search',
      data: {
        search: {
          term: item,
          source: 'Main Search',
          resultCount: resultCount,
        },
      },
    };
  }

  private useNewTrackingService(record: TrackingRecord): void {
    this.trackingSuiteService.send(record).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.subs.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
