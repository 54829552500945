import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TrackingService } from 'src/app/shared/services/tracking.service';
import { gsap } from 'gsap';
import { MatDialog } from '@angular/material/dialog';
import { Calendar23DialogComponent } from './calendar23-dialog/calendar23-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { TRACKING_ITEM } from 'src/app/shared/enums/tracking_item.enum';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { IUserConfig } from 'src/app/shared/interfaces/IUserConfig';
import { UserState } from 'src/app/shared/state-management/user/user.state';
import { tap, takeUntil } from 'rxjs/operators';
import { UpdateUserConfigCalendar } from 'src/app/shared/state-management/user/user.actions';

@Component({
  selector: 'gt-calendar23',
  templateUrl: './calendar23.component.html',
  styleUrls: ['./calendar23.component.scss'],
})
export class Calendar23Component implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('animContainer') animContainer: ElementRef;
  @Select(UserState.userConfig) userConfig$: Observable<IUserConfig>;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  tl: gsap.core.Timeline;
  public viewedItems: string[];

  constructor(
    public dialog: MatDialog,
    private trackingService: TrackingService,
    public translate: TranslateService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.getUserConfig();
  }

  async ngAfterViewInit(): Promise<void> {
    const animPathes1 = Array.from(this.animContainer.nativeElement.querySelectorAll('.visited > .animation > path'));
    this.tl = gsap.timeline();
    this.tl.to(animPathes1, { opacity: 1 });
    this.tl.fromTo(
      animPathes1,
      {
        drawSVG: '0',
      },
      {
        duration: 1,
        drawSVG: '100%',
      },
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.tl?.kill();
  }

  private getUserConfig(): void {
    this.userConfig$
      .pipe(
        tap(userConfig => {
          this.viewedItems = userConfig.calendar['202312'] ? userConfig.calendar['202312'].map(String) : [];
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  public clicked(day: string, color: string): void {
    this.trackClick(day);
    this.saveViewedItem(day);
    this.animateStep(day);
    setTimeout(() => {
      this.openDialog(day, color);
    }, 1500);
  }

  private trackClick(day: string): void {
    this.trackingService.addTrackingItem(TRACKING_ITEM.calendar, 'day:' + day);
  }

  private saveViewedItem(day: string): void {
    if (!this.viewedItems.includes(day)) {
      this.viewedItems.push(day);
      this.store.dispatch(new UpdateUserConfigCalendar({ ['202312']: this.viewedItems.map(Number) }));
    }
  }

  private animateStep(dayNumber: string): void {
    const querySelector = '#day' + dayNumber + ' >.animation>path';
    const animPathes2 = Array.from(this.animContainer.nativeElement.querySelectorAll(querySelector));
    this.tl.staggerFromTo(
      animPathes2,
      0.2,
      {
        drawSVG: 0,
      },
      {
        drawSVG: '100%',
        ease: 'linear',
      },
      0.2,
    );
  }

  private openDialog(day: string, color: string): void {
    this.dialog.open(Calendar23DialogComponent, {
      width: '100vw',
      maxHeight: '900px',
      panelClass: 'calendar23Dialog',
      data: {
        day: day,
        color: color,
      },
    });
  }
}
