import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { gsap } from 'gsap';

@Component({
  selector: 'gt-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss'],
})
export class LoadingIndicatorComponent implements AfterViewInit, OnDestroy {
  @ViewChild('animContainer') animContainer: ElementRef;
  tl: gsap.core.Timeline;

  constructor() {}

  async ngAfterViewInit(): Promise<void> {
    const animPathes1 = Array.from(this.animContainer.nativeElement.querySelectorAll('path'));
    const potPaths = Array.from(this.animContainer.nativeElement.querySelectorAll('#pot > path'));
    const part1Paths = Array.from(this.animContainer.nativeElement.querySelectorAll('#part1 > path, #part1 > g > path'));
    const part2Paths = Array.from(this.animContainer.nativeElement.querySelectorAll('#part2 > path, #part2 > g > path'));
    const part3Paths = Array.from(this.animContainer.nativeElement.querySelectorAll('#part3 > path, #part3 > g > path'));
    const part4Paths = Array.from(this.animContainer.nativeElement.querySelectorAll('#part4 > path, #part4 > g > path'));
    const part5Paths = Array.from(this.animContainer.nativeElement.querySelectorAll('#part5 > path, #part5 > g > path'));
    const part6Paths = Array.from(this.animContainer.nativeElement.querySelectorAll('#part6 > path, #part6 > g > path'));
    const part7Paths = Array.from(this.animContainer.nativeElement.querySelectorAll('#part7 > path, #part7 > g > path'));
    this.tl = gsap.timeline();
    // this.tl.to(animPathes1, {opacity: 1});
    this.tl.fromTo(
      potPaths,
      {
        drawSVG: '0',
      },
      {
        duration: 0.6,
        drawSVG: '100%',
      },
    );
    this.tl.fromTo(
      part1Paths,
      {
        drawSVG: '0',
      },
      {
        duration: 3,
        drawSVG: '100%',
      },
    );
    this.tl.fromTo(
      part2Paths,
      {
        drawSVG: '0',
      },
      {
        duration: 5,
        drawSVG: '100%',
      },
      1.8,
    );
    this.tl.fromTo(
      part3Paths,
      {
        drawSVG: '0',
      },
      {
        duration: 4,
        drawSVG: '100%',
      },
      4,
    );
    this.tl.fromTo(
      part4Paths,
      {
        drawSVG: '0',
      },
      {
        duration: 5,
        drawSVG: '100%',
      },
      3,
    );
    this.tl.fromTo(
      part5Paths,
      {
        drawSVG: '0',
      },
      {
        duration: 2,
        drawSVG: '100%',
      },
      '-=2.5',
    );
    this.tl.fromTo(
      part6Paths,
      {
        drawSVG: '0',
      },
      {
        duration: 3,
        drawSVG: '100%',
      },
      '-=5',
    );
    this.tl.fromTo(
      part7Paths,
      {
        drawSVG: '0',
      },
      {
        duration: 5,
        drawSVG: '100%',
      },
      7,
    );
    this.tl.repeat(-1);
  }

  ngOnDestroy(): void {
    this.tl?.kill();
  }
}
