import { Action, Selector, State, StateContext } from '@ngxs/store';
import { IMyGrowthUserProfile } from '../../interfaces/IMyGrowthProfile';
import { IUserConfig } from '../../interfaces/IUserConfig';
import { UserRolesEnum } from '../../enums/user_roles.enum';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { GetAuthData, GetUserConfig, GetUserProfile, UpdateUserConfigCalendar, UpdateUserConfigSurvey, UpdateUserConfigVideo } from './user.actions';
import { UserOperations } from '../../operations/user.operations';
import { AuthService } from 'src/app/auth/services/new-auth.service';
import { catchError, filter, take, takeUntil, tap } from 'rxjs/operators';
import { IUserData } from '../../interfaces/IUserData';
import { UserService } from '../../services/user.service';
import _ from 'lodash';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';

export class UserStateModel {
  loggedUser: IUserData;
  isAuthenticated: boolean;
  accessToken: string;
  myGrowthUserProfile: IMyGrowthUserProfile;
  userConfig: IUserConfig;
}
@State<UserStateModel>({
  name: 'user',
  defaults: {
    loggedUser: null,
    isAuthenticated: false,
    accessToken: null,
    myGrowthUserProfile: null,
    userConfig: null,
  },
})
@Injectable()
export class UserState implements OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private authService: AuthService,
    private userService: UserService,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  @Selector()
  static loggedUser(state: UserStateModel): IUserData {
    return state.loggedUser;
  }

  @Selector()
  static isAuthenticated(state: UserStateModel): boolean {
    return state.isAuthenticated;
  }

  @Selector()
  static AuthData(state: UserStateModel): { userData: IUserData; isAuthenticated: boolean } {
    return { userData: state.loggedUser, isAuthenticated: state.isAuthenticated };
  }

  @Selector()
  static roles(state: UserStateModel): UserRolesEnum[] {
    return state.myGrowthUserProfile.roles;
  }

  @Selector()
  static myGrowthUserProfile(state: UserStateModel): IMyGrowthUserProfile {
    return state.myGrowthUserProfile;
  }

  @Selector()
  static userConfig(state: UserStateModel): IUserConfig {
    return state.userConfig;
  }

  @Selector()
  static accessToken(state: UserStateModel): string {
    return state.accessToken;
  }

  @Action(GetAuthData)
  GetAuthData(ctx: StateContext<UserStateModel>): void {
    this.authService
      .getAuthInfo()
      .pipe(
        tap(authData => {
          ctx.patchState({
            loggedUser: UserOperations.authServiceUserDataToUserData(authData.userData),
            isAuthenticated: authData.isAuthenticated,
            accessToken: authData.accessToken,
          });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  @Action(GetUserProfile)
  GetUserProfile(ctx: StateContext<UserStateModel>): void {
    this.userService
      .getMyGrotwhUserProfile()
      .pipe(
        filter(user => !!user),
        tap(user => {
          ctx.patchState({
            myGrowthUserProfile: user,
          });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  @Action(GetUserConfig)
  GetUserConfig(ctx: StateContext<UserStateModel>): Observable<IUserConfig> {
    const userGid = ctx.getState().loggedUser.gid;
    return this.userService.getUserConfig(userGid).pipe(
      catchError(err => {
        console.log('caught rethrown error, providing fallback value');
        return this.userService.createUserConfig(userGid, {
          calendar: null,
          survey: null,
          video: null,
        });
      }),
      tap(userConfig => {
        ctx.patchState({
          userConfig: userConfig,
        });
      }),
    );
  }

  @Action(UpdateUserConfigCalendar)
  UpdateUserConfigCalendar(ctx: StateContext<UserStateModel>, payload: { [yearMonth: string]: number[] }): void {
    const state = ctx.getState();
    const partiallyConfig = {
      calendar: state.userConfig && state.userConfig.calendar ? _.merge(state.userConfig.calendar, payload.calendar) : payload.survey,
    };
    this.userService
      .saveUserConfig(state.loggedUser.gid, partiallyConfig)
      .pipe(
        tap(updatedUserConfig => {
          ctx.patchState({
            userConfig: updatedUserConfig,
          });
        }),
      )
      .subscribe();
  }

  @Action(UpdateUserConfigSurvey)
  UpdateUserConfigSurvey(
    ctx: StateContext<UserStateModel>,
    payload: {
      [surveyConfigId: string]: {
        status: 'later' | 'no' | 'completed';
      };
    },
  ): void {
    const state = ctx.getState();
    const partiallyConfig = {
      survey: state.userConfig && state.userConfig.survey ? _.merge(state.userConfig.survey, payload.survey) : payload.survey,
    };
    this.userService
      .saveUserConfig(state.loggedUser.gid, partiallyConfig)
      .pipe(
        tap(updatedUserConfig => {
          ctx.patchState({
            userConfig: updatedUserConfig,
          });
        }),
      )
      .subscribe();
  }

  @Action(UpdateUserConfigVideo)
  UpdateUserConfigVideo(ctx: StateContext<UserStateModel>, payload: { [videoId: string]: { status: 'hide' } }): void {
    const state = ctx.getState();
    const partiallyConfig = {
      video: state.userConfig && state.userConfig.video ? _.merge(state.userConfig.video, payload.video) : payload.video,
    };
    this.userService
      .saveUserConfig(state.loggedUser.gid, partiallyConfig)
      .pipe(
        tap(updatedUserConfig => {
          ctx.patchState({
            userConfig: updatedUserConfig,
          });
        }),
      )
      .subscribe();
  }
}
