import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Select } from '@ngxs/store';
import { Observable, Subject, Subscription, takeUntil, tap } from 'rxjs';
import { UserState } from '../shared/state-management/user/user.state';
import { IUserData } from '../shared/interfaces/IUserData';
import { ActivePopupsService } from '../shared/services/active-popups.service';
import { POPUP_TYPE } from '../shared/enums/popup_type.enum';

@Component({
  selector: 'gt-my-growth-home',
  templateUrl: './my-growth-home.component.html',
  styleUrls: ['./my-growth-home.component.scss'],
})
export class MyGrowthHomeComponent implements OnInit, OnDestroy {
  whatIsItContent = {
    animationColor: '#00D7A0',
    title1: 'myGrowth.home.whatIsIt.title1',
    titleAnim: 'myGrowth.home.whatIsIt.titleAnim',
    desc: 'myGrowth.home.whatIsIt.desc',
    cards: [
      {
        imgUrl: 'assets/images/round_cards/homepage/Self-reflect.jpg',
        imgAltText: 'Illustration of a head processing information',
        title: 'myGrowth.home.whatIsIt.cards.card1.title',
        text: 'myGrowth.home.whatIsIt.cards.card1.text',
      },
      // {imgUrl: 'assets/images/round_cards/homepage/Strengths.jpg', title: 'myGrowth.home.whatIsIt.cards.card2.title', text: 'myGrowth.home.whatIsIt.cards.card2.text'},
      {
        imgUrl: 'assets/images/round_cards/homepage/upskill.jpg',
        imgAltText: 'Illustration of a hand confidently holding a pencil',
        title: 'myGrowth.home.whatIsIt.cards.card3.title',
        text: 'myGrowth.home.whatIsIt.cards.card3.text',
      },
      {
        imgUrl: 'assets/images/round_cards/homepage/Shape_carreer.jpg',
        imgAltText: 'Illustration of a hand holding a puzzle piece',
        title: 'myGrowth.home.whatIsIt.cards.card4.title',
        text: 'myGrowth.home.whatIsIt.cards.card4.text',
      },
    ],
  };
  growthMindsetContent = {
    color: '#00D7A0',
    subtitle: 'myGrowth.home.mindset.subtitle',
    title: 'myGrowth.home.mindset.title',
    titleAnim: 'myGrowth.home.mindset.titleAnim',
    text1: 'myGrowth.home.mindset.text1',
    imgUrl: 'assets/images/coffee-break-in-the-office.jpg',
    text2: 'myGrowth.home.mindset.text2',
    actionButton: 'myGrowth.home.mindset.actionButton',
    btnIntLink: '/self-reflection#subnav',
    imgAltText: 'A smiling woman leans against a facebrick wall and an illustrated thought bubble suggests processing and reflection',
  };
  strengthsContent = {
    subTitle: 'myGrowth.home.strengths.subtitle',
    titleAnim: 'myGrowth.home.strengths.titleAnim',
    title2: 'myGrowth.home.strengths.title2',
    desc: 'myGrowth.home.strengths.desc',
    btnUrl: '/self-reflection/strengths',
    btnText: 'myGrowth.home.strengths.btnText',
  };

  upskillContent = {
    animationColor: '#00D7A0',
    subtitle: 'myGrowth.home.upskill.subtitle',
    titleAnim: 'myGrowth.home.upskill.titleAnim',
    title2: 'myGrowth.home.upskill.title2',
    desc: 'myGrowth.home.upskill.desc',
    prompt: 'myGrowth.home.upskill.prompt',
    buttonLink: '/learning',
    cards: [
      {
        imgUrl: 'assets/images/round_cards/homepage/Learning_formats.jpg',
        imgAltText: 'Illustration of an open book',
        title: 'myGrowth.home.upskill.cards.card1.title',
        text: 'myGrowth.home.upskill.cards.card1.text',
      },
      {
        imgUrl: 'assets/images/round_cards/homepage/Features.jpg',
        imgAltText: 'Illustration of a star and a crown and a heart joined together in a circle',
        title: 'myGrowth.home.upskill.cards.card2.title',
        text: 'myGrowth.home.upskill.cards.card2.text',
      },
      {
        imgUrl: 'assets/images/round_cards/homepage/Partners.jpg',
        imgAltText: 'Illustration of two hands high fiving',
        title: 'myGrowth.home.upskill.cards.card3.title',
        text: 'myGrowth.home.upskill.cards.card3.text',
      },
    ],
  };

  careerContent = {
    subTitle: 'myGrowth.home.career.subtitle',
    title1: 'myGrowth.home.career.title1',
    titleAnim: 'myGrowth.home.career.titleAnim',
    desc: 'myGrowth.home.career.desc',
    btnUrl: '/career',
    btnText: 'myGrowth.home.career.btnText',
    imgUrl: '/assets/images/businessman-drawing.svg',
  };

  pointsContent = {
    cards: [
      {
        imgUrl: 'assets/icons/myGrowth-home/Shadowing.png',
        imgAltText: 'Illustration of two people back to back',
        title: 'myGrowth.home.points.card1.title',
        points: ['myGrowth.home.points.card1.point1', 'myGrowth.home.points.card1.point2', 'myGrowth.home.points.card1.point3'],
      },
      {
        imgUrl: 'assets/icons/myGrowth-home/Mentoring.png',
        imgAltText: 'Illustration of two hands reaching out to each other',
        title: 'myGrowth.home.points.card3.title',
        points: ['myGrowth.home.points.card3.point1', 'myGrowth.home.points.card3.point2', 'myGrowth.home.points.card3.point3'],
      },
      {
        imgUrl: 'assets/icons/myGrowth-home/OpenJobMarket.png',
        imgAltText: 'Illustration of a magnifying glass',
        title: 'myGrowth.home.points.card2.title',
        points: ['myGrowth.home.points.card2.point1', 'myGrowth.home.points.card2.point2', 'myGrowth.home.points.card2.point3'],
      },
    ],
  };

  growthTalksContent = {
    subTitle: 'myGrowth.home.growthTalks.subtitle',
    title1: 'myGrowth.home.growthTalks.title1',
    desc: 'myGrowth.home.growthTalks.desc',
    btnUrl: '/growth-talks',
    btnText: 'myGrowth.home.growthTalks.btnText',
    imgUrl: '/assets/images/man-thinking.svg',
  };

  keyElementsContent = {
    title1: 'myGrowth.home.keyElements.title',
    desc: 'myGrowth.home.keyElements.desc',
    tiles: [
      {
        title: 'growthTalks.home.keyElements.element1.title',
        desc: 'growthTalks.home.keyElements.element1.desc',
        linkUrl: './growth-talks/how-it-works/0',
        btnText: 'growthTalks.home.keyElements.element1.prompt',
        imgUrl: '/assets/images/keyelement-compass-green.svg',
      },
      {
        title: 'growthTalks.home.keyElements.element2.title',
        desc: 'growthTalks.home.keyElements.element2.desc',
        linkUrl: './growth-talks/how-it-works/1',
        btnText: 'growthTalks.home.keyElements.element2.prompt',
        imgUrl: '/assets/images/keyelement-flower-blue.svg',
      },
      {
        title: 'growthTalks.home.keyElements.element3.title',
        desc: 'growthTalks.home.keyElements.element3.desc',
        linkUrl: './growth-talks/how-it-works/3',
        btnText: 'growthTalks.home.keyElements.element3.prompt',
        imgUrl: '/assets/images/keyelement-hands-red.svg',
      },
      {
        title: 'growthTalks.home.keyElements.element4.title',
        desc: 'growthTalks.home.keyElements.element4.desc',
        linkUrl: './growth-talks/how-it-works/2',
        btnText: 'growthTalks.home.keyElements.element4.prompt',
        imgUrl: '/assets/images/keyelement-bulb-orange.svg',
      },
    ],
  };

  diveDeeperContent = {
    title1: 'myGrowth.home.diveDeeper.title1',
    desc: 'myGrowth.home.diveDeeper.desc',
    btnText: 'myGrowth.home.diveDeeper.btnText',
    btnUrl: 'myGrowth.home.diveDeeper.btnUrl',
    textLinkUrl: 'myGrowth.home.diveDeeper.textLinkUrl',
    textLink: 'myGrowth.home.diveDeeper.textLink',
  };

  @Select(UserState.loggedUser) userData$: Observable<IUserData>;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  userData: IUserData;
  userName: string;
  userGID: string;
  showCalendar = false;
  showLessonsLearned = false;
  showMobileCalendar = false;
  private _subscription: Subscription;

  constructor(
    public translateService: TranslateService,
    private _breakpointObserver: BreakpointObserver,
    private _router: Router,
    private _activePopupsService: ActivePopupsService,
  ) {
    this._breakpointObserver.observe(['(max-width: 800px)']).subscribe((result: BreakpointState) => {
      if (result.matches) {
        this.showMobileCalendar = true;
      } else {
        this.showMobileCalendar = false;
      }
    });

    this._subscription = _router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this._activePopupsService.openActivePopups([POPUP_TYPE.growth_talks_popup]);
      }
    });
  }

  ngOnInit(): void {
    if (!this.routeToRedirect()) {
      this.getUserData();
    }
    this.canShowCalendar();
    //this.canShowLessonsLearned();
  }

  private getUserData(): void {
    this.userData$
      .pipe(
        tap(userData => {
          this.userData = userData;
          this.userName = userData.given_name;
          this.userGID = userData.gid.toLowerCase();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private routeToRedirect(): boolean {
    const url = window.localStorage.getItem('redirectUrl');
    if (url) {
      void this._router.navigateByUrl(url);
      window.localStorage.removeItem('redirectUrl');
    }
    return !!url;
  }

  canShowCalendar(): void {
    let startDate = new Date('November 30, 2023 12:00:00');
    let endDate = new Date('January 14, 2024 00:00:00');
    this.showCalendar = (new Date() > startDate && new Date() < endDate) || !environment.production;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this._subscription.unsubscribe();
  }
}
