<div class="tags-list">
  <div class="tag" *ngFor="let tag of tags">
    <div class="tag__img">
      <img [src]="tag.imgUrl" alt="" />
    </div>
    <gt-custom-link *ngIf="tag.url; else noLink" [trackingString]="tag.text" [url]="tag.url + '#' + tag.fragment">
      <div class="tag__text">
        <span>{{ tag.text | translate }}</span>
      </div>
    </gt-custom-link>
    <ng-template #noLink>
      <div class="tag__text inactive">
        <span>{{ tag.text | translate }}</span>
      </div>
    </ng-template>
  </div>
</div>
