import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gt-admin-user-table',
  templateUrl: './admin-user-table.component.html',
  styleUrls: ['./admin-user-table.component.scss'],
})
export class AdminUserTableComponent implements OnInit {
  @Input() users;
  @Input() sortAsc: boolean;
  @Output() edit = new EventEmitter<string>();
  @Output() add = new EventEmitter<void>();
  @Output() delete = new EventEmitter<string>();
  @Output() toggleSort = new EventEmitter<void>();

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {}

  onEdit(gid: string): void {
    this.edit.emit(gid);
  }

  onAdd(): void {
    this.add.emit();
  }

  onDelete(gid: string): void {
    this.delete.emit(gid);
  }

  onToggleSort(): void {
    this.toggleSort.emit();
  }
}
