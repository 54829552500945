<div class="calnedar23-dialog">
  <div class="close-btn" [ngClass]="'close-btn--' + data.color" (click)="close()"></div>
  <div class="content">
    <div class="leftSide" *ngIf="isReady">
      <div class="date">
        <div class="day">{{ "calendar23.day" + data.day + ".weekday" | translate }}</div>
        <div class="number">{{ data.day }}</div>
        <div class="underline"><img [src]="'assets/images/calendar23/dialog/underline/' + data.color + '.svg'" /></div>
      </div>
      <div *ngIf="dayHasVideo()" class="video-container">
        <gt-videoplayer [videolink]="videoUrl" [autoplay]="true"></gt-videoplayer>
        <div class="loading-indicator" *ngIf="!videoUrl">
          <mat-spinner [diameter]="50" [strokeWidth]="5"></mat-spinner>
        </div>
      </div>
      <p [innerHTML]="'calendar23.day' + data.day + '.text' | translate"></p>
      <button
        class="btn btn-hero"
        *ngIf="
          translate.instant('calendar23.day' + data.day + '.cta') !== 'calendar23.day' + data.day + '.cta' &&
          translate.instant('calendar23.day' + data.day + '.cta')
        "
        [disabled]="!translate.instant('calendar23.day' + data.day + '.ctaLink')"
        (click)="btnClicked(translate.instant('calendar23.day' + data.day + '.ctaLink'))"
      >
        {{ "calendar23.day" + data.day + ".cta" | translate }}
      </button>
      <button
        class="btn btn-hero"
        *ngIf="
          translate.instant('calendar23.day' + data.day + '.cta2') !== 'calendar23.day' + data.day + '.cta2' &&
          translate.instant('calendar23.day' + data.day + '.cta2')
        "
        [disabled]="!translate.instant('calendar23.day' + data.day + '.cta2Link')"
        (click)="btnClicked(translate.instant('calendar23.day' + data.day + '.cta2Link'))"
      >
        {{ "calendar23.day" + data.day + ".cta2" | translate }}
      </button>
    </div>
    <div #animContainer class="dialogAnim"></div>
  </div>
</div>
