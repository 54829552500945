import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gt-strengths-experts',
  templateUrl: './strengths-experts.component.html',
  styleUrls: ['./strengths-experts.component.scss'],
})
export class StrengthsExpertsComponent {
  @Input() sectionData: {
    title?: string;
    desc?: string;
    btnText?: string;
    btnLink?: string;
    workshopInfo?: string;
    btn2Text?: string;
    btn2Link?: string;
    strengthsExpertsList: {
      img: string;
      name: string;
      desc: string;
      animation: string;
    }[];
  };

  constructor(public translateService: TranslateService) {}
}
