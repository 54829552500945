<section
  class="sgt-content-section"
  [attr.data-scheme]="scheme"
  [attr.data-illustration-mobile-position]="illustrationMobilePosition"
  [attr.illustration-size]="illustrationSize"
  [attr.data-image-position-when-next-to-copy]="imagePositionWhenNextToCopy"
  [attr.data-image-position-when-below-copy]="imagePositionWhenBelowCopy"
  [attr.data-image-alingment]="imageAlignment"
  [class.marginBottom]="hasMarginBottom"
>
  <div class="sgt-content-section-copy" [attr.data-hasimage]="hasImage" [attr.data-hasillustration]="!!illustrationUrl" [attr.data-export-pdf]="hasExportPdf">
    <div class="subtitle" *ngIf="sectionContent.subTitle">{{ sectionContent.subTitle | translate }}</div>
    <h2 class="h2" *ngIf="hasImage && (sectionContent.title1 || sectionContent.title2 || sectionContent.titleAnim)">
      <span
        [innerHTML]="sectionContent.title1 | translate"
        *ngIf="sectionContent.title1 && translateService.instant(sectionContent.title1) !== sectionContent.title1"
      >
      </span>
      <gt-animated-underline [strokeType]="animType" [color]="animationColor"
        ><span class="strong"> {{ sectionContent.titleAnim | translate }}</span></gt-animated-underline
      >
      <span
        [innerHTML]="sectionContent.title2 | translate"
        *ngIf="sectionContent.title2 && translateService.instant(sectionContent.title2) !== sectionContent.title2"
      ></span>
    </h2>
    <h2 class="h2" *ngIf="!hasImage">
      <span
        [innerHTML]="sectionContent.title1 | translate"
        *ngIf="sectionContent.title1 && translateService.instant(sectionContent.title1) !== sectionContent.title1"
      >
      </span>
      <gt-animated-underline [strokeType]="animType" [color]="animationColor"
        ><span class="strong"> {{ sectionContent.titleAnim | translate }}</span></gt-animated-underline
      >
      <span
        [innerHTML]="sectionContent.title2 | translate"
        *ngIf="sectionContent.title2 && translateService.instant(sectionContent.title2) !== sectionContent.title2"
      ></span>
    </h2>
    <p [innerHTML]="sectionContent.desc | translate"></p>
    <gt-custom-link
      *ngIf="sectionContent.btnText && translateService.instant(sectionContent.btnText)"
      [trackingString]="sectionContent.btnText"
      [url]="translateService.instant(sectionContent.btnUrl)"
    >
      <div class="btn btn-hero">
        <span>{{ sectionContent.btnText | translate }}</span>
      </div>
    </gt-custom-link>
    <gt-custom-link
      *ngIf="sectionContent.textLink && translateService.instant(sectionContent.textLink)"
      [trackingString]="sectionContent.textLink"
      [url]="translateService.instant(sectionContent.textLinkUrl)"
    >
      <div class="textLink">
        <span>{{ sectionContent.textLink | translate }}</span>
        <span class="rearIcon icon-Arrowhead-right"></span>
      </div>
    </gt-custom-link>

    <ng-content></ng-content>
  </div>
  <figure [attr.data-size]="size" [attr.data-image-alingment]="imageAlignment" *ngIf="hasImage">
    <gt-animated-illustration *ngIf="illustrationUrl" [illustrationURL]="illustrationUrl" [illustrationStyle]="illustrationStyle">
      <img [src]="sectionContent.imgUrl" [alt]="imgAltText" />
    </gt-animated-illustration>
    <img *ngIf="!illustrationUrl" [src]="sectionContent.imgUrl" [alt]="imgAltText" />
  </figure>

  <div class="illustration" *ngIf="!hasImage && illustrationUrl">
    <gt-animated-illustration-no-image [illustrationURL]="illustrationUrl" [illustrationStyle]="illustrationStyle"></gt-animated-illustration-no-image>
  </div>
</section>
