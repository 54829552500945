<div mat-dialog-content>
  <form [formGroup]="surveyForm">
    <mat-stepper #stepper class="survey-stepper" formArrayName="questions">
      <mat-step>
        <div class="info-step">
          <div class="text">
            <p class="welcome">
              <span>{{ data.name }}</span
              ><span class="material-icons">sentiment_satisfied</span>
            </p>
            <p [innerHTML]="data.welcomeText[translateService.currentLang]"></p>
          </div>
          <div class="illustration">
            <gt-animated-illustration-no-image
              [illustrationURL]="'/assets/images/illustrations/survey_welcome.svg'"
              [illustrationStyle]="{ top: '10%', left: '3%+0px', width: '' }"
            ></gt-animated-illustration-no-image>
          </div>
        </div>
        <div class="btns-container multiple">
          <button class="btn btn-tetriary" matStepperNext (click)="trackClick('Survey:Start')">
            <span class="frontIcon icon-Check"></span><span></span>{{ "shared.yes" | translate }}
          </button>
          <button class="btn btn-tetriary" (click)="hideSurvey('no')">
            <span class="frontIcon icon-Close"></span><span></span>{{ "shared.no" | translate }}
          </button>
          <button class="btn btn-tetriary" (click)="hideSurvey('later')">
            <span class="frontIcon icon-Reading-time"></span><span></span>{{ "shared.later" | translate }}
          </button>
        </div>
      </mat-step>
      <div *ngIf="questionsFormArray.length">
        <div *ngFor="let question of data.questions; let i = index">
          <mat-step class="window" [formGroupName]="i" [stepControl]="questionsFormArray.get([i])">
            <div class="question-numbering">{{ "shared.question" | translate }} {{ i + 1 }}/{{ data.questions.length }}</div>
            <p>{{ question.questionText[translateService.currentLang] }}</p>
            <div *ngIf="question.type === questionTypeEnum.yes_no">
              <mat-radio-group aria-label="Select an option" formControlName="answer" class="survey-radioGroup">
                <mat-radio-button value="yes">{{ "shared.yes" | translate }}</mat-radio-button>
                <mat-radio-button value="no">{{ "shared.no" | translate }}</mat-radio-button>
              </mat-radio-group>
              <div *ngIf="question.allowComment && questionsFormArray.get([i]).value.answer === question.commentCondition" class="comment-section">
                <label
                  >{{ question.commentLabel[translateService.currentLang]
                  }}<span class="icon icon-Information" [matTooltip]="'surveys.commentInfo' | translate"></span
                ></label>
                <mat-form-field class="form-field">
                  <textarea matInput formControlName="comment"></textarea>
                </mat-form-field>
              </div>
            </div>
            <div *ngIf="question.type === questionTypeEnum.star_rating">
              <gt-star-rating formControlName="answer"></gt-star-rating>
              <div
                *ngIf="
                  question.allowComment &&
                  questionsFormArray.get([i]).value.answer !== null &&
                  questionsFormArray.get([i]).value.answer <= question.commentCondition
                "
                class="comment-section"
              >
                <label
                  >{{ question.commentLabel[translateService.currentLang]
                  }}<span class="icon icon-Information" [matTooltip]="'surveys.commentInfo' | translate"></span
                ></label>
                <mat-form-field class="form-field">
                  <textarea matInput formControlName="comment"></textarea>
                </mat-form-field>
              </div>
            </div>
            <div *ngIf="i < data.questions.length - 1; else submit" class="btns-container multiple">
              <button matStepperPrevious class="btn btn-secondary">{{ "shared.back" | translate }}</button>
              <button matStepperNext class="btn btn-primary" [disabled]="questionsFormArray.get([i]).value.answer === null">
                {{ "shared.next" | translate }}
              </button>
            </div>
            <ng-template #submit>
              <div class="btns-container">
                <button class="btn btn-primary" matStepperNext (click)="submitSurvey()">{{ "shared.submit" | translate }}</button>
              </div>
            </ng-template>
          </mat-step>
        </div>
      </div>
      <mat-step>
        <div class="info-step">
          <p [innerHTML]="data.thanksText[translateService.currentLang]" class="text"></p>
          <div class="illustration small">
            <gt-animated-illustration-no-image
              [illustrationURL]="'/assets/images/illustrations/survey_thanks.svg'"
              [illustrationStyle]="{ top: '10%', left: '0px', width: '' }"
            ></gt-animated-illustration-no-image>
          </div>
        </div>
        <div class="btns-container">
          <button class="btn btn-primary" [mat-dialog-close]>{{ "shared.close" | translate }}</button>
        </div>
      </mat-step>
    </mat-stepper>
  </form>
</div>
