import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SliderController } from 'src/ts/controller/ui/SliderController';

@Component({
  selector: 'gt-testemonial',
  templateUrl: './testemonial.component.html',
  styleUrls: ['./testemonial.component.scss'],
})
export class TestemonialComponent {
  controller: SliderController;

  @Input() ui: 'dots' | 'arrows';
  @Input() items: any[];
  @Input() scheme;
  @Input() sectionBackground: 'light' | 'sand' | 'dark' = 'light';
  @Input() cardBackground: 'light' | 'sand' | 'dark' = 'dark';
  @Input() title1: string;
  @Input() titleAnim: string;
  @Input() title2: string;
  @Input() desc: string;
  @Input() hasIllustration: boolean = false;
  @Input() animationColor: string = '#fe8389';
  @Input() strokeType: 'z' | 'linear' | 'wavy' = 'z';

  activeSlide: number = 0;

  constructor(public translate: TranslateService) {}

  public changeSlide(mode: 'previous' | 'next'): void {
    if (document.getElementsByClassName('slideFromLeft').item(0)) {
      document.getElementsByClassName('slideFromLeft').item(0).classList.remove('slideFromLeft');
    }
    if (mode === 'previous') {
      if (this.activeSlide !== 0) {
        document.getElementById('slide' + (this.activeSlide - 1)).classList.add('slideFromLeft');
        this.activeSlide -= 1;
      } else {
        document.getElementById('slide' + (this.items.length - 1)).classList.add('slideFromLeft');
        this.activeSlide = this.items.length - 1;
      }
    } else {
      if (this.activeSlide !== this.items.length - 1) {
        this.activeSlide += 1;
      } else {
        this.activeSlide = 0;
      }
    }
  }
}
