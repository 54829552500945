import { Component, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gt-add-topic-dialog',
  templateUrl: './add-topic-dialog.component.html',
  styleUrls: ['./add-topic-dialog.component.scss'],
})
export class AddTopicDialogComponent {
  addForm = new UntypedFormGroup({
    key: new UntypedFormControl('', [Validators.required]),
  });

  constructor(
    private dialogRef: MatDialogRef<AddTopicDialogComponent>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: { keyName: string },
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }

  onChange(event: any): void {
    this.data.keyName = event.target.value;
  }

  onSubmit(): void {
    if (!this.addForm.invalid) {
      this.dialogRef.close(this.data);
    }
  }
}
