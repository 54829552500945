export enum SRJ_VALUES {
  reliability = 'reliability',
  health = 'health',
  humor = 'humor',
  authenticity = 'authenticity',
  empathy = 'empathy',
  honesty = 'honesty',
  justice = 'justice',
  optimism = 'optimism',
  self_confidence = 'self_confidence',
  cheerfulness = 'cheerfulness',
  respect = 'respect',
  recognition = 'recognition',
  trust = 'trust',
  enthusiasm = 'enthusiasm',
  warmth = 'warmth',
  freedom = 'freedom',
  self_determination = 'self_determination',
  helpfulness = 'helpfulness',
  responsibility = 'responsibility',
  gratitude = 'gratitude',
  loyalty = 'loyalty',
  personal_development = 'personal_development',
  team_spirit = 'team_spirit',
  curiosity = 'curiosity',
  love_of_nature = 'love_of_nature',
  harmony = 'harmony',
  courage = 'courage',
  balance = 'balance',
  tolerance = 'tolerance',
  lightness = 'lightness',
  creativity = 'creativity',
  order = 'order',
  intergrity = 'intergrity',
  effectiveness = 'effectiveness',
  flexibility = 'flexibility',
  lifelong_learning = 'lifelong_learning',
  openness = 'openness',
}
