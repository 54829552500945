import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TrackingService } from './tracking.service';
import { TRACKING_ITEM } from '../enums/tracking_item.enum';

@Injectable({
  providedIn: 'root',
})
export class HrefInterceptorService {
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private trackingService: TrackingService,
  ) {}

  initialise(): void {
    document.onclick = _event => {
      const tEvent = _event || window.event;
      const element = (tEvent.target as HTMLAnchorElement) || (tEvent.srcElement as HTMLAnchorElement);
      let asset = '';
      let fragment = '';
      let href = '';
      if (element.tagName === 'A' && element.href) {
        href = element.href;
      }
      if (element.matches(':where(a) :scope')) {
        href = element.closest('a').href;
      }
      if (href) {
        if (!href.includes('https://profile.strengthscope.com/en-gb/register/')) {
          this.trackingService.addTrackingItem(
            TRACKING_ITEM.inlineLink,
            element.title + ':[from]' + this.trackingService.cleanRoute(this.router.url) + ':[to]' + this.trackingService.cleanRoute(href),
          );
        }
        if (href.includes('#')) {
          fragment = href.split('#').pop().split('?')[0];
        }
        if (href.includes('asset=')) {
          asset = href.split('asset=').pop();
        }
        if (!href.startsWith('/') && !href.startsWith(environment.url)) {
          _event.preventDefault();
          window.open(href, '_blank');
        }
      }
      // we are doing the next steps so that the app will not reload - instead it will update the route
      if (asset) {
        _event.preventDefault();
        let state = { ...this.activatedRoute.snapshot.queryParams, asset: asset };
        let url = this.router.createUrlTree([], { relativeTo: this.activatedRoute, queryParams: state, fragment: fragment }).toString();
        this.router.navigateByUrl(url);
      }
    };
  }
}
