import { UIController } from '../UIController';
import { RequestUtils } from '@webfruits/toolbox/dist/utils/RequestUtils';
import { SVGComponent } from '@webfruits/toolbox/dist/components/svg/SVGComponent';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SplitText } from 'gsap/SplitText';
import { PromisedDelay } from '@webfruits/toolbox/dist/timer/PromisedDelay';

/******************************************************************
 * AnimatedIllustration
 *
 *
 *****************************************************************/

export class AnimatedIllustration extends UIController {
  /******************************************************************
   * Properties
   *****************************************************************/

  private _svg: SVGComponent;
  private _svgPathes: SVGPathElement[];
  private _hasEntered: boolean;
  private _hasPlayed: boolean;
  private _splitText: SplitText;
  private _scrollTrigger: gsap.plugins.ScrollTriggerInstance;

  /******************************************************************
   * Constructor
   *****************************************************************/

  constructor(element: HTMLElement) {
    super(element);
    this.initScrollTrigger();
    this.loadAndInitSVG();
  }

  /******************************************************************
   * Public Methodes
   *****************************************************************/

  public updateStyles() {
    this.applyStyle({
      position: 'relative',
      display: 'inline-block',
    });
    if (this._svg) {
      this._svg.applyStyle({
        position: 'absolute',
        top: this.getSVGStyleTop(),
        left: `calc(${this.getSVGStyleLeft()} + ${this.calcAlignOfffsetX()}px)`,
        x: this.getSVGStyleOffsetX(),
        y: this.getSVGStyleOffsetY(),
        width: this.getSVGStyleWidth(),
        height: this.getSVGStyleHeight(),
        marginLeft: this.getSVGStyleMarginLeft(),
        marginTop: this.getSVGStyleMarginTop(),
      });
      if (this.getSVGStyleColor()) {
        this._svgPathes.forEach((svgPath: SVGElement) => {
          svgPath.style.stroke = this.getSVGStyleColor();
        });
      }
      if (this.hasCustomSVGStyleStrokeSize()) {
        this._svgPathes.forEach((svgPath: SVGElement) => {
          svgPath.style.strokeWidth = this.getSVGStyleStrokeSize() + 'px';
        });
      }
      this._svg.svgRoot.style.width = '100%';
      this._svg.svgRoot.style.height = '100%';
    }
    if (this._scrollTrigger) {
      this._scrollTrigger.kill();
      this.initScrollTrigger();
    }
  }

  public redraw() {
    this.removeChild(this._svg);
    this.loadAndInitSVG();
  }

  /******************************************************************
   * Private Methodes
   *****************************************************************/

  private initScrollTrigger() {
    this._scrollTrigger = ScrollTrigger.create({
      trigger: this.view,
      start: 'top 90%',
      once: true,
      onEnter: () => {
        this._hasEntered = true;
        if (this._svg) {
          this.playSVGAnimation();
        }
      },
    });
  }

  private async loadAndInitSVG() {
    const result = await RequestUtils.getPromisedData(this.getSVGURL());
    const svgString = result.responseText;
    this._svg = new SVGComponent(svgString);
    this._svg.svgRoot.setAttribute('alt', '');
    this._svg.svgRoot.setAttribute('role', 'img');
    this._svg.interactive = false;
    this._svg.clearSizeDefinition();
    this._svgPathes = Array.from(this._svg.svgRoot.querySelectorAll('path, line, polyline'));
    this.updateStyles();
    this.addChild(this._svg);
    ScrollTrigger.update();
    gsap.set(this._svgPathes, {
      drawSVG: '0%',
    });
    if (this._hasEntered && !this._hasPlayed) {
      setTimeout(() => {
        this.playSVGAnimation();
      }, 1000);
    }
    await PromisedDelay.wait(0.01);
    this.updateStyles();
  }

  private playSVGAnimation() {
    this._hasPlayed = true;
    this._svgPathes.forEach((path: SVGElement) => {
      const rawPathAnimationData = path.id.split('_');
      const pathAnimationSetup = {
        duration: 1,
        delay: 0,
      };
      rawPathAnimationData.forEach(data => {
        if (data.indexOf('duration') != -1) {
          pathAnimationSetup.duration = parseFloat(data.split('duration')[1]) / 1000;
        }
        if (data.indexOf('delay') != -1) {
          pathAnimationSetup.delay = parseFloat(data.split('delay')[1]) / 1000;
        }
      });
      gsap.to(path, {
        duration: pathAnimationSetup.duration,
        delay: pathAnimationSetup.delay,
        drawSVG: '100%',
      });
    });
  }

  private calcAlignOfffsetX(): number {
    if (this.view.dataset.align != 'end-of-first-line') {
      return 0;
    }
    this._splitText?.revert();
    this._splitText = new SplitText(this.view.firstChild as HTMLElement, { type: 'words' });
    const lastWordOfFirstLine = this.getLastWordOfFirstLine(this._splitText.words as HTMLElement[]);
    const lastWordOfFirstLineX = lastWordOfFirstLine.getBoundingClientRect().x;
    const lastWordOfFirstLineWidth = lastWordOfFirstLine.getBoundingClientRect().width;
    const thisX = this.view.getBoundingClientRect().x;
    return lastWordOfFirstLineX - thisX + lastWordOfFirstLineWidth;
  }

  private getLastWordOfFirstLine(words: HTMLElement[]): HTMLElement {
    let lastWord = words[words.length - 1];
    let lastWordTop = words[0].offsetTop;
    words.forEach(word => {
      if (word.offsetTop == lastWordTop) {
        lastWord = word;
      }
    });
    return lastWord;
  }

  private getSVGURL(): string {
    return this.view.dataset.illustrationUrl;
  }

  private getSVGStyleTop(): string {
    return this.view.dataset.illustrationTop;
  }

  private getSVGStyleLeft(): string {
    return this.view.dataset.illustrationLeft;
  }

  private getSVGStyleOffsetX(): string {
    return this.view.dataset.illustrationOffsetX;
  }

  private getSVGStyleOffsetY(): string {
    return this.view.dataset.illustrationOffsetY;
  }

  private getSVGStyleWidth(): string {
    return this.view.dataset.illustrationWidth;
  }

  private getSVGStyleHeight(): string {
    return this.view.dataset.illustrationHeight;
  }

  private getSVGStyleMarginLeft(): string {
    return this.view.dataset.illustrationMarginLeft;
  }

  private getSVGStyleMarginTop(): string {
    return this.view.dataset.illustrationMarginTop;
  }

  private getSVGStyleColor(): string {
    return this.view.dataset.illustrationColor;
  }

  private getSVGStyleStrokeSize(): number {
    return parseFloat(this.view.dataset.illustrationStrokeSize);
  }

  private hasCustomSVGStyleStrokeSize() {
    return this.view.dataset.illustrationStrokeSize != 'default';
  }

  /******************************************************************
   * Events
   *****************************************************************/

  // no events yet
}
