import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { filter, map, takeUntil } from 'rxjs/operators';
import { AuthService } from './new-auth.service';
import { Select } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { UserState } from 'src/app/shared/state-management/user/user.state';
import { IUserData } from 'src/app/shared/interfaces/IUserData';

@Injectable({
  providedIn: 'root',
})
export class ForbiddenGuard implements OnDestroy {
  @Select(UserState.AuthData) authData$: Observable<{ userData: IUserData; isAuthenticated: boolean }>;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return this.authData$
      .pipe(
        filter(authData => authData.isAuthenticated),
        map(authData => {
          if (!this.authService.isForbiddenByOrgCode(authData.userData.orgCode)) {
            this.router.navigate([''], { queryParamsHandling: 'merge', queryParams: route.queryParams });
          }
          return true;
        }),
        takeUntil(this.destroy$),
      )
      .toPromise();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
