<section
  class="sgt-people-hightlight-quote"
  [attr.data-scheme]="scheme"
  [attr.data-illustration-mobile-position]="illustrationMobilePosition"
  [attr.data-image-position-when-next-to-copy]="imagePositionWhenNextToCopy"
  [attr.data-image-position-when-below-copy]="imagePositionWhenBelowCopy"
>
  <figure *ngIf="imgUrl || imgUrl" [attr.data-size]="size">
    <img [src]="imgUrl" [alt]="imgAltText" />
  </figure>
  <div class="content">
    <blockquote [class.long]="translateService.instant(quoteTranslationString).length > 350" [class.purple]="animationColor === '#805cff'">
      <span class="block-text" [innerHTML]="quoteTranslationString | translate"></span>
    </blockquote>
    <p>
      <ng-container *ngIf="name">
        {{ name | translate }}
      </ng-container>
      <br />
      <ng-container *ngIf="position">
        {{ position | translate }}
      </ng-container>
      <br />
      <ng-container *ngIf="country">
        {{ country | translate }}
      </ng-container>
    </p>
  </div>
</section>
