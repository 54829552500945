import { JOURNEY_STEP_TYPE } from 'src/app/shared/enums/journey_step_types.enum';
import { SRJ_DIMENSIONS } from 'src/app/shared/enums/srj_dimensions.enum';
import { SRJ_TOPIC } from 'src/app/shared/enums/srj_topic.enum';
import { SRJ_VALUES } from 'src/app/shared/enums/srj_values.enum';
import { IJourneyConfigStageItem } from 'src/app/shared/interfaces/JourneyConfigStageItem';

export const selfReflectionJourneyConfig: IJourneyConfigStageItem[] = [
  {
    stageId: 'checkin',
    time: '8min',
    icon: '',
    steps: [
      {
        stepId: 'checkin_1',
        type: JOURNEY_STEP_TYPE.DIMENSIONS,
        previousModuleRoute: ['checkin'],
        nextUrl: '/self-reflection-journey/stages/checkin/checkin_3',
      },
      /* hide until they have the video
      {
        stepId: 'checkin_2',
        type: JOURNEY_STEP_TYPE.video,
        videoName: 'How to access the short Strengthscope report',
        previousRoute: ['checkin/checkin_1'],
        nextUrl: '/self-reflection-journey/stages/checkin/checkin_3'
      }, 
      */
      {
        stepId: 'checkin_3',
        type: JOURNEY_STEP_TYPE.TEXT,
        previousModuleRoute: ['checkin/checkin_1'],
        nextUrl: '/self-reflection-journey/stages/checkin/checkin_4',
      },
      {
        stepId: 'checkin_4',
        type: JOURNEY_STEP_TYPE.UNIQUE,
        previousModuleRoute: ['checkin/checkin_3'],
        nextUrl: '/self-reflection-journey/stages/checkin/checkin_5',
      },
      {
        stepId: 'checkin_5',
        type: JOURNEY_STEP_TYPE.AUDIO,
        illustrationStyle: { marginTop: '80px', width: '80%', height: '80%', color: '#00D7A0' },
        sideAnimation: 'assets/images/illustrations/srj_audio.svg',
        previousModuleRoute: ['checkin/checkin_4'],
        nextUrl: '/self-reflection-journey/stages/checkin/checkin_6',
      },
      {
        stepId: 'checkin_6',
        type: JOURNEY_STEP_TYPE.OPTION,
        previousModuleRoute: ['checkin/checkin_5'],
        illustrationStyle: { marginTop: '80px', left: '-40%', width: '150%', height: '100%', color: '#00D7A0' },
        sideAnimation: 'assets/images/illustrations/srj_quiz.svg',
        options: ['yes', 'no'],
        nextUrl: {
          yes: '/self-reflection-journey/stages/checkin/checkin_7',
          no: '/self-reflection-journey/stages/checkin/checkin_13',
        },
      },
      {
        stepId: 'checkin_7',
        type: JOURNEY_STEP_TYPE.TEXT,
        bottomAnimation: 'assets/images/srj_welcome.svg',
        previousModuleRoute: ['checkin/checkin_6'],
        nextUrl: '/self-reflection-journey/stages/checkin/checkin_8',
      },
      {
        stepId: 'checkin_8',
        type: JOURNEY_STEP_TYPE.QUIZ,
        answers: ['true', 'false'],
        correctAnswer: 'false',
        illustrationStyle: { marginTop: '80px', left: '-40%', width: '150%', height: '100%', color: '#00D7A0' },
        sideAnimation: 'assets/images/illustrations/srj_quiz.svg',
        previousModuleRoute: ['checkin/checkin_7'],
        nextUrl: '/self-reflection-journey/stages/checkin/checkin_9',
      },
      {
        stepId: 'checkin_9',
        type: JOURNEY_STEP_TYPE.QUIZ,
        answers: ['true', 'false'],
        correctAnswer: 'true',
        sideAnimation: 'assets/images/illustrations/srj_quiz_checkin_9.svg',
        illustrationStyle: { marginTop: '80px', left: '-40%', width: '150%', height: '100%', color: '#00D7A0' },
        previousModuleRoute: ['checkin/checkin_8'],
        nextUrl: '/self-reflection-journey/stages/checkin/checkin_10',
      },
      {
        stepId: 'checkin_10',
        type: JOURNEY_STEP_TYPE.QUIZ,
        answers: ['true', 'false'],
        correctAnswer: 'true',
        sideAnimation: 'assets/images/illustrations/srj_quiz_checkin_10.svg',
        illustrationStyle: { marginTop: '80px', left: '-40%', width: '150%', height: '100%', color: '#00D7A0' },
        previousModuleRoute: ['checkin/checkin_9'],
        nextUrl: '/self-reflection-journey/stages/checkin/checkin_11',
      },
      {
        stepId: 'checkin_11',
        type: JOURNEY_STEP_TYPE.QUIZ,
        answers: ['true', 'false'],
        correctAnswer: 'false',
        sideAnimation: 'assets/images/illustrations/srj_quiz_checkin_11.svg',
        illustrationStyle: { marginTop: '80px', left: '-40%', width: '150%', height: '100%', color: '#00D7A0' },
        previousModuleRoute: ['checkin/checkin_10'],
        nextUrl: '/self-reflection-journey/stages/checkin/checkin_12',
      },
      {
        stepId: 'checkin_12',
        type: JOURNEY_STEP_TYPE.TEXT,
        bottomAnimation: 'assets/images/srj_welcome.svg',
        previousModuleRoute: ['checkin/checkin_11'],
        nextUrl: '/self-reflection-journey/stages/checkin/checkin_13',
      },
      {
        stepId: 'checkin_13',
        type: JOURNEY_STEP_TYPE.TEXT,
        bottomAnimation: 'assets/images/srj_welcome.svg',
        previousModuleRoute: ['checkin/checkin_6', 'checkin/checkin_12'],
        nextUrl: '/self-reflection-journey/stages/stage1',
      },
    ],
  },
  {
    stageId: 'stage1',
    time: '12 min',
    icon: '',
    steps: [
      {
        stepId: 'stage1_1',
        type: JOURNEY_STEP_TYPE.AUDIO,
        sideAnimation: 'assets/images/illustrations/srj_audio.svg',
        illustrationStyle: { marginTop: '80px', width: '80%', height: '80%', color: '#00D7A0' },
        previousModuleRoute: ['stage1'],
        nextUrl: '/self-reflection-journey/stages/stage1/stage1_2',
      },
      {
        stepId: 'stage1_2',
        type: JOURNEY_STEP_TYPE.SELECTION,
        previousModuleRoute: ['stage1/stage1_1'],
        options: Object.values(SRJ_TOPIC),
        selectionCount: 3,
        i18nPrefixOptions: 'srj.stages.stage1.steps.stage1_2.options.',
        nextUrl: {
          '3': '/self-reflection-journey/stages/stage1/stage1_3-1-1',
          '1': '/self-reflection-journey/stages/stage1/stage1_3-2-1',
        },
      },
      {
        stepId: 'stage1_3-1-1',
        type: JOURNEY_STEP_TYPE.SELECTION,
        selectionCount: 1,
        i18nPrefixOptions: 'srj.stages.stage1.steps.stage1_2.options.',
        previousModuleRoute: ['stage1/stage1_2'],
        nextUrl: {
          '1': '/self-reflection-journey/stages/stage1/stage1_3-1-2',
        },
      },
      {
        stepId: 'stage1_3-1-2',
        type: JOURNEY_STEP_TYPE.UNIQUE,
        previousModuleRoute: ['stage1/stage1_3-1-1'],
        nextUrl: '/self-reflection-journey/stages/stage1/stage1_4',
      },
      {
        stepId: 'stage1_3-2-1',
        type: JOURNEY_STEP_TYPE.USER_INPUT,
        previousModuleRoute: ['stage1/stage1_2'],
        nextUrl: '/self-reflection-journey/stages/stage1/stage1_3-2-2',
      },
      {
        stepId: 'stage1_3-2-2',
        type: JOURNEY_STEP_TYPE.DIMENSIONS,
        previousModuleRoute: ['stage1/stage1_3-2-1'],
        nextUrl: '/self-reflection-journey/stages/stage1/stage1_3-2-3',
      },
      {
        stepId: 'stage1_3-2-3',
        type: JOURNEY_STEP_TYPE.SELECTION,
        selectionCount: 1,
        options: Object.values(SRJ_DIMENSIONS),
        i18nPrefixOptions: 'srj.dimensions.',
        previousModuleRoute: ['stage1/stage1_3-2-2'],
        nextUrl: {
          '1': '/self-reflection-journey/stages/stage1/stage1_4',
        },
      },
      {
        stepId: 'stage1_4',
        type: JOURNEY_STEP_TYPE.OPTION,
        options: ['little', 'much'],
        previousModuleRoute: ['stage1/stage1_3-1-2', 'stage1/stage1_3-2-3'],
        sideAnimation: 'assets/images/illustrations/srj_goals.svg',
        illustrationStyle: { marginTop: '80px', left: '0%', width: '150%', height: '100%', color: '#00D7A0' },
        nextUrl: {
          little: '/self-reflection-journey/stages/stage1/stage1_5-1',
          much: '/self-reflection-journey/stages/stage1/stage1_5-2-1',
        },
      },
      {
        stepId: 'stage1_5-1',
        type: JOURNEY_STEP_TYPE.USER_INPUT,
        sideAnimation: 'assets/images/illustrations/srj_goals.svg',
        illustrationStyle: { marginTop: '80px', left: '0%', width: '150%', height: '100%', color: '#00D7A0' },
        previousModuleRoute: ['stage1/stage1_4'],
        nextUrl: '/self-reflection-journey/stages/stage1/stage1_6',
      },
      {
        stepId: 'stage1_5-2-1',
        type: JOURNEY_STEP_TYPE.TEXT,
        sideAnimation: 'assets/images/illustrations/srj_goals.svg',
        illustrationStyle: { marginTop: '80px', left: '0%', width: '150%', height: '100%', color: '#00D7A0' },
        previousModuleRoute: ['stage1/stage1_4'],
        nextUrl: '/self-reflection-journey/stages/stage1/stage1_5-2-2',
      },
      {
        stepId: 'stage1_5-2-2',
        type: JOURNEY_STEP_TYPE.USER_INPUT,
        sideAnimation: 'assets/images/illustrations/srj_stage1_5-2-2.svg',
        illustrationStyle: { marginTop: '80px', left: '0%', width: '150%', height: '100%', color: '#00D7A0' },
        previousModuleRoute: ['stage1/stage1_5-2-1'],
        nextUrl: '/self-reflection-journey/stages/stage1/stage1_5-2-3',
      },
      {
        stepId: 'stage1_5-2-3',
        type: JOURNEY_STEP_TYPE.USER_INPUT,
        sideAnimation: 'assets/images/illustrations/srj_stage1_5-2-3.svg',
        illustrationStyle: { marginTop: '80px', left: '0%', width: '150%', height: '100%', color: '#00D7A0' },
        previousModuleRoute: ['stage1/stage1_5-2-2'],
        nextUrl: '/self-reflection-journey/stages/stage1/stage1_5-2-4',
      },
      {
        stepId: 'stage1_5-2-4',
        type: JOURNEY_STEP_TYPE.USER_INPUT,
        sideAnimation: 'assets/images/illustrations/srj_stage1_5-2-4.svg',
        illustrationStyle: { marginTop: '80px', left: '0%', width: '150%', height: '100%', color: '#00D7A0' },
        previousModuleRoute: ['stage1/stage1_5-2-3'],
        nextUrl: '/self-reflection-journey/stages/stage1/stage1_5-2-5',
      },
      {
        stepId: 'stage1_5-2-5',
        type: JOURNEY_STEP_TYPE.USER_INPUT,
        sideAnimation: 'assets/images/illustrations/srj_stage1_5-2-5.svg',
        illustrationStyle: { marginTop: '80px', left: '0%', width: '150%', height: '100%', color: '#00D7A0' },
        previousModuleRoute: ['stage1/stage1_5-2-4'],
        nextUrl: '/self-reflection-journey/stages/stage1/stage1_5-2-6',
      },
      {
        stepId: 'stage1_5-2-6',
        type: JOURNEY_STEP_TYPE.USER_INPUT,
        sideAnimation: 'assets/images/illustrations/srj_stage1_5-2-6.svg',
        illustrationStyle: { marginTop: '80px', left: '0%', width: '150%', height: '100%', color: '#00D7A0' },
        previousModuleRoute: ['stage1/stage1_5-2-5'],
        nextUrl: '/self-reflection-journey/stages/stage1/stage1_5-2-7',
      },
      {
        stepId: 'stage1_5-2-7',
        type: JOURNEY_STEP_TYPE.UNIQUE,
        sideAnimation: 'assets/images/illustrations/srj_goals.svg',
        previousModuleRoute: ['stage1/stage1_5-2-6'],
        nextUrl: '/self-reflection-journey/stages/stage1/stage1_6',
      },
      {
        stepId: 'stage1_6',
        type: JOURNEY_STEP_TYPE.UNIQUE,
        sideAnimation: 'assets/images/illustrations/srj_goals.svg',
        previousModuleRoute: ['stage1/stage1_5-1', 'stage1/stage1_5-2-7'],
        nextUrl: '/self-reflection-journey/stages/stage1/stage1_7',
      },
      {
        stepId: 'stage1_7',
        type: JOURNEY_STEP_TYPE.SUMMARY,
        sideAnimation: 'assets/images/illustrations/srj_welldone.svg',
        illustrationStyle: { marginTop: '80px', left: '0%', width: '150%', height: '100%', color: '#00D7A0' },
        previousModuleRoute: ['stage1/stage1_6'],
        nextUrl: '/self-reflection-journey/stages/stage2',
      },
    ],
  },
  {
    stageId: 'stage2',
    time: '8 min',
    icon: '',
    steps: [
      {
        stepId: 'stage2_1',
        type: JOURNEY_STEP_TYPE.SUMMARY,
        previousModuleRoute: ['stage2'],
        nextUrl: '/self-reflection-journey/stages/stage2/stage2_2',
      },
      {
        stepId: 'stage2_2',
        type: JOURNEY_STEP_TYPE.UNIQUE,
        previousModuleRoute: ['stage2/stage2_1'],
        nextUrl: '/self-reflection-journey/stages/stage2/stage2_3',
      },
      {
        stepId: 'stage2_3',
        type: JOURNEY_STEP_TYPE.USER_INPUT,
        sideAnimation: 'assets/images/illustrations/srj_notes.svg',
        illustrationStyle: { marginTop: '80px', left: '0%', width: '150%', height: '100%', color: '#00D7A0' },
        previousModuleRoute: ['stage2/stage2_2'],
        nextUrl: '/self-reflection-journey/stages/stage2/stage2_4',
      },
      {
        stepId: 'stage2_4',
        type: JOURNEY_STEP_TYPE.USER_INPUT,
        sideAnimation: 'assets/images/illustrations/srj_note_stage2_4.svg',
        illustrationStyle: { marginTop: '80px', left: '0%', width: '150%', height: '100%', color: '#00D7A0' },
        previousModuleRoute: ['stage2/stage2_3'],
        nextUrl: '/self-reflection-journey/stages/stage2/stage2_5',
      },
      {
        stepId: 'stage2_5',
        type: JOURNEY_STEP_TYPE.AUDIO,
        sideAnimation: 'assets/images/illustrations/srj_audio.svg',
        illustrationStyle: { marginTop: '80px', left: '10%', width: '80%', height: '80%', color: '#00D7A0' },
        previousModuleRoute: ['stage2/stage2_4'],
        nextUrl: '/self-reflection-journey/stages/stage2/stage2_6',
      },
      {
        stepId: 'stage2_6',
        type: JOURNEY_STEP_TYPE.TEXT,
        sideAnimation: 'assets/images/illustrations/srj_values.svg',
        illustrationStyle: { marginTop: '80px', left: '40%', width: '60%', height: '50%', color: '#00D7A0' },
        previousModuleRoute: ['stage2/stage2_5'],
        nextUrl: '/self-reflection-journey/stages/stage2/stage2_7',
      },
      {
        stepId: 'stage2_7',
        type: JOURNEY_STEP_TYPE.SELECTION,
        previousModuleRoute: ['stage2/stage2_6'],
        options: Object.values(SRJ_VALUES),
        selectionCount: 5,
        i18nPrefixOptions: 'srj.values.',
        nextUrl: {
          '5': '/self-reflection-journey/stages/stage2/stage2_8',
        },
      },
      {
        stepId: 'stage2_8',
        type: JOURNEY_STEP_TYPE.UNIQUE,
        sideAnimation: 'assets/images/illustrations/srj_values.svg',
        previousModuleRoute: ['stage2/stage2_7'],
        nextUrl: '/self-reflection-journey/stages/stage2/stage2_9',
      },
      {
        stepId: 'stage2_9',
        type: JOURNEY_STEP_TYPE.SELECTION,
        selectionCount: 3,
        i18nPrefixOptions: 'srj.values.',
        previousModuleRoute: ['stage2/stage2_8'],
        nextUrl: {
          '3': '/self-reflection-journey/stages/stage2/stage2_10',
        },
      },
      {
        stepId: 'stage2_10',
        type: JOURNEY_STEP_TYPE.SUMMARY,
        sideAnimation: 'assets/images/illustrations/srj_welldone.svg',
        illustrationStyle: { marginTop: '80px', left: '0%', width: '150%', height: '100%', color: '#00D7A0' },
        previousModuleRoute: ['stage2/stage2_9'],
        nextUrl: '/self-reflection-journey/stages/stage2/stage2_11',
      },
      {
        stepId: 'stage2_11',
        type: JOURNEY_STEP_TYPE.TEXT,
        previousModuleRoute: ['stage2/stage2_10'],
        nextUrl: '/self-reflection-journey/stages/stage3',
      },
    ],
  },
  {
    stageId: 'stage3',
    time: '7 min',
    icon: '',
    steps: [
      {
        stepId: 'stage3_1',
        type: JOURNEY_STEP_TYPE.SUMMARY,
        previousModuleRoute: ['stage3'],
        nextUrl: '/self-reflection-journey/stages/stage3/stage3_2',
      },
      {
        stepId: 'stage3_2',
        type: JOURNEY_STEP_TYPE.SELECTION,
        selectionCount: 1,
        i18nPrefixOptions: 'srj.values.',
        previousModuleRoute: ['stage3/stage3_1'],
        nextUrl: {
          '1': '/self-reflection-journey/stages/stage3/stage3_3',
        },
      },
      {
        stepId: 'stage3_3',
        type: JOURNEY_STEP_TYPE.USER_INPUT,
        sideAnimation: 'assets/images/illustrations/srj_values_2.svg',
        illustrationStyle: { marginTop: '80px', left: '0%', width: '150%', height: '100%', color: '#00D7A0' },
        previousModuleRoute: ['stage3/stage3_2'],
        nextUrl: '/self-reflection-journey/stages/stage3/stage3_4',
      },
      {
        stepId: 'stage3_4',
        type: JOURNEY_STEP_TYPE.USER_INPUT,
        sideAnimation: 'assets/images/illustrations/srj_stage3_4.svg',
        illustrationStyle: { marginTop: '80px', left: '0%', width: '150%', height: '100%', color: '#00D7A0' },
        previousModuleRoute: ['stage3/stage3_3'],
        nextUrl: '/self-reflection-journey/stages/stage3/stage3_5',
      },
      {
        stepId: 'stage3_5',
        type: JOURNEY_STEP_TYPE.SELECTION,
        selectionCount: 1,
        i18nPrefixOptions: 'srj.values.',
        previousModuleRoute: ['stage3/stage3_4'],
        nextUrl: {
          '1': '/self-reflection-journey/stages/stage3/stage3_6',
        },
      },
      {
        stepId: 'stage3_6',
        type: JOURNEY_STEP_TYPE.USER_INPUT,
        sideAnimation: 'assets/images/illustrations/srj_stage3_6.svg',
        illustrationStyle: { marginTop: '80px', left: '0%', width: '150%', height: '100%', color: '#00D7A0' },
        previousModuleRoute: ['stage3/stage3_5'],
        nextUrl: '/self-reflection-journey/stages/stage3/stage3_7',
      },
      {
        stepId: 'stage3_7',
        type: JOURNEY_STEP_TYPE.USER_INPUT,
        sideAnimation: 'assets/images/illustrations/srj_stage3_7.svg',
        illustrationStyle: { marginTop: '80px', left: '0%', width: '150%', height: '100%', color: '#00D7A0' },
        previousModuleRoute: ['stage3/stage3_6'],
        nextUrl: '/self-reflection-journey/stages/stage3/stage3_8',
      },
      {
        stepId: 'stage3_8',
        type: JOURNEY_STEP_TYPE.UNIQUE,
        sideAnimation: 'assets/images/illustrations/srj_values_2.svg',
        previousModuleRoute: ['stage3/stage3_7'],
        nextUrl: '/self-reflection-journey/stages/stage3/stage3_9',
      },
      {
        stepId: 'stage3_9',
        type: JOURNEY_STEP_TYPE.UNIQUE,
        previousModuleRoute: ['stage3/stage3_8'],
        nextUrl: '/self-reflection-journey/stages/stage3/stage3_10',
      },
      {
        stepId: 'stage3_10',
        type: JOURNEY_STEP_TYPE.UNIQUE,
        previousModuleRoute: ['stage3/stage3_9'],
        nextUrl: '/self-reflection-journey/stages/stage3/stage3_11',
      },
      {
        stepId: 'stage3_11',
        type: JOURNEY_STEP_TYPE.SUMMARY,
        sideAnimation: 'assets/images/illustrations/srj_welldone.svg',
        illustrationStyle: { marginTop: '80px', left: '0%', width: '150%', height: '100%', color: '#00D7A0' },
        previousModuleRoute: ['stage3/stage3_10'],
        nextUrl: '/self-reflection-journey/stages/stage3/stage3_12',
      },
      {
        stepId: 'stage3_12',
        type: JOURNEY_STEP_TYPE.TEXT,
        previousModuleRoute: ['stage3/stage3_11'],
        bottomAnimation: 'assets/images/srj_welcome.svg',
        nextUrl: '/self-reflection-journey/stages/stage4',
      },
    ],
  },
  {
    stageId: 'stage4',
    time: '5 min',
    icon: '',
    steps: [
      {
        stepId: 'stage4_1',
        type: JOURNEY_STEP_TYPE.SUMMARY,
        previousModuleRoute: ['stage4'],
        nextUrl: '/self-reflection-journey/stages/stage4/stage4_2',
      },
      {
        stepId: 'stage4_2',
        type: JOURNEY_STEP_TYPE.TEXT,
        previousModuleRoute: ['stage4/stage4_1'],
        bottomAnimation: 'assets/images/srj_welcome.svg',
        nextUrl: '/self-reflection-journey/stages/stage4/stage4_3',
      },
      {
        stepId: 'stage4_3',
        type: JOURNEY_STEP_TYPE.TEXT,
        previousModuleRoute: ['stage4/stage4_2'],
        nextUrl: '/self-reflection-journey/stages/stage4/stage4_4',
      },
      {
        stepId: 'stage4_4',
        type: JOURNEY_STEP_TYPE.TEXT,
        sideAnimation: 'assets/images/illustrations/srj_routines.svg',
        illustrationStyle: { marginTop: '80px', left: '20%', width: '100%', height: '100%', color: '#00D7A0' },
        previousModuleRoute: ['stage4/stage4_3'],
        nextUrl: '/self-reflection-journey/stages/stage4/stage4_5',
      },
      {
        stepId: 'stage4_5',
        type: JOURNEY_STEP_TYPE.UNIQUE,
        sideAnimation: 'assets/images/illustrations/srj_routines.svg',
        previousModuleRoute: ['stage4/stage4_4'],
        nextUrl: '/self-reflection-journey/stages/stage4/stage4_6',
      },
      {
        stepId: 'stage4_6',
        type: JOURNEY_STEP_TYPE.USER_INPUT,
        sideAnimation: 'assets/images/illustrations/srj_routines.svg',
        illustrationStyle: { marginTop: '80px', left: '20%', width: '100%', height: '100%', color: '#00D7A0' },
        previousModuleRoute: ['stage4/stage4_5'],
        nextUrl: '/self-reflection-journey/stages/stage4/stage4_7',
      },
      {
        stepId: 'stage4_7',
        type: JOURNEY_STEP_TYPE.TEXT,
        sideAnimation: 'assets/images/illustrations/srj_routines.svg',
        illustrationStyle: { marginTop: '80px', left: '20%', width: '100%', height: '100%', color: '#00D7A0' },
        previousModuleRoute: ['stage4/stage4_6'],
        nextUrl: '/self-reflection-journey/stages/stage4/stage4_8',
      },
      {
        stepId: 'stage4_8',
        type: JOURNEY_STEP_TYPE.SUMMARY,
        sideAnimation: 'assets/images/illustrations/srj_welldone.svg',
        illustrationStyle: { marginTop: '80px', left: '0%', width: '150%', height: '100%', color: '#00D7A0' },
        previousModuleRoute: ['stage4/stage4_7'],
        nextUrl: '/self-reflection-journey/stages/stage4/stage4_9',
      },
      {
        stepId: 'stage4_9',
        type: JOURNEY_STEP_TYPE.UNIQUE,
        previousModuleRoute: ['stage4/stage4_8'],
      },
    ],
  },
];
