import { IEsrjCompletedStep } from '../../interfaces/IEsrjCompeltedStep';
import { JourneyMultipleChoiceItem } from '../../interfaces/IJourneyMultipleChoice';
import { JourneyRatingData } from '../../interfaces/IJourneyRating';

export class UpdateESRJCompletedSteps {
  static readonly type = '[EthicalSelfReflectionJourney] Update Completed Steps';
  constructor(public newStep: IEsrjCompletedStep) {}
}

export class UpdateESRJAspectsOfEthics {
  static readonly type = '[EthicalSelfReflectionJourney] Update Selected Aspects Of Ethics';
  constructor(public aspectsOfEthics: JourneyMultipleChoiceItem[]) {}
}

export class UpdateESRJEthicalBehaviorResponses {
  static readonly type = '[EthicalSelfReflectionJourney] Update Ethical Behavior Responses';
  constructor(public ethicalBehaviorResponses: JourneyRatingData) {}
}

export class UpdateESRJEthicalMindfulResponses {
  static readonly type = '[EthicalSelfReflectionJourney] Update Ethical Mindful Responses';
  constructor(public ethicalMindfulResponses: JourneyRatingData) {}
}

export class ResetESRJStatus {
  static readonly type = '[EthicalSelfReflectionJourney] Reset Ethical Self-Reflection Record';
}

export class ResetESRJSteps {
  static readonly type = '[EthicalSelfReflectionJourney] Reset Ethical Self-Reflection Steps';
  constructor(public newStepsArray: IEsrjCompletedStep[]) {}
}
