import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { VideoService } from '../../services/video.service';

@Component({
  selector: 'gt-video-section',
  templateUrl: './video-section.component.html',
  styleUrls: ['./video-section.component.scss'],
})
export class VideoSectionComponent implements OnInit {
  @Input() scheme: 'sand' | 'dark' | 'light' = 'dark';
  @Input() illustrationMobilePosition: 'right-to-title' | 'below-desc';
  @Input() imagePositionWhenNextToCopy: 'right' | 'left';
  @Input() imagePositionWhenBelowCopy: 'right' | 'left';
  @Input() size: 'normal' | 'reducedForPageNavigation' = 'normal';
  @Input() hasImage: boolean = true;
  @Input() illustrationUrl: string;
  @Input() illustrationSize: string;
  @Input() animationColor: string = '#fe8389';
  @Input() illustrationStyle: { top?: string; marginLeft?: string; left?: string; width: string; height?: string; marginTop?: string } = {
    marginTop: '0',
    marginLeft: '0',
    width: '37%',
    height: '100%',
  };
  @Input() hasMarginBottom = false;
  @Input() imageAlignment: 'start' | 'center' | 'end'; //default is end
  @Input() imgAltText: string = '';
  @Input() animType: 'z' | 'linear' | 'wavy' = 'z';

  @Input() sectionContent: {
    subTitle?: string;
    title1?: string;
    titleAnim?: string;
    title2?: string;
    desc1?: string;
    desc2?: string;
    imgUrl?: string;
    downloadBtnUrl?: string;
    downloadBtnText?: string;
    btnUrl?: string;
    btnText?: string;
    textLinkUrl?: string;
    textLink?: string;
    videoName?: string;
  };

  public videoUrl: string;

  constructor(
    public translateService: TranslateService,
    private videoService: VideoService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.videoUrl = await this.getVideoUrl(this.sectionContent.videoName);
  }

  private async getVideoUrl(id: string): Promise<string> {
    const url = await this.videoService.getVideoUrl(id).toPromise();
    return url;
  }
}
