<div class="video-dialog-container">
  <h2 [innerHTML]="data.title | translate"></h2>
  <p [innerHTML]="data.desc | translate"></p>
  <div *ngIf="videoUrl && data.type === popupTypeEnum.growth_talks_popup">
    <gt-videoplayer [videolink]="videoUrl" [autoplay]="true" (videoFinished)="trackVideoEnding($event)"></gt-videoplayer>
  </div>
  <div *ngIf="videoUrl && data.type === popupTypeEnum.focus_week_popup">
    <gt-gifplayer [videolink]="videoUrl"></gt-gifplayer>
  </div>
  <gt-custom-link *ngIf="data.type === popupTypeEnum.focus_week_popup" [url]="'focusWeekPopup.btnLink' | translate">
    <div class="btn btn-hero">
      <span>{{ "focusWeekPopup.btnText" | translate }}</span>
    </div>
  </gt-custom-link>
  <a *ngIf="showHideLink" (click)="updateVisibility()">{{ "growthTalksVideo.hide" | translate }}</a>
  <div class="btns-container">
    <button class="btn btn-primary" (click)="close()">
      <span>{{ "shared.close" | translate }}</span>
    </button>
  </div>
</div>
