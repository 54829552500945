export enum TRACKING_ITEM {
  route = 'route',
  faq = 'faq',
  button = 'button',
  downloads = 'downloads',
  calendar = 'calendar',
  manualSearch = 'manualSearch',
  feedback_act_library = 'feedback_act_library',
  inlineLink = 'inlineLink',
  menu = 'menu',
  bb = 'my_brand_builder',
  video = 'video',
}
