import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'gt-journey-warning-dialog',
  templateUrl: './journey-warning-dialog.component.html',
  styleUrl: './journey-warning-dialog.component.scss',
})
export class JourneyWarningDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<JourneyWarningDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public i18nPrefix: string,
  ) {}

  public close(mode: boolean): void {
    this.dialogRef.close(mode);
  }
}
