import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'gt-videoplayer',
  templateUrl: './videoplayer.component.html',
  styleUrls: ['./videoplayer.component.scss'],
})
export class VideoplayerComponent {
  @Input() videolink: string;
  @Input() autoplay: boolean;
  @Output() videoFinished = new EventEmitter<{ finished: boolean; skipped: boolean }>();
  private previousTime: number = 0;
  private skippedVideo: boolean = false;

  public getType(): string {
    return 'video/mp4';
  }

  public videoEnded(): void {
    this.videoFinished.next({ finished: true, skipped: this.skippedVideo });
  }

  public videoTimeUpdate(event: Event): void {
    const videoElement = event.target as HTMLVideoElement;
    const currentTime = videoElement.currentTime;
    const skipThreshold = 2;
    // Check if the user skipped ahead by comparing current time with previous time
    if (currentTime - this.previousTime < skipThreshold) {
      this.skippedVideo = true;
    }
    this.previousTime = currentTime;
  }
}
