import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TrackingService } from 'src/app/shared/services/tracking.service';
import { environment } from 'src/environments/environment';
import { CalendarDialogComponent } from './calendar-dialog/calendar-dialog.component';
import { TRACKING_ITEM } from 'src/app/shared/enums/tracking_item.enum';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { UserState } from 'src/app/shared/state-management/user/user.state';
import { takeUntil, tap } from 'rxjs/operators';
import { IUserConfig } from 'src/app/shared/interfaces/IUserConfig';
import { UpdateUserConfigCalendar } from 'src/app/shared/state-management/user/user.actions';

@Component({
  selector: 'gt-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit, OnDestroy {
  @Select(UserState.userConfig) userConfig$: Observable<IUserConfig>;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  viewedItems: string[];
  loading: boolean = false;

  constructor(
    public dialog: MatDialog,
    private http: HttpClient,
    private trackingService: TrackingService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.getUserConfig();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private getUserConfig(): void {
    this.userConfig$
      .pipe(
        tap(userConfig => {
          this.viewedItems = userConfig.calendar['202112'] ? userConfig.calendar['202112'].map(String) : [];
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  async openDialog(calendarItem: { id: string; color: 'green' | 'blue' | 'orange' | 'pink' | 'purple' | 'yellow'; hasVideo?: boolean }): Promise<void> {
    if (this.loading === true) {
      return;
    } // to avoid opening 2 pop-ups if you double-click
    this.loading = true;
    this.viewedItems.push(calendarItem.id);
    this.store.dispatch(new UpdateUserConfigCalendar({ ['202112']: this.viewedItems.map(Number) }));
    const dialogRef = this.dialog.open(CalendarDialogComponent, {
      width: '100vw',
      height: '100vh',
      panelClass: 'calendarDialog',
      disableClose: true,
      data: {
        id: calendarItem.id,
        color: calendarItem.color,
        videoUrl: calendarItem.hasVideo ? await this.getVideoUrl(calendarItem.id) : null,
        type: 'calendar',
      },
    });
    this.trackingService.addTrackingItem(TRACKING_ITEM.calendar, 'day:' + calendarItem.id);
    this.loading = false;
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  hasBeenViewed(itemId: string): boolean {
    return this.viewedItems.findIndex(s => s === itemId) > -1;
  }

  async getVideoUrl(id: string): Promise<string> {
    const url = await this.http.get<string>(environment.backendUrl + 'calendar/' + id).toPromise();
    return url;
  }
}
