import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gt-centered-card',
  templateUrl: './centered-card.component.html',
  styleUrls: ['./centered-card.component.scss'],
})
export class CenteredCardComponent {
  @Input() scheme: 'sand' | 'dark' | 'light' = 'sand';
  @Input() sectionData: {
    color: string;
    subtitle: string;
    title: string;
    titleAnim?: string;
    text1: string;
    imgUrl: string;
    text2: string;
    actionButton?: string;
    btnIntLink?: string;
    imgAltText?: string;
  };
  @Input() animationUrl: string;
  @Input() illustrationStyle = { top: '-35%', left: '33%', width: '37%', height: '100%' };
}
