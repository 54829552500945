import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'gt-add-item-dialog',
  templateUrl: './add-item-dialog.component.html',
  styleUrls: ['./add-item-dialog.component.scss'],
})
export class AddItemDialogComponent implements OnInit {
  newItemForm: FormGroup;

  constructor(private dialogRef: MatDialogRef<AddItemDialogComponent>) {}

  ngOnInit(): void {
    this.newItemForm = new FormGroup({
      keyName: new FormControl('', [Validators.required]),
      valueName: new FormControl(''),
    });
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public save(): void {
    if (!this.newItemForm.invalid) {
      this.dialogRef.close(this.newItemForm.value);
    }
  }
}
