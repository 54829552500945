import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { searchOptionsEn } from 'src/assets/documents/searchOptions';
import { ISearchSuggestion } from '../interfaces/ISearchSuggestion';
import { searchSuggestionsEn } from 'src/assets/documents/searchSuggestions';
import { ISearchOption } from '../interfaces/ISearchOption';
import { TranslateService } from '@ngx-translate/core';
import { LoadingIndicatorService } from './loading-indicator.service';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private searchOptions = { en: [], de: [] };
  private isSearchOpenSource = new BehaviorSubject(false);
  private searchTermSource = new BehaviorSubject(null);
  private searchResultsSource = new BehaviorSubject([]);
  private relatedTopicsSource = new BehaviorSubject([]);
  public isSearchOpenCurrent = this.isSearchOpenSource.asObservable();
  public searchTermCurrent = this.searchTermSource.asObservable();
  public searchResultsCurrent = this.searchResultsSource.asObservable();
  public relatedTopicsCurrent = this.relatedTopicsSource.asObservable();

  constructor(
    private translateService: TranslateService,
    private loadingIndicatorService: LoadingIndicatorService,
  ) {
    this.translateService.onLangChange.subscribe(lang => {
      if (lang.lang !== 'en') {
        this.updateSearchState(false);
      }
    });
  }

  public updateSearchState(value: boolean): void {
    this.isSearchOpenSource.next(value);
  }

  public updateSearchTerm(value: string): void {
    this.searchTermSource.next(value);
    if (value) {
      this.getSearchResults(value);
    } else {
      this.updateSearchResults([]);
      this.updateRelatedTopics([]);
    }
  }

  public updateSearchResults(value: ISearchOption[]): void {
    this.searchResultsSource.next(value);
  }

  public updateRelatedTopics(value: ISearchOption[]): void {
    this.relatedTopicsSource.next(value);
  }

  public async getSearchResults(searchTerm: string): Promise<void> {
    this.loadingIndicatorService.updateLoadingIndicatorStatus(true);
    // in the future check what is the current lang
    if (!this.searchOptions.en.length) {
      this.searchOptions.en = await this.getSearchOptionsByLanguage('en');
    }
    const searchResults: ISearchOption[] = [];
    let relatedTopics: ISearchOption[] = [];
    let tempArray: { number: number; option: ISearchOption }[] = [];
    this.searchOptions.en.forEach(option => {
      if (option.value.toLowerCase().includes(searchTerm.toLowerCase())) {
        searchResults.push(option);
      } else {
        let similarities = this.findSimilarityes(searchTerm.toLowerCase(), option.value.toLowerCase());
        if (similarities > 2) {
          tempArray.push({ number: similarities, option: option });
        }
      }
    });
    tempArray.sort((a, b) => (a.number < b.number ? 1 : b.number < a.number ? -1 : 0));
    relatedTopics = tempArray
      .map(s => {
        return s.option;
      })
      .slice(0, 10);
    this.updateSearchResults(searchResults);
    this.updateRelatedTopics(relatedTopics);
    this.loadingIndicatorService.updateLoadingIndicatorStatus(false);
  }

  private findSimilarityes(searchTerm, itemTerm): number {
    searchTerm = searchTerm.replaceAll(/\s/g, '');
    itemTerm = itemTerm.replaceAll(/\s/g, '');
    const res = [];
    for (let i = 0; i < searchTerm.length; i++) {
      if (itemTerm.includes(searchTerm[i]) && res.indexOf(searchTerm[i]) === -1) {
        res.push(searchTerm[i]);
      }
    }
    for (let i = 0; i < itemTerm.length; i++) {
      if (searchTerm.includes(itemTerm[i]) && res.indexOf(itemTerm[i]) === -1) {
        res.push(itemTerm[i]);
      }
    }
    return res.length;
  }

  private getSearchOptionsByLanguage(lang: string): Promise<ISearchOption[]> {
    return this.fakePromise(searchOptionsEn);
  }

  public getSearchSuggestionsByLang(lang: string): Promise<ISearchSuggestion[]> {
    return this.fakePromise(searchSuggestionsEn);
  }

  private fakePromise(returnValue: any): any {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(returnValue);
      }, 1000);
    });
  }
}
