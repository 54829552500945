import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DevGuard {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!environment.production) {
      return true;
    } else {
      this.router.navigate(['/'], { queryParamsHandling: 'merge', queryParams: route.queryParams });
      return false;
    }
  }
}
