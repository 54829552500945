import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import _ from 'lodash';
import { TranslationChange } from '../../services/translation-file.service';
import { MachineTranslationService } from '../../services/machine-translation.service';
import { catchError, finalize, of, tap } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MYG_LANGUAGE_CODE } from '../../enums/language-code.enum';

@Component({
  selector: 'gt-admin-recursive-translation',
  templateUrl: './admin-recursive-translation.component.html',
  styleUrls: ['./admin-recursive-translation.component.scss'],
})
export class AdminRecursiveTranslationComponent {
  constructor(
    private machineTranslation: MachineTranslationService,
    private snackBar: MatSnackBar,
  ) {}

  @Input() data: any;
  @Input() orig: any;
  @Input() path: string;
  @Input() level: number;
  @Input() canEdit: boolean = false;
  @Input() hideOrig: boolean = false;
  @Input() currentLang: MYG_LANGUAGE_CODE;
  @Output() onChange = new EventEmitter<TranslationChange>();
  @Output() topic = new EventEmitter<string>();
  @Output() item = new EventEmitter<string>();
  @Output() removeItem = new EventEmitter<string>();
  @Output() removeTopic = new EventEmitter<TranslationChange[]>();

  public hideOrigIndexes: number[] = [];
  public openedIndexes: number[] = [];
  protected isTranslating: boolean[] = [];
  protected mygLanguageCode = MYG_LANGUAGE_CODE;
  private changes: TranslationChange[] = [];

  public isObject(obj: any): boolean {
    return typeof obj === 'object';
  }

  public passEvent(event: TranslationChange): void {
    this.onChange.emit(event);
  }

  public passEventRemoveItem(path: string): void {
    this.removeItem.emit(path);
  }

  public passEventRemoveTopic(event: TranslationChange[]): void {
    this.removeTopic.emit(event);
  }

  public onRename(path: string, event: any): void {
    const newPath = path.split('.');
    newPath.pop();
    newPath.push(event.target.value);

    this.onChange.emit({
      action: 'rename',
      path: path,
      newPath: newPath.join('.'),
    });
  }

  public onValueChange(path: string, event: any): void {
    this.onChange.emit({
      action: 'update',
      path: path,
      value: event.target.value,
    });
  }

  public onValueDelete(path: string): void {
    this.removeItem.emit(path);
  }

  public onAddTopic(path: string): void {
    this.topic.emit(path);
  }

  public onAddItem(path: string): void {
    this.item.emit(path);
  }

  public onRemoveTopic(path: string, obj: any): void {
    this.changes.push({ action: 'delete', path });
    this.removeTopic.emit(this.changes);
  }

  public onToggleHideOrig(event: any, index: number): void {
    if (this.hideOrigIndexes.includes(index)) {
      this.hideOrigIndexes = this.hideOrigIndexes.filter(i => i !== index);
    } else {
      this.hideOrigIndexes = [...this.hideOrigIndexes, index];
    }
    event.stopPropagation();
  }

  public getOriginalValue(path: string): string {
    path = this._clearPath(path);
    return _.get(this.orig, path, '-');
  }

  public onOpen(index: number): void {
    this.openedIndexes.push(index);
  }

  public onClose(index: number): void {
    this.openedIndexes = this.openedIndexes.filter(i => i !== index);
  }

  protected translateFromEnglish(path: string, index: number): void {
    this.isTranslating[index] = true;
    const originalValue = this.getOriginalValue(path);
    this.machineTranslation
      .translate(MYG_LANGUAGE_CODE.EN, this.currentLang, originalValue)
      .pipe(
        tap(translatedText => {
          _.set(this.data, this._getKeyFromPath(path), translatedText);
          this.onChange.emit({ action: 'update', path, value: translatedText });
        }),
        catchError(error => {
          console.error('Translation failed: ', error);
          this.snackBar.open('Translation failed: ' + error, '', { duration: 5000 });
          return of('');
        }),
        finalize(() => {
          this.isTranslating[index] = false;
        }),
      )
      .subscribe();
  }

  private _clearPath(path): string {
    return path.startsWith('.') ? path.substring(1) : path;
  }

  private _getKeyFromPath(path: string): string {
    const parts = path.split('.');
    return parts[parts.length - 1];
  }
}
