import { SURVEY_STATUS } from '../enums/survey_status.enum';
import { ISurveyQuestion } from './ISurveyQuestion';

export class ISurveyConfigBasic {
  name: string;
  welcomeText: Record<string, string>;
  questions: ISurveyQuestion[];
  thanksText: Record<string, string>;
  status?: SURVEY_STATUS;
}

export class ISurveyConfig extends ISurveyConfigBasic {
  id: string;
  statusLastChangedAt: string;
  creator: string;
  createdAt: string;
}
