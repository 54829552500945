import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { UserRolesEnum } from 'src/app/shared/enums/user_roles.enum';
import { UserState } from 'src/app/shared/state-management/user/user.state';

@Injectable({
  providedIn: 'root',
})
export class RolesGuard {
  @Select(UserState.roles) roles$: Observable<UserRolesEnum[]>;

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const allowedRoles = route.data['allowedRoles'] as UserRolesEnum[];
    return this.roles$
      .pipe(
        filter(roles => !!roles),
        map(roles => {
          return roles.some(role => allowedRoles.includes(role));
        }),
        take(1),
      )
      .toPromise();
  }
}
