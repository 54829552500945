<!-- <gt-calendar></gt-calendar>
<gt-lessons-leearned></gt-lessons-leearned> -->
<!-- <gt-calendar22 *ngIf="!showMobileCalendar && showCalendar"></gt-calendar22> -->
<!-- <gt-calendar22-mobile *ngIf="showMobileCalendar && showCalendar"></gt-calendar22-mobile> -->
<gt-subpage-hero
  scheme="sand"
  animationColor="#00D7A0"
  [userName]="userName"
  subTitleTranslationString="myGrowth.home.hero1.subTitle"
  title1TranslationString="myGrowth.home.hero1.title1"
  titleTranslationStringAnim="myGrowth.home.hero1.titleAnim"
  title2TranslationString="myGrowth.home.hero1.title2"
>
</gt-subpage-hero>
<gt-people-highlight-quote
  scheme="sand"
  illustrationMobilePosition="below-desc"
  imagePositionWhenNextToCopy="left"
  imagePositionWhenBelowCopy="right"
  imgUrl="/assets/images/testimonials/Judith-Wiese.png"
  imgAltText="Portrait of the quote author"
  animationColor="#00D7A0"
  size="reducedForPageNavigation"
  quoteTranslationString="myGrowth.home.hero1.quote.quote"
  name="myGrowth.home.hero1.quote.name"
  position="myGrowth.home.hero1.quote.position"
>
</gt-people-highlight-quote>
<gt-subpage-hero
  scheme="sand"
  illustrationMobilePosition="right-to-title"
  imagePositionWhenNextToCopy="right"
  imagePositionWhenBelowCopy="left"
  animationColor="#00D7A0"
  title1TranslationString="myGrowth.home.hero2.title1"
  titleTranslationStringAnim="myGrowth.home.hero2.titleAnim"
  title2TranslationString="myGrowth.home.hero2.title2"
  textTranslationString="myGrowth.home.hero2.desc"
  illustrationUrl=""
  imgUrl="/assets/images/hero_photos/myGrowth-home-hero.svg"
  size="reducedForPageNavigation"
  imgAltText="A woman in a t-shirt smiles as she looks towards the introduction text as if she is reading it"
  [illustrationStyle]="{ top: '10%', left: '3%+0px', width: '' }"
  illustrationUrl="/assets/images/illustrations/home_hero.svg"
>
</gt-subpage-hero>

<!-- <gt-round-cards
  [sectionData]="whatIsItContent"
  [columns]="3"
  imgAltText="Illustration of a seedling"
  [animationUrl]="'assets/images/illustrations/home_whatIsMyGrowth.svg'"
  [illustrationStyle]="{top: '0%', left: '0%', width: '200%', height: '600%', color: '#00D7A0'}">
</gt-round-cards> -->
<section class="image-section">
  <div class="h2">
    <span [innerHTML]="whatIsItContent.title1 | translate"></span>
    <gt-animated-underline [color]="whatIsItContent.animationColor"
      ><span class="strong"> {{ whatIsItContent.titleAnim | translate }}</span></gt-animated-underline
    >
  </div>
  <p [innerHTML]="whatIsItContent.desc | translate"></p>
</section>
<div class="growthtalksimage" [class]="translateService.currentLang"></div>

<gt-centered-card
  [sectionData]="growthMindsetContent"
  [animationUrl]="'assets/images/illustrations/home_grow.svg'"
  [illustrationStyle]="{ top: '-25%', left: '37%', width: '70%', height: '100%' }"
></gt-centered-card>

<gt-content-section
  *ngIf="translateService.currentLang !== 'en' && translateService.currentLang !== 'de'"
  scheme="sand"
  illustrationMobilePosition="right-to-title"
  imagePositionWhenNextToCopy="right"
  imagePositionWhenBelowCopy="left"
  imgAltText="Illustration of a fist with stars above it"
  animationColor="#00D7A0"
  [sectionContent]="strengthsContent"
  [illustrationUrl]="'assets/images/illustrations/home_unlock_strengths_new.svg'"
  [illustrationStyle]="{ width: '300%', height: '100%', left: '-100%' }"
  [hasImage]="false"
  size="reducedForPageNavigation"
>
</gt-content-section>

<gt-round-cards
  [sectionData]="upskillContent"
  [columns]="3"
  imgAltText="illustration of a hand holding three playing cards"
  [animationUrl]="'assets/images/illustrations/home_upskill.svg'"
  [illustrationStyle]="{ left: '-70%', width: '250%', height: '250%' }"
>
</gt-round-cards>

<gt-content-section
  scheme="sand"
  illustrationMobilePosition="right-to-title"
  imagePositionWhenNextToCopy="right"
  imagePositionWhenBelowCopy="left"
  animationColor="#00D7A0"
  [sectionContent]="careerContent"
  [illustrationUrl]="'assets/images/illustrations/arrow_up.svg'"
  imgAltText="A man scribbles down his ideas on the glass wall of an office with a white marker"
  size="reducedForPageNavigation"
  [illustrationStyle]="{ top: '-5%', left: '-15%', width: '100%' }"
>
</gt-content-section>

<gt-round-list-card scheme="sand" [color]="'green'" [sectionData]="pointsContent"></gt-round-list-card>

<gt-content-section
  scheme="light"
  illustrationMobilePosition="right-to-title"
  imagePositionWhenNextToCopy="right"
  imagePositionWhenBelowCopy="left"
  animationColor="#00D7A0"
  [sectionContent]="growthTalksContent"
  [illustrationUrl]="'assets/images/illustrations/home_talk.svg'"
  imgAltText="A man holds his chin and looks up while illustrated speech bubbles above him suggest that he is deliberating"
  size="reducedForPageNavigation"
  [illustrationStyle]="{ top: '-30%', left: '-30%', width: '80%' }"
>
</gt-content-section>

<!-- <gt-tiles-list [layout]="'cards'" [theme]="'sand'" [sectionContent]="keyElementsContent"></gt-tiles-list> -->

<gt-myGrowth-home-teasers></gt-myGrowth-home-teasers>

<section class="sgt-illustratedteaser" data-bg="sand" data-scheme="3/4" data-illustration-right="true">
  <div class="h2" [innerHTML]="'myGrowth.home.animatedTeaser.title' | translate"></div>
  <p class="strong" *ngIf="translateService.currentLang === 'en'">{{ "myGrowth.home.animatedTeaser.prompt" | translate }}</p>
  <div class="btns">
    <gt-custom-link
      [trackingString]="'myGrowth.home.animatedTeaser.btnText'"
      [url]="'https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIxMzAzODk2MDY0MCJ9/'"
    >
      <div class="btn btn-hero">
        <span>{{ "myGrowth.home.animatedTeaser.btnText" | translate }}</span>
      </div>
    </gt-custom-link>
    <gt-custom-link
      *ngIf="translateService.currentLang === 'en'"
      [trackingString]="'myGrowth.home.animatedTeaser.btnText2'"
      [url]="
        'https://teams.microsoft.com/l/team/19%3aaG8G4vw8g3zg1bD479raU4piZpdmGjPffj1FnueSXbI1%40thread.tacv2/conversations?groupId=7c66ec62-4871-4522-8c15-0a0b084d77d6&tenantId=38ae3bcd-9579-4fd4-adda-b42e1495d55a'
      "
    >
      <div class="btn btn-hero">
        <span>{{ "myGrowth.home.animatedTeaser.btnText2" | translate }}</span>
      </div>
    </gt-custom-link>
  </div>
  <gt-animated-illustration
    illustrationURL="assets/images/illustrations/home_community.svg"
    [illustrationStyle]="{ top: '0%', left: '0%', width: '150%', height: '150%', color: '#00D7A0' }"
  >
  </gt-animated-illustration>
</section>

<gt-content-section
  *ngIf="translateService.currentLang === 'en' || translateService.currentLang === 'de'"
  class="leaders"
  scheme="sand"
  illustrationMobilePosition="right-to-title"
  imagePositionWhenNextToCopy="right"
  imagePositionWhenBelowCopy="left"
  [sectionContent]="diveDeeperContent"
  [illustrationUrl]=""
  [illustrationStyle]=""
  [hasImage]="false"
  size="reducedForPageNavigation"
>
</gt-content-section>

<gt-whats-next
  [title]="'myGrowth.home.whats-next.title'"
  [layout]="'sectionChange'"
  [btnLink]="'/self-reflection/why-self-reflection'"
  [theme]="'light'"
  [illustrationUrl]="'assets/images/illustrations/home_whatsNext.svg'"
  [illustrationStyle]="{ top: '-30%', left: '-40%', width: '180%', height: '160%', color: '#00D7A0' }"
>
</gt-whats-next>
