import { Component, Input, OnInit } from '@angular/core';
import { AnimatedIllustrationStyle } from '../../models/animated-illustration-style.model';

@Component({
  selector: 'gt-animated-strokes-top-right',
  templateUrl: './animated-strokes-top-right.component.html',
  styleUrls: ['./animated-strokes-top-right.component.scss'],
})
export class AnimatedStrokesTopRightComponent implements OnInit {
  @Input()
  set color(color: string) {
    this.illustrationStyle = {
      top: '-30%',
      left: '90%',
      width: '30%',
      height: '100%',
      color,
    };
  }

  illustrationURL = '/assets/images/illustrations/green-strokes-illustration.svg';
  illustrationStyle: AnimatedIllustrationStyle = {
    top: '-30%',
    left: '90%',
    width: '30%',
    height: '100%',
    color: '#00D7A0',
  };

  constructor() {}

  ngOnInit(): void {}
}
