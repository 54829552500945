import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AudioService } from '../../services/audio.service';
import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Route, Router } from '@angular/router';

@Component({
  selector: 'gt-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrl: './audio-player.component.scss',
})
export class AudioPlayerComponent implements OnDestroy, OnInit {
  @Input() set audioId(value: string) {
    if (value) {
      this._audioId = value;
      this.getAudioUrl(value);
    }
  }
  get audioId() {
    return this._audioId;
  }
  private _audioId: string;
  protected audioUrl: string;
  protected loading: boolean = false;
  protected destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private audioService: AudioService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.langChangeListener();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private langChangeListener(): void {
    this.route.queryParams
      .pipe(
        debounceTime(200),
        filter(params => params.lang),
        tap(params => this.getAudioUrl(this.audioId, params.lang)),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private getAudioUrl(audioId: string, lang?: string): void {
    this.loading = true;
    this.audioService
      .getAudioUrl(audioId, lang ? lang : this.translateService.currentLang)
      .pipe(
        tap(url => {
          this.audioUrl = url;
          this.cd.detectChanges();
          this.loading = false;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }
}
