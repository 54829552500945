import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Select } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';
import { UserState } from '../state-management/user/user.state';
import { IUserData } from '../interfaces/IUserData';

@Injectable({
  providedIn: 'root',
})
export class MailingService implements OnInit, OnDestroy {
  @Select(UserState.loggedUser) userData$: Observable<IUserData>;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  userdata: IUserData;

  constructor(private http: HttpClient) {}

  async ngOnInit(): Promise<void> {
    this.getUserData();
  }

  private getUserData(): void {
    this.userData$
      .pipe(
        tap(userData => {
          this.userdata = userData;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  sendEmail(options: { templateId: string; receivers?: string[]; interpolateWith?: Object; language?: string }): void {
    this.http
      .post(environment.backendUrl + 'mailings', {
        templateId: options.templateId,
        receivers: options.receivers ? options.receivers : [this.userdata.email],
        interpolateWith: options.interpolateWith,
      })
      .toPromise();
  }
}
