<section
  class="sgt-subpagehero"
  [attr.data-scheme]="scheme"
  [attr.data-illustration-mobile-position]="illustrationMobilePosition"
  [attr.data-image-position-when-next-to-copy]="imagePositionWhenNextToCopy"
  [attr.data-image-position-when-below-copy]="imagePositionWhenBelowCopy"
>
  <div class="sgt-subpagehero-copy">
    <div class="h3">{{ subTitleTranslationString | translate }} {{ userName | uppercase }}</div>
    <div class="h2">
      <span *ngIf="title1TranslationString && translateService.instant('title1TranslationString')" [innerHTML]="title1TranslationString | translate"></span>
      <gt-animated-underline *ngIf="titleTranslationStringAnim && showAnimation" [strokeType]="animType" [color]="animationColor"
        ><span class="strong"> {{ titleTranslationStringAnim | translate }}</span></gt-animated-underline
      >
      <span class="strong" *ngIf="title1TranslationString && !showAnimation">{{ titleTranslationStringAnim | translate }}</span>
      <span *ngIf="title2TranslationString" [innerHTML]="title2TranslationString | translate"></span>
    </div>
    <p [innerHTML]="textTranslationString | translate"></p>

    <ng-content></ng-content>
  </div>
  <figure *ngIf="illustrationUrl" [attr.data-size]="size">
    <gt-animated-illustration *ngIf="illustrationUrl" [illustrationURL]="illustrationUrl" [illustrationStyle]="illustrationStyle">
      <img [src]="imgUrl" [alt]="imgAltText" />
    </gt-animated-illustration>
    <img *ngIf="!illustrationUrl && imgUrl" [src]="imgUrl" [alt]="imgAltText" />
  </figure>
</section>
