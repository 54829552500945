<div id="top-header" class="sgt-menubar" data-scheme="sand">
  <div class="header-container">
    <div class="sgt-menubar-menubutton" (click)="mobileNavOpen = !mobileNavOpen">
      <div class="sgt-menubar-menubutton-show" style="transform: translate(0%, 0px)"></div>
      <div class="sgt-menubar-menubutton-close" style="transform: translate(0%, 0px)"></div>
    </div>

    <h1 class="logoSection" role="banner">
      <gt-custom-link [trackingEvent]="TRACKING_ITEM.menu" [trackingString]="'SIEMENS logo'" url="/" class="logo desktop"
        ><img src="assets/images/sie-logo-petrol-rgb.svg" [alt]="'Siemens logo'"
      /></gt-custom-link>
      <gt-custom-link [trackingEvent]="TRACKING_ITEM.menu" [trackingString]="'SIEMENS logo'" url="/" class="logo mobile"
        ><img src="assets/images/sie-logo-white-rgb.svg" [alt]="'Siemens logo'"
      /></gt-custom-link>
      <div class="intra desktop"><img src="assets/icons/intranet_logo.svg" alt="Intranet logo" /></div>
      <div class="intra mobile"><img src="assets/icons/intranet_logo_white.svg" alt="Intranet logo" /></div>
      <gt-custom-link [doTracking]="false" url="/" class="appName" *ngIf="!showLeadersView()">MyGrowth</gt-custom-link>
      <div class="appName" *ngIf="showLeadersView()">
        <button mat-button [matMenuTriggerFor]="menu" class="app-menu-btn">
          <span class="text" [innerHTML]="'header.viewSwitch.generalView.btn' | translate"></span>
          <span class="icon-Arrowhead-down"></span>
        </button>
        <mat-menu #menu="matMenu" class="appMenu">
          <button mat-menu-item class="app-menu-item" (click)="goTo('leaders')">
            <span class="icon icon-Arrowhead-right"></span><span [innerHTML]="'header.viewSwitch.generalView.switchToLeaders' | translate"></span>
          </button>
          <button mat-menu-item class="app-menu-item active" (click)="goTo('general')">
            <span class="icon icon-Arrowhead-right"></span><span [innerHTML]="'header.viewSwitch.generalView.homepage' | translate"></span>
          </button>
        </mat-menu>
      </div>
    </h1>
    <nav aria-label="primary" class="sgt-menubar-admin" *ngIf="isAdminRoute">
      <gt-custom-link [doTracking]="false" url="/admin" class="no-underline"
        ><span class="admin-role strong">{{ adminBanner }}</span></gt-custom-link
      >
      <gt-custom-link [doTracking]="false" url="/" class="no-underline"><span class="material-icons">logout</span></gt-custom-link>
    </nav>
    <div class="header-right" *ngIf="!isAdminRoute">
      <div class="header-icon" *ngIf="translate.currentLang === 'en'" [class.active]="showSearch" (click)="toggleSearch()">
        <span class="material-icons">search</span>
      </div>
      <div class="header-icon" *ngIf="languages.length" [matMenuTriggerFor]="langMenu">
        <span class="material-icons">language</span>
      </div>
      <gt-custom-link [doTracking]="false" url="/admin" class="no-underline" *ngIf="isAdmin">
        <div
          class="admin-link"
          [matTooltip]="'myGrowth.navigation.adminTooltip' | translate"
          matTooltipPosition="below"
          [matTooltipClass]="'sie-tooltip'"
          [matTooltipHideDelay]="500"
        >
          <span class="material-icons">admin_panel_settings</span>
        </div>
      </gt-custom-link>
    </div>
    <mat-menu #langMenu="matMenu" [xPosition]="'after'" [class]="'langSwitchPanel'">
      <button mat-menu-item *ngFor="let lang of languages" [class.active]="lang.code === currentLang.code" (click)="changeLanguage(lang.code)">
        {{ lang.name }}
      </button>
    </mat-menu>
    <nav aria-label="primary" class="sgt-menubar-flyout" [class.open]="mobileNavOpen">
      <section>
        <div class="appName">MyGrowth</div>
        <ul>
          <li *ngFor="let item of headerLinks">
            <gt-custom-link [doTracking]="false" [url]="item.link">{{ "myGrowth.navigation." + item.name | translate }}</gt-custom-link>
          </li>
        </ul>
      </section>
    </nav>
  </div>
  <div class="header-container">
    <nav aria-label="primary" class="sgt-menubar-static" *ngIf="!isAdminRoute">
      <ul>
        <li *ngFor="let item of headerLinks" routerLinkActive="active" [routerLink]="item.link">
          <gt-custom-link [trackingEvent]="TRACKING_ITEM.menu" [trackingString]="'myGrowth.navigation.' + item.name" [url]="item.link">{{
            "myGrowth.navigation." + item.name | translate
          }}</gt-custom-link>
        </li>
      </ul>
    </nav>
  </div>
</div>

<gt-search *ngIf="showSearch"></gt-search>
