import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'gt-add-language-dialog',
  templateUrl: './add-language-dialog.component.html',
  styleUrls: ['./add-language-dialog.component.scss'],
})
export class AddLanguageDialogComponent {
  addForm = new UntypedFormGroup({
    code: new UntypedFormControl('', [Validators.required]),
    name: new UntypedFormControl('', [Validators.required]),
    rssCode: new UntypedFormControl('', []),
  });

  constructor(
    private dialogRef: MatDialogRef<AddLanguageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { code: string; name: string; rssCode: string },
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }

  onChange(key: string, event: any): void {
    this.data[key] = event.target.value;
  }

  onSubmit(): void {
    if (!this.addForm.invalid) {
      this.dialogRef.close(this.data);
    }
  }
}
