import { Component, Input } from '@angular/core';

@Component({
  selector: 'gt-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
})
export class TagsComponent {
  @Input() tags: {
    imgUrl: string;
    text: string;
    url?: string;
    fragment?: string;
  }[];
}
