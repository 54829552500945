<div class="loading-indicator-container" #animContainer>
  <svg height="100%" id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 975.33 669.34">
    <g id="pot">
      <path
        class="cls-2"
        d="M85.38,667.84c4.24-10.85,8.31-21.76,12.21-32.74,2.76-7.78,5.44-15.6,7.41-23.61,2.01-8.16,3.3-16.56,6.61-24.28,.98-2.29,2.34-4.7,4.67-5.58s5.62,.88,5.19,3.34c-.38,2.17-2.99,3-5.16,3.37-2.41,.41-4.85,.77-7.28,.52-1.61-.17-37.39-.93-47.67-1.26-19.94-.63-39.9-1.44-59.85-1.08"
      />
      <path class="cls-2" d="M101.66,600.19c-6.87-.34-89.08-1.58-99.43-3.17" />
    </g>
    <g id="part1">
      <path
        id="branch"
        class="cls-2"
        d="M51.03,584.67c7.9-11.55,14.48-24.01,19.57-37.04,1.45-3.7,2.79-7.52,2.99-11.49,.27-5.45-2.14-11.41-7.12-13.64s-12.08,1.24-12.01,6.7c.04,2.81,1.83,5.35,4.06,7.07,5.14,3.97,12.49,4.19,18.52,1.8s10.92-7.03,14.95-12.12c12.68-15.99,18.11-37.51,14.55-57.6-.86-4.82-2.2-9.54-3.42-14.29-1.44-5.57-2.72-11.17-3.84-16.81-4.49-22.6-6.04-47.42,5.44-67.4,4.7-8.18,11.87-15.67,13.89-25.14,2.07-9.71-6.51-18.06-6.07-28.33,.33-7.48,4.51-15.16,11.51-17.8,3.29-1.24,7.56-1.03,9.65,1.79,1.88,2.54,1.21,6.34-.86,8.72s-5.2,3.58-8.3,4.2c-1.01,.2-2.07,.35-3.08,.13-3.11-.69-4.4-4.4-4.69-7.56-1.32-14.35,6.73-28.01,16.59-38.52s21.76-19.02,31.06-30.04c4.13-4.89,7.84-10.61,8.14-17.01,.26-5.65-2.17-11.05-4.78-16.07-12.9-24.86-37.29-42.09-44.72-69.49-5.82-21.45,2.55-46.54,6.53-54.69,5.75,8.75,8.2,18.27,16.16,25.06,3.32,2.83,10.85,9.33,13.99,12.35,12.8,12.32,12.96,28.96,2.23,43.12"
      />
      <g>
        <path class="cls-2" d="M98.17,510.94c-3.62-8.64-7.35-17.32-12.63-25.06s-12.26-14.57-20.89-18.23" />
        <path class="cls-2" d="M99.18,511.26c-2.67,.26-5.11-1.42-7.16-3.14-6.59-5.53-12.04-12.42-15.89-20.1" />
        <path class="cls-2" d="M95.09,517.17c-17.11-10.56-29.2-28.9-32.15-48.78" />
      </g>
      <g>
        <path class="cls-2" d="M106.07,457.97c3.16-8.29,7.09-16.69,13.98-22.27,5.65-4.57,12.77-6.83,19.71-8.99,3.78-1.18,7.7-2.37,11.63-1.93" />
        <path class="cls-2" d="M108.36,455.29c11.68-.36,23.76-3.17,32.69-10.71,6.17-5.21,10.53-12.53,12.16-20.43" />
        <path class="cls-2" d="M111.24,453.98c7.77-9.01,19.07-14.02,29.98-18.75" />
      </g>
      <g>
        <path class="cls-2" d="M61.54,363.63c14.27,4.96,26.4,15.79,32.94,29.41" />
        <path
          class="cls-2"
          d="M95.72,394.24c-12.99,2.55-26.93-1.7-36.97-10.33-10.05-8.63-16.26-21.31-17.95-34.44,10.95-1.45,22.32,.32,32.31,5.04,2.63,1.24,5.19,2.7,7.34,4.65,5.28,4.78,7.59,11.94,9.69,18.74"
        />
      </g>
      <g>
        <path class="cls-2" d="M143.26,257.99c6.27-6.27,13.3-12.32,21.87-14.59,7.03-1.86,14.45-1.01,21.66-.14,3.93,.47,7.99,.99,11.4,3" />
        <path class="cls-2" d="M146.45,256.48c10.8,4.45,22.98,6.83,34.21,3.61,7.76-2.23,14.73-7.12,19.46-13.67" />
        <path class="cls-2" d="M149.61,256.47c10.77-5.04,23.14-4.99,35.03-4.84" />
      </g>
    </g>
    <g id="part2">
      <path
        class="cls-2"
        d="M110.02,490.43c12.51-4.8,24.38-11.13,36.21-17.43,3.75-2,7.67-4.15,9.93-7.75s2.1-9.09-1.48-11.39-9.51,1.76-7.62,5.57c11.19,2.24,22.29-4.08,31.04-11.4s16.75-16.08,27.38-20.24c18.3-7.17,39.49,1.06,58.42-4.21,3.27-.91,6.58-2.32,8.71-4.97s2.71-6.78,.53-9.39-7.25-2.21-8.3,1.02c-1,3.07,2.12,6.21,5.32,6.53s6.26-1.26,9.1-2.79c6.7-3.62,13.6-7.41,18.24-13.44,5.13-6.66,6.91-15.29,11.13-22.56,6.9-11.91,19.77-19.23,32.95-23.18,13.99-4.19,29.75-5.65,40.39-15.65,2.94-2.77,5.45-6.36,5.6-10.4s-2.82-8.34-6.86-8.48-7.29,5.42-4.5,8.34c1.4,1.46,3.61,1.78,5.63,1.68,11.08-.58,19.05-11.04,23.04-21.4s5.79-21.81,12.34-30.77c4.82-6.6,11.8-11.24,18.63-15.73,10.35-6.82,20.71-13.63,31.06-20.45"
      />
      <g>
        <path class="cls-2" d="M201.62,391.43c2.49,14.9-1.2,30.74-10.02,43" />
        <path
          class="cls-2"
          d="M191.13,436.1c-8.46-10.18-11.42-24.45-8.66-37.4s10.91-24.48,21.63-32.26c6.52,8.92,10.41,19.74,11.06,30.77,.17,2.9,.12,5.85-.56,8.68-1.67,6.93-6.84,12.38-11.81,17.49"
        />
      </g>
      <g>
        <path class="cls-2" d="M235.99,425.12c8.87-.01,18.12,.66,25.79,5.11,6.29,3.64,10.94,9.49,15.44,15.2,2.45,3.11,4.97,6.34,5.96,10.18" />
        <path class="cls-2" d="M239.32,426.3c4.51,10.78,11.45,21.06,21.68,26.71,7.07,3.9,15.46,5.36,23.43,4.06" />
        <path class="cls-2" d="M241.57,428.53c11.19,4.04,19.9,12.8,28.22,21.3" />
      </g>
    </g>
    <g id="part3">
      <path
        class="cls-2"
        d="M334.01,355.87c4.39-9.17,8.09-18.67,11.05-28.39,2.95-9.68,5.17-19.96,3.19-29.88-1-4.99-3.04-9.77-3.55-14.83s.94-10.82,5.29-13.46,11.49,.23,11.27,5.31c-.2,4.69-5.78,7.2-10.47,7.27-8.21,.12-16.13-3.89-21.93-9.7s-9.69-13.28-12.51-21c-2.54-6.94-4.45-14.45-9.52-19.83-3.45-3.66-8.06-5.99-11.97-9.15s-7.33-7.77-6.78-12.77"
      />
      <g>
        <path class="cls-2" d="M335.37,205.21c-.19,15.1-6.62,30.04-17.47,40.55" />
        <path
          class="cls-2"
          d="M317.15,247.32c-6.53-11.52-6.91-26.08-1.91-38.34s15.07-22.17,26.99-27.93c4.84,9.93,6.76,21.27,5.44,32.24-.35,2.89-.92,5.78-2.09,8.44-2.86,6.52-8.92,10.98-14.71,15.12"
        />
      </g>
      <g>
        <path class="cls-2" d="M341.76,332.59c-3.62-8.64-7.35-17.32-12.63-25.06s-12.26-14.57-20.89-18.23" />
        <path class="cls-2" d="M342.76,332.91c-2.67,.26-5.11-1.42-7.16-3.14-6.59-5.53-12.04-12.42-15.89-20.1" />
        <path class="cls-2" d="M338.67,338.82c-17.11-10.56-29.2-28.9-32.15-48.78" />
      </g>
    </g>
    <g id="part4">
      <path
        class="cls-2"
        d="M305.57,385.7l46.28-.19c8.15-.03,16.44-.05,24.24,2.32,7.8,2.37,15.16,7.53,18.17,15.1,.41,1.03,.74,2.12,.72,3.23-.04,2.77-2.56,5.26-5.33,5.26s-5.3-2.47-5.35-5.24c-.08-3.9,3.88-6.55,7.48-8.03,28.82-11.79,61.85,2.53,92.62-2.26,14.67-2.28,30.56-9.33,40.8-20.38,11.72-12.65,20.83-14.95,38.73-12.85,16.79,1.97,33.58,3.94,50.37,5.91,4.75,.56,9.84,1.27,13.35,4.52s4.07,9.98-.12,12.29c-4.83,2.66-10.74-3.52-9.78-8.95s5.93-9.18,10.85-11.68c9.8-4.99,20.95-7.31,31.93-6.64,13.18,.8,25.78,5.75,38.9,7.25,9.8,1.12,19.75,.29,29.47-1.43,14.45-2.56,29.34-7.61,38.77-18.86,11.37-13.57,12.42-33.21,22.21-47.96,3.93-5.91,9.36-11.3,10.46-18.31,1.1-7.01-5.87-15.43-12.17-12.17-5.24,2.71-4.56,11.18-.05,14.98s10.96,4.11,16.85,3.79c8.86-.47,17.88-2.01,25.6-6.4,9.22-5.23,15.77-14,22.54-22.16,14.1-16.98,31.44-32.91,53.03-37.52,20.25-4.33,43.27,1.46,60.66-9.78"
      />
      <g>
        <path class="cls-2" d="M540.66,404.53c-12.25-8.84-28.08-12.55-42.99-10.08" />
        <path
          class="cls-2"
          d="M495.97,394.13c5.37,12.1,16.85,21.07,29.68,24.34s26.78,1.08,38.51-5.08c-5.1-9.8-13.08-18.09-22.68-23.55-2.53-1.44-5.19-2.7-8.03-3.34-6.95-1.58-14.13,.64-20.91,2.83"
        />
      </g>
      <g>
        <path class="cls-2" d="M578.73,362.81c7.58-5.51,15.16-11.13,21.48-18.05s11.36-15.29,12.94-24.52" />
        <path class="cls-2" d="M578.65,363.86c-.86-2.54,.2-5.3,1.41-7.69,3.86-7.69,9.31-14.57,15.91-20.09" />
        <path class="cls-2" d="M571.96,361.24c6.34-19.08,21.41-35.06,40.08-42.51" />
      </g>
      <g>
        <path class="cls-2" d="M749.99,357.86c5.84-6.68,11.41-14.09,13.1-22.8,1.39-7.13,.04-14.48-1.3-21.63-.73-3.89-1.52-7.91-3.76-11.18" />
        <path class="cls-2" d="M751.28,354.58c-5.16-10.48-8.35-22.47-5.88-33.89,1.7-7.89,6.12-15.17,12.34-20.33" />
        <path class="cls-2" d="M751.08,351.42c4.31-11.08,3.43-23.42,2.49-35.27" />
      </g>
      <g>
        <path class="cls-2" d="M846.29,204.41c9.02,12.12,12.98,27.89,10.74,42.83" />
        <path
          class="cls-2"
          d="M857.38,248.93c-12.18-5.19-21.33-16.52-24.8-29.3s-1.49-26.76,4.48-38.58c9.88,4.95,18.29,12.8,23.9,22.31,1.48,2.51,2.78,5.15,3.46,7.97,1.68,6.92-.42,14.14-2.51,20.95"
        />
      </g>
    </g>
    <g id="part5">
      <path
        class="cls-2"
        d="M861.65,246.49c13.85-20.82,38.18-37.68,41.55-62.45,2.31-16.99,9.46-45.94,14.37-59.29,1.12-3.03,2.85-5.86,4.43-8.65,2.04-3.61,4.66-6.87,7.59-9.78,8.96-8.89,21.06-14.55,33.63-15.73,2.85-.27,7.87-1.01,10.61-.17-2.42,2.42-6.23,5.88-7.9,8.86-4.11,7.38-6.72,15.16-6.81,16.89-.27,5.04-1.9,9.17-3.81,12.94-1.35,2.67-4.58,5.77-6.81,7.76-2.81,2.52-14.81,7.08-22.36,3.75"
      />
    </g>
    <g id="part6">
      <path
        class="cls-2"
        d="M661.03,358.68c10.62,1.49,14.68,14.33,21.76,22.39,3.33,3.79,7.57,6.65,11.68,9.58,9.29,6.65,18.13,13.93,26.44,21.78,4.7,4.44,9.39,11.91,4.99,16.65-3.97,4.29-12.24,.22-12.67-5.61-.42-5.83,4.95-10.85,10.7-11.92s11.62,.85,16.98,3.18c18.24,7.94,34.2,21.06,45.5,37.42,4.65,6.73,8.58,14.06,14.37,19.85,5.78,5.79,14.05,9.93,22.05,8.22"
      />
      <g>
        <path class="cls-2" d="M775.27,440.46c.64,9.35,1.19,18.77-.06,28.06-1.25,9.29-4.44,18.52-10.51,25.66" />
        <path class="cls-2" d="M776.02,439.72c-2.5,.96-3.93,3.55-5,6.01-3.42,7.89-5.2,16.49-5.21,25.09" />
        <path class="cls-2" d="M769.71,436.27c-10.57,17.1-13.16,38.91-6.9,58.02" />
      </g>
    </g>
    <g id="part7">
      <path
        class="cls-2"
        d="M463.38,397.56c-.95-11.31,6.55-21.7,15.3-28.93s19.06-12.53,27.18-20.46,13.9-19.86,10.21-30.6c-1.01-2.92-2.84-5.75-5.66-7.01s-6.71-.35-7.97,2.47c-1.42,3.15,1.14,6.99,4.44,8.04s6.88,.07,10.07-1.28c9.39-3.98,17.39-11.17,22.33-20.09,2.11-3.81,3.71-7.96,6.48-11.33,10.21-12.38,29.01-8.8,41.26-17.62s28.8-19.36,34.07-34.61c2.79-8.07,2.44-16.99,5.43-24.98,1.34-3.58,3.59-7.13,7.16-8.52s8.46,.48,9.03,4.26c.42,2.81-1.59,5.46-3.95,7.05-8.06,5.44-20.17,1.33-25.03-7.1s-3.36-19.46,1.74-27.74,13.27-14.22,21.76-18.98c9.34-5.23,14.61-9.69,19.69-19.12,1.73-3.2,8.71-18.12,8.13-27.74-1.66-27.44-8.58-58.06,5.68-83.33,8.01-14.19,21.91-25.25,37.89-28.46-4.55,6.79-5.83,15.24-6.24,23.41-.25,4.92-.22,9.85,.08,14.77,.45,7.46,1.52,15.02-.03,22.33-1.55,7.31-6.46,14.51-13.76,16.12-4.37,.97-8.91-.17-13.24-1.31-5.48-1.44-10.97-2.87-16.45-4.31-2.33-.61-4.76-1.22-7.12-.73s-4.59,2.46-4.53,4.87c.05,1.95,1.76,3.71,3.7,3.8,2.63,.13,4.75-2.54,4.91-5.17s-1.11-5.13-2.51-7.36c-9.93-15.77-29.04-25.26-47.6-23.63,4.77,4.17,8.58,10.57,8.59,16.91,0,2.42,.35,10.73,.8,13.31,.88,4.95,3.75,9.48,7.14,13.2,6.79,7.45,19.34,8.7,27.48,2.75"
      />
      <g>
        <path class="cls-2" d="M542.16,294.36c4.79-7.47,9.19-15.63,9.57-24.49,.31-7.26-2.11-14.33-4.5-21.19-1.3-3.74-2.67-7.6-5.37-10.5" />
        <path class="cls-2" d="M542.95,290.92c-6.66-9.6-11.59-20.98-10.85-32.64,.51-8.06,3.8-15.91,9.18-21.93" />
        <path class="cls-2" d="M542.29,287.83c2.62-11.6-.08-23.67-2.77-35.25" />
      </g>
    </g>
    <text class="cls-text" transform="translate(215.23 576.79)"><tspan x="0" y="0">Loading...</tspan></text>
  </svg>
</div>
