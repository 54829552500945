import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { filter, map, take, tap } from 'rxjs/operators';
import { AuthService } from './new-auth.service';
import { environment } from 'src/environments/environment';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UserState } from 'src/app/shared/state-management/user/user.state';
import { IUserData } from 'src/app/shared/interfaces/IUserData';

@Injectable({
  providedIn: 'root',
})
export class CompanyGuard {
  @Select(UserState.loggedUser) loggedUser$: Observable<IUserData>;

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return this.loggedUser$
      .pipe(
        filter(userData => !!userData),
        tap(userData => {
          if (!environment.production) {
            /* start debugging logs for MYG-646 */
            /*
          console.log('userData', userData);
          console.log('userData[custom:org_code]', userData.orgCode);
          console.log('this.authService.isForbiddenByOrgCode(userData[custom:org_code])', this.authService.isForbiddenByOrgCode(userData.orgCode));
          */
            /* end debugging logs for MYG-646 */
          }
        }),
        map(userData => {
          if (this.authService.isForbiddenByOrgCode(userData.orgCode)) {
            this.router.navigate(['/forbidden'], { queryParamsHandling: 'merge', queryParams: route.queryParams });
          }
          return true;
        }),
        take(1),
      )
      .toPromise();
  }
}
