import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'gt-admin-link',
  templateUrl: './admin-link.component.html',
  styleUrls: ['./admin-link.component.scss'],
})
export class AdminLinkComponent {
  constructor(private router: Router) {}

  @Input() icon: string;
  @Input() title: string;
  @Input() link: string;
  @Input() disabled: boolean;
  @Input() tooltip: string;

  navigate(): void {
    if (this.disabled) return;

    if (this.link.startsWith('/')) {
      this.router.navigate([this.link], { queryParamsHandling: 'preserve' });
    } else {
      window.location.href = this.link;
    }
  }
}
