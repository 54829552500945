import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { BrandbuilderService } from 'src/app/shared/services/brandbuilder.service';
import { LoadingIndicatorService } from 'src/app/shared/services/loading-indicator.service';

@Injectable({
  providedIn: 'root',
})
export class BrandBuilderGuard {
  constructor(
    private service: BrandbuilderService,
    private loadingIndicator: LoadingIndicatorService,
  ) {}

  async canActivate(next: ActivatedRouteSnapshot): Promise<boolean> {
    this.loadingIndicator.updateLoadingIndicatorStatus(true);
    if (!this.service.data) {
      await this.service.load(); // load data before start
    }
    this.loadingIndicator.updateLoadingIndicatorStatus(false);
    return true;
  }
}
