<div id="top-header" class="sgt-menubar" data-scheme="deepblue">
  <div class="header-container">
    <div class="sgt-menubar-menubutton" (click)="mobileNavOpen = !mobileNavOpen">
      <div class="sgt-menubar-menubutton-show" style="transform: translate(0%, 0px)"></div>
      <div class="sgt-menubar-menubutton-close" style="transform: translate(0%, 0px)"></div>
    </div>

    <h1 class="logoSection" role="banner">
      <gt-custom-link [trackingEvent]="TRACKING_ITEM.menu" [trackingString]="'SIEMENS logo'" url="/leaders" class="logo desktop"
        ><img src="assets/images/sie-logo-white-rgb.svg" [alt]="'Siemens logo'"
      /></gt-custom-link>
      <gt-custom-link [trackingEvent]="TRACKING_ITEM.menu" [trackingString]="'SIEMENS logo'" url="/leaders" class="logo mobile"
        ><img src="assets/images/sie-logo-white-rgb.svg" [alt]="'Siemens logo'"
      /></gt-custom-link>
      <div class="intra desktop"><img src="assets/icons/intranet_logo_white.svg" alt="Intranet logo" /></div>
      <div class="intra mobile"><img src="assets/icons/intranet_logo_white.svg" alt="Intranet logo" /></div>
      <div class="appName">
        <button mat-button [matMenuTriggerFor]="menu" class="app-menu-btn">
          <span class="text" [innerHTML]="'header.viewSwitch.leadersView.btn' | translate"></span>
          <span class="icon-Arrowhead-down"></span>
        </button>
        <mat-menu #menu="matMenu" class="appMenu dark">
          <button mat-menu-item class="app-menu-item active" (click)="goTo('leaders')">
            <span class="icon icon-Arrowhead-right"></span><span [innerHTML]="'header.viewSwitch.leadersView.homepage' | translate"></span>
          </button>
          <button mat-menu-item class="app-menu-item" (click)="goTo('general')">
            <span class="icon icon-Arrowhead-right"></span><span [innerHTML]="'header.viewSwitch.leadersView.switchToGeneral' | translate"></span>
          </button>
        </mat-menu>
      </div>
    </h1>

    <div class="header-right" *ngIf="languages.length">
      <div class="header-icon" *ngIf="translate.currentLang === 'en'" [class.active]="showSearch" (click)="toggleSearch()">
        <span class="material-icons">search</span>
      </div>
      <div class="header-icon" [matMenuTriggerFor]="langMenu">
        <span class="material-icons">language</span>
      </div>
    </div>
    <mat-menu #langMenu="matMenu" [xPosition]="'after'" [class]="'langSwitchPanel dark'">
      <button mat-menu-item *ngFor="let lang of languages" [class.active]="lang.code === currentLang.code" (click)="changeLanguage(lang.code)">
        {{ lang.name }}
      </button>
    </mat-menu>
    <nav aria-label="primary" class="sgt-menubar-flyout" [class.open]="mobileNavOpen">
      <section>
        <div class="appName">MyGrowth</div>
        <ul>
          <li *ngFor="let item of headerLinks">
            <gt-custom-link [url]="item.link">{{ "myGrowth.navigation." + item.name | translate }}</gt-custom-link>
          </li>
        </ul>
      </section>
    </nav>
  </div>
  <div class="header-container">
    <nav aria-label="primary" class="sgt-menubar-static">
      <ul>
        <li *ngFor="let item of headerLinks" routerLinkActive="active" [routerLink]="item.link">
          <gt-custom-link [trackingEvent]="TRACKING_ITEM.menu" [trackingString]="'myGrowth.navigation.' + item.name" [url]="item.link">{{
            "myGrowth.navigation." + item.name | translate
          }}</gt-custom-link>
        </li>
      </ul>
    </nav>
  </div>
</div>

<gt-search *ngIf="showSearch"></gt-search>
