import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'gt-delete-item-dialog',
  templateUrl: './delete-item-dialog.component.html',
  styleUrls: ['./delete-item-dialog.component.scss'],
})
export class DeleteItemDialogComponent {
  constructor(private dialogRef: MatDialogRef<DeleteItemDialogComponent>) {}

  public onClose(isDeleteConfirmed: boolean): void {
    this.dialogRef.close(isDeleteConfirmed);
  }
}
