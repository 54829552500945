import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MYG_LANGUAGE_CODE } from 'src/app/shared/enums/language-code.enum';
import { BrandbuilderService } from 'src/app/shared/services/brandbuilder.service';
import { ILanguage, LanguageService } from 'src/app/shared/services/language.service';
import { LoadingIndicatorService } from 'src/app/shared/services/loading-indicator.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'gt-bb-header',
  templateUrl: './bb-header.component.html',
  styleUrls: ['./bb-header.component.scss'],
})
export class BbHeaderComponent implements OnInit {
  subs: Subscription[] = [];
  currentLang: ILanguage = { name: 'English', code: MYG_LANGUAGE_CODE.EN, rssCode: '', visible: true };
  languages: ILanguage[] = [];
  environment = environment;

  constructor(
    private bbService: BrandbuilderService,
    private router: Router,
    private route: ActivatedRoute,
    public languageService: LanguageService,
    public translate: TranslateService,
    private loadingIndicator: LoadingIndicatorService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.languages = (await this.languageService.getLanguages()).filter(s => s.code === 'en' || s.code === 'de');
    this.subs.push(
      this.route.queryParams.pipe(debounceTime(200)).subscribe(params => {
        this.loadingIndicator.updateLoadingIndicatorStatus(true);
        if (params['lang']) {
          const langCode = this.languages.filter(s => s.code === params['lang']);
          const defaultObject = { name: 'English', code: MYG_LANGUAGE_CODE.EN, rssCode: '', visible: true };
          this.currentLang = langCode && langCode.length ? langCode[0] : defaultObject;
          this.translate.use(this.currentLang.code);
          this.loadingIndicator.updateLoadingIndicatorStatus(false);
        } else {
          this.translate.use('en');
          this.loadingIndicator.updateLoadingIndicatorStatus(false);
        }
      }),
    );
  }

  public async close(): Promise<void> {
    this.loadingIndicator.updateLoadingIndicatorStatus(true);
    await this.bbService.save();
    this.loadingIndicator.updateLoadingIndicatorStatus(false);
    this.router.navigateByUrl('/');
  }

  public changeLanguage(lang: string): void {
    this.translate.use(lang);
    this.currentLang = this.languages.filter(s => s.code === lang)[0];
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { lang: this.currentLang.code },
    });
  }
}
