import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'gt-gifplayer',
  templateUrl: './gifplayer.component.html',
  styleUrls: ['./gifplayer.component.scss'],
})
export class GifplayerComponent {
  @Input() videolink: string;

  public getType(): string {
    return 'video/mp4';
  }
}
