import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { TrackingService } from 'src/app/shared/services/tracking.service';
import { gsap } from 'gsap';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { environment } from 'src/environments/environment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TRACKING_ITEM } from 'src/app/shared/enums/tracking_item.enum';

@Component({
  selector: 'gt-calendar22-dialog',
  templateUrl: './calendar22-dialog.component.html',
  styleUrls: ['./calendar22-dialog.component.scss'],
})
export class Calendar22DialogComponent implements OnInit {
  @ViewChild('animContainer') animContainer: ElementRef;
  @ViewChild('calendarContainer') calendarContainer: ElementRef;
  //@ViewChild('btnBg') btnBg: ElementRef;
  tl: gsap.core.Timeline;
  isMobile: boolean = false;
  daysWithVideo = ['13', '16'];
  videoUrl: string;

  constructor(
    public dialogRef: MatDialogRef<Calendar22DialogComponent>,
    public translate: TranslateService,
    private trackingService: TrackingService,
    private http: HttpClient,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    @Inject(MAT_DIALOG_DATA) public data: { step: string },
  ) {
    this.breakpointObserver.observe(['(max-width: 800px)']).subscribe((result: BreakpointState) => {
      if (result.matches) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    });
  }

  async ngOnInit(): Promise<void> {
    if (this.dayHasVideo()) {
      this.videoUrl = await this.getVideoUrl(this.data.step);
    }
  }

  async ngAfterViewInit(): Promise<void> {
    const mobilePath = this.isMobile ? '_mobile' : '';
    const dialogAnimSVG = await this.http
      .get('assets/images/calendar22/dialog/dialogAnim' + this.data.step + mobilePath + '.svg', { responseType: 'text' })
      .toPromise();
    const dialogCalendarSVG = await this.http
      .get('assets/images/calendar22/dialog/dialogCalendar' + this.data.step + '.svg', { responseType: 'text' })
      .toPromise();
    // const btnBgSVG = await this.http.get('assets/images/calendar22/dialogBtn' + this.data.step + '.svg', {responseType: 'text'}).toPromise();
    this.animContainer.nativeElement.innerHTML = dialogAnimSVG;
    this.calendarContainer.nativeElement.innerHTML = dialogCalendarSVG;
    // this.btnBg.nativeElement.innerHTML = btnBgSVG;
    const _dialogAnimSvgPaths = Array.from(this.animContainer.nativeElement.querySelectorAll('path, line, polyline'));
    const _dialogCalendarSvgPaths = Array.from(this.calendarContainer.nativeElement.querySelectorAll('path, line, polyline'));
    // const _dialogBtnSvgPaths = Array.from(this.btnBg.nativeElement.querySelectorAll('path, line, polyline'));
    this.tl = gsap.timeline();
    this.tl.fromTo(
      _dialogAnimSvgPaths,
      {
        drawSVG: '0',
      },
      {
        duration: 1,
        drawSVG: '100%',
      },
    );
    this.tl.fromTo(
      _dialogCalendarSvgPaths,
      {
        drawSVG: '0',
      },
      {
        duration: 1,
        drawSVG: '100%',
      },
      0,
    );
    /*
    this.tl.fromTo(_dialogBtnSvgPaths, {
      drawSVG: '0'
    }, {
      duration: 1,
      drawSVG: '100%'
    }, 0);
    */
  }

  private async getVideoUrl(id: string): Promise<string> {
    const url = await this.http.get<string>(environment.backendUrl + 'calendar/calendar22day' + id).toPromise();
    return url;
  }

  public dayHasVideo(): boolean {
    return this.daysWithVideo.includes(this.data.step);
  }

  public btnClicked(url: string): void {
    this.trackingService.addTrackingItem(TRACKING_ITEM.calendar, 'day:' + this.data.step + ':callToActionButton');
    if (url.startsWith('/')) {
      this.router.navigate([url], { queryParamsHandling: 'preserve' });
    } else {
      window.open(url, '_blank');
    }
  }

  ngOnDestroy(): void {
    this.tl?.kill();
  }

  public close(): void {
    this.dialogRef.close();
  }
}
