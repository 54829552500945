export class GetAuthData {
  static readonly type = '[User] Get Auth Data';
  constructor() {}
}

export class GetUserProfile {
  static readonly type = '[User] Get User Profile';
  constructor() {}
}

export class GetUserConfig {
  static readonly type = '[User] Get User Config';
}

export class UpdateUserConfigCalendar {
  static readonly type = '[User] Update User Config Calendar';
  constructor(public calendar: { [yearMonth: string]: number[] }) {}
}

export class UpdateUserConfigSurvey {
  static readonly type = '[User] Update User Config Survey';
  constructor(
    public survey: {
      [surveyConfigId: string]: {
        status: 'later' | 'no' | 'completed';
      };
    },
  ) {}
}

export class UpdateUserConfigVideo {
  static readonly type = '[User] Update User Config Video';
  constructor(public video: { [videoId: string]: { status: 'hide' } }) {}
}
