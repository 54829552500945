import { Component, Inject } from '@angular/core';
import { VideoService } from '../../services/video.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { TrackingService } from '../../services/tracking.service';
import { TRACKING_ITEM } from '../../enums/tracking_item.enum';
import { Store } from '@ngxs/store';
import { UpdateUserConfigVideo } from '../../state-management/user/user.actions';
import { POPUP_TYPE } from '../../enums/popup_type.enum';
import { IVideoDialogInput } from '../../interfaces/IVideoDialog';

@Component({
  selector: 'gt-video-dialog',
  templateUrl: './video-dialog.component.html',
  styleUrls: ['./video-dialog.component.scss'],
})
export class VideoDialogComponent {
  protected videoUrl: string;
  protected showHideLink: boolean = true;
  public popupTypeEnum = POPUP_TYPE;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IVideoDialogInput,
    private videoService: VideoService,
    private store: Store,
    private dialogRef: MatDialogRef<VideoDialogComponent>,
    private trackingService: TrackingService,
  ) {
    this.getVideoUrl();
  }

  private getVideoUrl(): void {
    this.videoService
      .getVideoUrl(this.data.videoId, this.data.lang)
      .pipe(tap(url => (this.videoUrl = url)))
      .subscribe();
  }

  public updateVisibility(): void {
    this.showHideLink = false;
    this.store.dispatch(new UpdateUserConfigVideo({ [this.data.videoId]: { status: 'hide' } }));
    this.trackClick('growthTalksVideo.hide');
  }

  public close(): void {
    this.trackClick('shared.close');
    this.dialogRef.close();
  }

  public trackVideoEnding(videoTrackData: { finished: boolean; skipped: boolean }): void {
    this.trackingService.addTrackingItem(TRACKING_ITEM.video, this.data.videoId + ':finished');
    if (videoTrackData.skipped) {
      this.trackingService.addTrackingItem(TRACKING_ITEM.video, this.data.videoId + ':skipped');
    }
  }

  private trackClick(trackingString: string): void {
    let trackingModifiedString = trackingString ? this.trackingService.getEngTranslation(trackingString) : '';
    trackingModifiedString = trackingModifiedString + ':[from]' + 'growthTalksVideoPopUp';
    this.trackingService.addTrackingItem(TRACKING_ITEM.button, trackingModifiedString);
  }
}
