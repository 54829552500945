import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { TRACKING_ITEM } from 'src/app/shared/enums/tracking_item.enum';
import { TrackingService } from 'src/app/shared/services/tracking.service';

@Component({
  selector: 'gt-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  languageLinks = {
    en: [
      { link: 'https://new.siemens.com/global/en/general/legal.html', name: 'legal' },
      { link: 'https://siemens.com/global/en/general/privacy-notice.html', name: 'privacy-notice' },
      { link: 'https://new.siemens.com/global/en/general/cookie-notice.html', name: 'cookie-notice' },
      { link: 'https://new.siemens.com/global/en/general/terms-of-use.html', name: 'terms-of-use' },
      { link: 'https://new.siemens.com/global/en/general/digital-id.html', name: 'digital-id' },
    ],
    'fr-ca': [
      { link: 'https://new.siemens.com/fr/fr/general/legal.html', name: 'legal' },
      { link: 'https://new.siemens.com/fr/fr/general/vie-privee.html', name: 'privacy-notice' },
      { link: 'https://new.siemens.com/fr/fr/general/politique-de-cookies.html', name: 'cookie-notice' },
      { link: 'https://new.siemens.com/fr/fr/general/conditionsdutilisation.html', name: 'terms-of-use' },
      { link: 'https://new.siemens.com/fr/fr/general/digital-id.html', name: 'digital-id' },
    ],
    de: [
      { link: 'https://new.siemens.com/de/de/general/legal.html', name: 'legal' },
      { link: 'https://new.siemens.com/de/de/general/datenschutz.html', name: 'privacy-notice' },
      { link: 'https://new.siemens.com/de/de/general/cookie-richtlinien.html', name: 'cookie-notice' },
      { link: 'https://new.siemens.com/de/de/general/nutzungsbedingungen.html', name: 'terms-of-use' },
      { link: 'https://new.siemens.com/de/de/general/digitales-zertifikat.html', name: 'digital-id' },
    ],
    zh: [
      { link: 'https://new.siemens.com/cn/zh/general/legal.html', name: 'legal' },
      { link: 'https://new.siemens.com/cn/zh/general/privacy-notice.html', name: 'privacy-notice' },
      { link: 'https://new.siemens.com/global/en/general/cookie-notice.html', name: 'cookie-notice' },
      { link: 'https://new.siemens.com/cn/zh/general/terms-of-use.html', name: 'terms-of-use' },
      { link: 'https://new.siemens.com/global/en/general/digital-id.html', name: 'digital-id' },
    ],
    pl: [
      { link: 'https://new.siemens.com/pl/pl/general/informacje-firmowe.html', name: 'legal' },
      { link: 'https://new.siemens.com/pl/pl/general/polityka-prywatnosci.html', name: 'privacy-notice' },
      { link: 'https://new.siemens.com/pl/pl/general/cookie-notice.html', name: 'cookie-notice' },
      { link: 'https://new.siemens.com/pl/pl/general/warunki-uzytkowania.html', name: 'terms-of-use' },
      { link: 'https://new.siemens.com/global/en/general/digital-id.html', name: 'digital-id' },
    ],
    es: [
      { link: 'https://new.siemens.com/es/es/informacion-corporativa.html', name: 'legal' },
      { link: 'https://new.siemens.com/es/es/informacion-corporativa/politica-privacidad-cookies.html', name: 'privacy-notice' },
      { link: 'https://new.siemens.com/es/es/informacion-corporativa/politica-privacidad-cookies.html', name: 'cookie-notice' },
      { link: 'https://new.siemens.com/es/es/informacion-corporativa/terminos-de-uso.html', name: 'terms-of-use' },
      { link: 'https://new.siemens.com/es/es/informacion-corporativa/digital-id.html', name: 'digital-id' },
    ],
  };

  homepageLinks = {
    en: 'https://www.siemens.com/global/en.html',
    'fr-ca': 'https://new.siemens.com/fr/fr.html',
    de: 'https://www.siemens.com/de/de.html',
    zh: 'https://new.siemens.com/cn/zh.html',
    pl: 'https://new.siemens.com/pl/pl.html',
    es: 'https://new.siemens.com/es/es.html',
  };

  currentYear = new Date().getFullYear();
  footerLinks = this.languageLinks['en'];
  homepageLink = this.homepageLinks['en'];
  sub: Subscription;

  constructor(
    private translate: TranslateService,
    private trackingService: TrackingService,
    private router: Router,
  ) {
    this.translate.onLangChange.subscribe(value => {
      this.footerLinks = this.languageLinks[value.lang];
      this.homepageLink = this.homepageLinks[value.lang];
    });
  }

  public handleClick(url: string, identifier: string): void {
    this.trackingService.addTrackingItem(
      TRACKING_ITEM.button,
      this.trackingService.getEngTranslation(identifier) +
        ':[from]' +
        this.trackingService.cleanRoute(this.router.url) +
        ':[to]' +
        this.trackingService.cleanRoute(url),
    );
    window.open(url, '_blank');
  }
}
