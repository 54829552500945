<div class="admin-dialog">
  <div class="content">
    <div class="dialog-header">
      <h4 class="heading strong">{{ "admin.translations.addTopicDialog.heading" | translate }}</h4>
      <span class="icon-Close" (click)="onClose()"></span>
    </div>
    <form (ngSubmit)="onSubmit()" [formGroup]="addForm">
      <mat-form-field class="form-field">
        <mat-label>{{ "admin.translations.addTopicDialog.form.key" | translate }}</mat-label>
        <input matInput [value]="data.keyName" (change)="onChange($event)" formControlName="key" required />
        <mat-error *ngIf="addForm.get('key').hasError('required')">{{ "admin.translations.addTopicDialog.form.requiredError" | translate }}</mat-error>
      </mat-form-field>
      <br />
      <div class="action-area">
        <button class="btn btn-secondary" (click)="onClose()">{{ "admin.translations.addTopicDialog.cancelButton" | translate }}</button>
        <button class="btn btn-primary" type="submit">{{ "admin.translations.addTopicDialog.addButton" | translate }}</button>
      </div>
    </form>
  </div>
</div>
