import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gt-people-highlight-quote',
  templateUrl: './people-highlight-quote.component.html',
  styleUrl: './people-highlight-quote.component.scss',
})
export class PeopleHighlightQuoteComponent {
  @Input() scheme: 'sand' | 'dark' = 'dark';
  @Input() illustrationMobilePosition: 'below-desc';
  @Input() imagePositionWhenNextToCopy: 'left';
  @Input() imagePositionWhenBelowCopy: 'right';
  @Input() size: 'normal' | 'reducedForPageNavigation';
  @Input() quoteTranslationString: string;
  @Input() name: string;
  @Input() position?: string;
  @Input() country?: string;
  @Input() imgUrl: string;
  @Input() animationColor: string = '#fe8389';
  @Input() illustrationStyle = { top: '-35%', left: '33%', width: '37%' };
  @Input() imgAltText: string;

  showAnimation = true;

  constructor(public translateService: TranslateService) {}
}
