import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'characterHighlight',
})
export class CharacterHighlightPipe implements PipeTransform {
  transform(value: any, args: any): any {
    if (!args) {
      return value;
    }
    let test = [...args];
    let res = [...value];
    test.forEach(character => {
      const i = res.findIndex(s => s.toLowerCase() === character.toLowerCase());
      if (i > -1) {
        res[i] = '<span class="highlight">' + res[i] + '</span>';
      }
    });

    return res.join('');
  }
}
