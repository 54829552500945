import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as prodEnvironment } from 'src/environments/environment.prod';
import { ILanguage } from './language.service';

export enum Stage {
  DEV,
  PROD,
}

export type TranslationChange = {
  action: 'update' | 'rename' | 'create' | 'delete' | 'createTopic';
  path: string;
  value?: string | {};
  newPath?: string;
};

export type UpdateData = {
  lang: string;
  changes: TranslationChange[];
};

@Injectable({
  providedIn: 'root',
})
export class TranslationFileService {
  constructor(private http: HttpClient) {}

  getFile(lang: string, stage: Stage): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(prodEnvironment.backendUrl + 'translations/' + (stage === Stage.PROD ? '' : 'dev/') + lang, { responseType: 'text' })
        .toPromise()
        .then(fileUrl => {
          this.http.get(fileUrl).toPromise().then(resolve).catch(reject);
        })
        .catch(reject);
    });
  }

  downloadFile(lang: string, stage: Stage): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .get(prodEnvironment.backendUrl + 'translations/' + (stage === Stage.PROD ? '' : 'dev/') + lang, { responseType: 'text' })
        .toPromise()
        .then(fileUrl => {
          this.http
            .get(fileUrl, { responseType: 'blob' })
            .toPromise()
            .then(blob => {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.style.display = 'none';
              a.href = url;
              a.download = `${lang}.json`;
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
              resolve();
            })
            .catch(reject);
        })
        .catch(reject);
    });
  }

  updateFile(data: UpdateData, stage: Stage): Promise<any> {
    return this.http.post(prodEnvironment.backendUrl + `translations${stage === Stage.PROD ? '' : '/dev'}/update`, data).toPromise();
  }

  createFile(language: ILanguage, stage: Stage): Promise<any> {
    return this.http.post(prodEnvironment.backendUrl + `translations${stage === Stage.PROD ? '' : '/dev'}`, language).toPromise();
  }
}
