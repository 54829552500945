<div
  class="admin-link-container"
  [class.disabled]="disabled"
  (click)="navigate()"
  [matTooltip]="tooltip ? tooltip : null"
  matTooltipPosition="below"
  [matTooltipClass]="'sie-tooltip'"
  [matTooltipHideDelay]="500"
>
  <h4 class="admin-link-title strong">{{ title }}</h4>
  <span [class]="icon + ' background-icon'"></span>
</div>
