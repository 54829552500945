import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AnimatedIllustration } from 'src/ts/controller/effects/AnimatedIllustration';
import { AnimatedIllustrationStyle } from '../../models/animated-illustration-style.model';

@Component({
  selector: 'gt-animated-illustration',
  templateUrl: './animated-illustration.component.html',
  styleUrls: ['./animated-illustration.component.scss'],
})
export class AnimatedIllustrationComponent implements OnInit, AfterViewInit, OnDestroy {
  controller: AnimatedIllustration;

  private _illustrationURL: string;
  @Input()
  set illustrationURL(url: string) {
    // Force redrawing of SVG
    this._illustrationURL = url;
    setTimeout(() => {
      if (this.controller) {
        this.controller.redraw();
      }
    });
  }
  get illustrationURL() {
    return this._illustrationURL;
  }

  private _illustrationStyle: AnimatedIllustrationStyle;
  @Input()
  set illustrationStyle(style: AnimatedIllustrationStyle) {
    this._illustrationStyle = style;
    setTimeout(() => {
      if (this.controller) {
        this.controller.updateStyles();
      }
    });
  }
  get illustrationStyle() {
    return this._illustrationStyle;
  }

  @Input() align: 'block' | 'end-of-first-line' = 'block';

  @ViewChild('el') el: ElementRef;

  constructor() {}

  ngOnInit(): void {
    this.illustrationStyle.width = this.illustrationStyle.width || '100%';
    this.illustrationStyle.height = this.illustrationStyle.height || '100%';
    this.illustrationStyle.left = this.illustrationStyle.left || '0%';
    this.illustrationStyle.top = this.illustrationStyle.top || '0%';
    this.illustrationStyle.offsetX = this.illustrationStyle.offsetX || '0%';
    this.illustrationStyle.offsetY = this.illustrationStyle.offsetY || '0%';
    this.illustrationStyle.strokeSize = this.illustrationStyle.strokeSize || 'default';
  }

  ngAfterViewInit(): void {
    // Let the browser finish drawing so that the scroll plugin works
    setTimeout(() => {
      this.controller = new AnimatedIllustration(this.el.nativeElement);
    });
  }

  ngOnDestroy(): void {
    if (this.controller) {
      this.controller.destroy();
    }
  }
}
