import { Component, Input } from '@angular/core';
@Component({
  selector: 'gt-whats-next',
  templateUrl: './whats-next.component.html',
  styleUrls: ['./whats-next.component.scss'],
})
export class WhatsNextComponent {
  @Input() title: string;
  @Input() btnLink: string;
  @Input() theme: 'sand' | 'light';
  @Input() layout: 'sectionChange' | 'subsectionChange';
  @Input() illustrationUrl;
  @Input() illustrationStyle;
}
