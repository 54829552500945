import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MyGrowthTeaserService {
  // url = 'https://dcapis.siemens.com/siemensworld-rss?tag=mygrowth'

  constructor(private http: HttpClient) {}

  getTeasers<T>(rssLang: string): Observable<any> {
    const httpOptions = this.getHttpOptions();
    return this.http.get<T>(this.getUrl(rssLang), httpOptions);
  }

  getHttpOptions(options?: any, returnAsArrayBuffer?: boolean) {
    const httpOptions = { ...options }; // ,observe: 'response'
    const headers = {};
    httpOptions.headers = { ...headers, ...(options ? options.headers : {}) };
    httpOptions.responseType = 'text';
    httpOptions.observe = 'body';
    return httpOptions;
  }

  getUrl(rssLang: string): string {
    rssLang = rssLang.length ? '-' + rssLang : rssLang;
    return 'https://dcapis.siemens.com/siemensworld-rss' + rssLang + '?tag=mygrowth';
  }
}
