import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gt-round-cards',
  templateUrl: './round-cards.component.html',
  styleUrls: ['./round-cards.component.scss'],
})
export class RoundCardsComponent {
  @Input() sectionData: {
    animationColor?: string;
    subtitle?: string;
    title1?: string;
    titleAnim?: string;
    title2?: string;
    desc?: string;
    prompt?: string;
    afterCardsText?: string;
    imgAltText?: string;
    buttonLink?: string;
    buttonLinkExternal?: string;
    textLink?: string;
    textLinkUrl?: string;
    titleAlign?: string;
    cards: {
      imgUrl: string;
      imgAltText?: string;
      title: string;
      text: string;
      linkText?: string;
      linkUrl?: string;
      linkText2?: string;
      linkUrl2?: string;
      linkText3?: string;
      linkUrl3?: string;
    }[];
  };
  @Input() scheme: 'light' | 'sand' = 'light';
  @Input() columns: number = 4;
  @Input() animationUrl: string;
  @Input() illustrationStyle: { top?: string; left?: string; width: string; height?: string } = { top: '-35%', left: '33%', width: '37%', height: '100%' };

  constructor(public translateService: TranslateService) {}
}
