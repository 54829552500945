import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IAssetPreview } from '../../interfaces/IAssetPreview';
import { LoadingIndicatorService } from '../../services/loading-indicator.service';
import { TrackingService } from '../../services/tracking.service';
import { TRACKING_ITEM } from '../../enums/tracking_item.enum';
import { DownloadsService } from '../../services/downloads.service';
import { tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'gt-asset-dialog',
  templateUrl: './asset-dialog.component.html',
  styleUrls: ['./asset-dialog.component.scss'],
})
export class AssetDialogComponent {
  isReady: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AssetDialogComponent>,
    private trackingService: TrackingService,
    private router: Router,
    private loadingIndicator: LoadingIndicatorService,
    private downloadsService: DownloadsService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: IAssetPreview,
  ) {
    this.loadingIndicator.updateLoadingIndicatorStatus(true);
  }

  protected close(): void {
    this.dialogRef.close();
  }

  protected documentLoaded(): void {
    this.isReady = true;
    this.loadingIndicator.updateLoadingIndicatorStatus(false);
  }

  protected download(): void {
    const urlForTracking = this.router.url.split('asset=')[0];
    const assetIdentifier = this.data.id ? this.data.id : this.data.title;
    this.trackingService.addTrackingItem(
      TRACKING_ITEM.downloads,
      '[doDownload]:' + assetIdentifier + ':[from]' + this.trackingService.cleanRoute(urlForTracking),
    );

    this.downloadsService
      .getFileFromCDN(this.data.key)
      .pipe(
        tap({
          next: blob => {
            const url = window.URL.createObjectURL(blob);
            this.triggerDownload(this.extractFileNameFromKey(this.data.key), url);
          },
          error: error => {
            console.error('Error on downloading asset:', error);
            this.loadingIndicator.updateLoadingIndicatorStatus(false);
            this.snackBar.open('Error on fetching amount of free codes: ' + error.message, '', { duration: 5000 });
          },
        }),
      )
      .subscribe();
  }

  private triggerDownload(fileName: string, url: string) {
    const aElem = document.createElement('a');
    aElem.setAttribute('href', url);
    aElem.setAttribute('download', fileName);
    document.body.appendChild(aElem); // Required for FF
    aElem.click();
    document.body.removeChild(aElem);
  }

  private extractFileNameFromKey(key: string): string {
    return key.split('/').splice(-1)[0];
  }
}
