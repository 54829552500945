import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TrackingService } from 'src/app/shared/services/tracking.service';
import { environment } from 'src/environments/environment';
import { CalendarDialogComponent } from '../calendar/calendar-dialog/calendar-dialog.component';

@Component({
  selector: 'gt-lessons-leearned',
  templateUrl: './lessons-leearned.component.html',
  styleUrls: ['./lessons-leearned.component.scss'],
})
export class LessonsLeearnedComponent implements OnInit {
  viewedItems: string[];
  loading: boolean = false;

  constructor(
    public dialog: MatDialog,
    private http: HttpClient,
    private trackingService: TrackingService,
  ) {}

  ngOnInit(): void {
    this.viewedItems = JSON.parse(localStorage.getItem('viewedLeasonsLearned2021')) ? JSON.parse(localStorage.getItem('viewedLeasonsLearned2021')) : [];
  }

  async openDialog(gridItem: {
    id: string;
    color: 'green' | 'blue' | 'orange' | 'pink' | 'purple' | 'yellow';
    hasVideo?: boolean;
    noAnim?: boolean;
  }): Promise<void> {
    if (this.loading === true) {
      return;
    } // to avoid opening 2 pop-ups if you double-click
    this.loading = true;
    this.viewedItems.push(gridItem.id);
    localStorage.setItem('viewedLeasonsLearned2021', JSON.stringify(this.viewedItems));
    const dialogRef = this.dialog.open(CalendarDialogComponent, {
      width: '100vw',
      height: '100vh',
      panelClass: 'calendarDialog',
      disableClose: true,
      data: {
        id: gridItem.id,
        color: gridItem.color,
        videoUrl: gridItem.hasVideo ? await this.getVideoUrl(gridItem.id) : null,
        type: 'lessonsLearned',
        noAnim: gridItem.noAnim,
      },
    });
    // this.trackingService.addTrackingItem('leasonsLearned', 'item:' + gridItem.id);
    this.loading = false;
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  hasBeenViewed(itemId: string): boolean {
    return this.viewedItems.findIndex(s => s === itemId) > -1;
  }

  async getVideoUrl(id: string): Promise<string> {
    const url = await this.http.get<string>(environment.backendUrl + 'calendar/lessonsLearned' + id).toPromise();
    return url;
  }
}
