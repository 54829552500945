import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingIndicatorService {
  private showLoadingIndicatorSource = new BehaviorSubject(false);
  currentShowLoadingIndicator = this.showLoadingIndicatorSource.asObservable();
  counter = 0;

  constructor() {}

  updateLoadingIndicatorStatus(value: boolean): void {
    if (value) {
      this.counter += 1;
      document.getElementsByTagName('html')[0].classList.add('loading');
      this.showLoadingIndicatorSource.next(value);
    } else {
      if (this.counter > 1) {
        this.counter -= 1;
      } else {
        this.counter -= 1;
        document.getElementsByTagName('html')[0].classList.remove('loading');
        this.showLoadingIndicatorSource.next(value);
      }
    }
  }
}
