<footer>
  <section class="sgt-footer">
    <div class="sgt-footer-global">
      <button (click)="handleClick(homepageLink, 'footer.homepage')">{{ "footer.homepage" | translate }}</button>
      <button (click)="handleClick('https://siemens.sharepoint.com/teams/GrowthTalksDeutschland?', 'Growth Talks DE')">Growth Talks DE</button>
      <button (click)="handleClick('https://siemens.sharepoint.com/teams/GrowthTalksinUKI?e=1%3aeed45cd158ef46638a47e4059d1fe1f6', 'Growth Talks UK')">
        Growth Talks UK
      </button>
      <button (click)="handleClick('mailto:mygrowth.people_organization@siemens.com', 'Contact')">
        <span class="icon icon-Email"></span>
        <span>Contact</span>
      </button>
    </div>
    <div class="sgt-footer-text" [innerHTML]="'footer.text' | translate"></div>
    <p class="sgt-footer-copyright">{{ "© Siemens AG, " + (currentYear - 25) + " – " + currentYear }}</p>
    <ul class="sgt-footer-links">
      <li *ngFor="let item of footerLinks">
        <button (click)="handleClick(item.link, 'footer.' + item.name)">{{ "footer." + item.name | translate }}</button>
      </li>
    </ul>
  </section>
</footer>
