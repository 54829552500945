import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { tap, takeUntil, take, map, switchMap } from 'rxjs/operators';
import { IUserData } from 'src/app/shared/interfaces/IUserData';
import { MailingService } from 'src/app/shared/services/mailing.service';
import { TrackingService } from 'src/app/shared/services/tracking.service';
import { UserState } from 'src/app/shared/state-management/user/user.state';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'gt-calendar-dialog',
  templateUrl: './calendar-dialog.component.html',
  styleUrls: ['./calendar-dialog.component.scss'],
})
export class CalendarDialogComponent implements OnInit {
  @Select(UserState.loggedUser) userData$: Observable<IUserData>;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  hasSecondLink: boolean = false;
  userGID: string;

  constructor(
    public dialogRef: MatDialogRef<CalendarDialogComponent>,
    private translateService: TranslateService,
    private mailingService: MailingService,
    private trackingService: TrackingService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {}

  ngOnInit(): void {
    this.getUserData();
  }

  private getUserData(): void {
    this.userData$
      .pipe(
        tap(userData => {
          this.userGID = userData.gid.toUpperCase();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getDate(): string {
    if (Number(this.data.id) < 10) {
      return '0' + this.data.id;
    } else {
      return this.data.id;
    }
  }

  hasLink(numberOfLink: number): boolean {
    return (
      this.translateService.instant(this.data.type + '.box' + this.data.id + '.link' + numberOfLink) !==
      this.data.type + '.box' + this.data.id + '.link' + numberOfLink
    );
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  trackClick(number: number) {
    if (this.data.id === '13' && this.data.type === 'calendar') {
      this.registerNow();
      // this.trackingService.addTrackingItem('button', ':Register now:learningSession');
    }
    this.trackingService.addTrackingItem(this.data.type, 'day:' + this.data.id + ':callToActionButton' + number);
  }

  public registerNow(): void {
    this.mailingService
      .sendEmail({ templateId: environment.juno.templateIds.registerStrengthscope })
      .pipe(
        take(1),
        tap(() => {
          this.snackBar.open("Great, we're happy you want to learn more about strengths. Please check your mailbox for the confirmation.", '', {
            duration: 5000,
          });
        }),
      )
      .subscribe();
  }
}
