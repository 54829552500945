<div *ngFor="let item of data | keyvalue; let i = index">
  <mat-expansion-panel *ngIf="isObject(item.value)" class="translations-expand-container mat-expansion-panel-admin">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <mat-checkbox
          [checked]="areAllChildrenSelected(path + '.' + item.key, item.value)"
          (change)="onSelectAll(path + '.' + item.key, item.value)"
        ></mat-checkbox>
        <span class="strong">{{ item.key }}</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <div class="content">
        <gt-admin-recursive-translation-changes
          [data]="item.value"
          [path]="path + '.' + item.key"
          (select)="passEvent($event)"
          [selected]="selected"
        ></gt-admin-recursive-translation-changes>
        <div class="add-container">
          <button class="btn btn-primary" (click)="onSelectAll(path + '.' + item.key, item.value)">
            <span *ngIf="!allSelected">{{ "admin.translations.selectAll" | translate }}</span>
            <span *ngIf="allSelected">{{ "admin.translations.diselectAll" | translate }}</span>
          </button>
        </div>
      </div>
    </ng-template>
  </mat-expansion-panel>

  <div *ngIf="!isObject(item.value)" class="space-between">
    <!-- <input type="text" [value]="key" (change)="onRename(path + '.' + key, $event)"/> -->
    <mat-checkbox [checked]="isSelected(path + '.' + item.key)" (change)="onSelect(path + '.' + item.key, $event)">
      <span>{{ item.key }}</span>
    </mat-checkbox>
    <mat-form-field class="form-field value-input">
      <input matInput type="text" [value]="item.value" disabled />
    </mat-form-field>
  </div>
</div>
