import { Injectable } from '@angular/core';
import { IPopupConfig } from '../interfaces/IPopupConfig';
import { popUpConfigList } from '../configs/popUpVideosList';
import { IUserConfig } from '../interfaces/IUserConfig';
import { VideoDialogComponent } from '../components/video-dialog/video-dialog.component';
import { POPUP_TYPE } from '../enums/popup_type.enum';
import { UserState } from '../state-management/user/user.state';
import { Select } from '@ngxs/store';
import { ActivatedRoute, Params } from '@angular/router';
import { PopupHandlerService } from './popup-handler.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ActivePopupsService {
  @Select(UserState.userConfig) userConfig$: Observable<IUserConfig>;
  private _allPopupTypes = Object.values(POPUP_TYPE);
  private _queryParams: Params = {};

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _popupService: PopupHandlerService,
  ) {
    this._activatedRoute.queryParams.subscribe(async queryParams => {
      this._queryParams = queryParams;
    });
  }

  public getActivePopupConfig(filter: POPUP_TYPE[] = this._allPopupTypes): IPopupConfig[] {
    const currentPopups = popUpConfigList.filter(s => this._popupIsActive(s) && filter.includes(s.type));
    return currentPopups;
  }

  public openActivePopups(popupTypes: POPUP_TYPE[]): void {
    this.userConfig$.subscribe((userConfig: IUserConfig) => {
      if (userConfig) {
        const popupConfig = this.getActivePopupConfig(popupTypes);
        for (const popup of popupConfig) {
          const hidePopup = this._popupIsMarkedHiddenByUser(popup, userConfig);
          const language = this._queryParams.lang ? this._queryParams.lang : 'en';
          if (popup?.language.includes(language) && !hidePopup) {
            this._openPopup(popup, language);
          }
        }
      }
    });
  }

  private _popupIsActive(popup: IPopupConfig): boolean {
    return popup.expirationDate > new Date() && popup.startDate <= new Date();
  }

  private _popupIsMarkedHiddenByUser(popup: IPopupConfig, userConfig: IUserConfig): boolean {
    return userConfig && userConfig.video && userConfig.video[popup.videoId] && userConfig.video[popup.videoId].status === 'hide';
  }

  private _openPopup(popupConfig: IPopupConfig, lang: string): void {
    this._popupService.openDialog({
      dialogContent: VideoDialogComponent,
      config: {
        data: {
          title: popupConfig.translationKey + '.title',
          desc: popupConfig.translationKey + '.desc',
          videoId: popupConfig.videoId,
          lang: lang,
          type: popupConfig.type,
        },
      },
    });
  }
}
