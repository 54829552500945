<section class="sgt-tilesList" [attr.data-layout]="layout" [attr.data-theme]="theme">
  <div class="h2">
    <span *ngIf="sectionContent.title1" [innerHTML]="sectionContent.title1 | translate"></span>
    <gt-animated-underline *ngIf="sectionContent.titleAnim" [color]="animationColor">
      <span class="strong">{{ sectionContent.titleAnim | translate }}</span>
    </gt-animated-underline>
  </div>
  <p class="desc">{{ sectionContent.desc | translate }}</p>
  <ul>
    <li *ngFor="let tile of sectionContent.tiles">
      <img [src]="tile.imgUrl" [alt]="tile.imgAltText" />
      <div class="h3" *ngIf="tile.title" [innerHTML]="tile.title | translate" [class.largeTitle]="isLarge(tile.title | translate)"></div>
      <p [innerHTML]="tile.desc | translate"></p>
      <gt-custom-link
        [trackingString]="tile.btnText"
        *ngIf="tile.btnText && translate.instant(tile.btnText)"
        [url]="translate.instant(tile.linkUrl) + '#' + fragment"
      >
        <div class="btn btn-hero">
          <span>{{ tile.btnText | translate }}</span>
        </div></gt-custom-link
      >
      <gt-custom-link
        *ngIf="tile.linkText && translate.instant(tile.linkText)"
        [trackingString]="tile.linkText"
        [url]="translate.instant(tile.linkTextUrl) + '#' + fragment"
      >
        <div class="textLink">
          <span>{{ tile.linkText | translate }}</span>
          <span class="rearIcon icon-Arrowhead-right"></span>
        </div>
      </gt-custom-link>
    </li>
  </ul>
</section>
