<section class="sgt-centered-card" [attr.data-scheme]="scheme">
  <div class="content">
    <div class="subtitle">{{ sectionData.subtitle | translate }}</div>
    <div class="title">
      {{ sectionData.title | translate
      }}<gt-animated-underline [color]="sectionData.color"
        ><span class="strong">{{ sectionData.titleAnim | translate }}</span>
      </gt-animated-underline>
    </div>
    <p [innerHTML]="sectionData.text1 | translate"></p>

    <figure>
      <gt-animated-illustration *ngIf="animationUrl" [illustrationURL]="animationUrl" [illustrationStyle]="illustrationStyle">
        <img [src]="sectionData.imgUrl" [alt]="sectionData.imgAltText" />
      </gt-animated-illustration>
      <img *ngIf="!animationUrl" [src]="sectionData.imgUrl" [alt]="sectionData.imgAltText" />
    </figure>

    <p [innerHTML]="sectionData.text2 | translate" [style.padding-bottom]="sectionData.actionButton ? '20px' : '0px'"></p>
    <gt-custom-link [trackingString]="sectionData.actionButton" [url]="sectionData.btnIntLink" *ngIf="sectionData.actionButton">
      <div class="btn btn-hero">
        <span>{{ sectionData.actionButton | translate }}</span>
      </div>
    </gt-custom-link>
    <ng-content></ng-content>
  </div>
</section>
