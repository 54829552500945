<section class="calendar-section">
  <div class="text-container">
    <div class="h1">{{ "calendar.header" | translate }}</div>
    <p>{{ "calendar.desc" | translate }}</p>
  </div>
  <div class="calendar">
    <div style="grid-column: 2/4; grid-row: 1/2" class="calendar__item calendar__item--1" (click)="openDialog({ id: '1', color: 'green', hasVideo: true })">
      <div *ngIf="hasBeenViewed('1')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%">
        <gt-animated-illustration-no-image
          [illustrationURL]="'/assets/images/calendar/animations/1.svg'"
          [illustrationStyle]="{ width: '75%', height: '100%', marginLeft: '10%' }"
        ></gt-animated-illustration-no-image>
      </div>
    </div>
    <div style="grid-column: 4/6; grid-row: 1/3" class="calendar__item calendar__item--11" (click)="openDialog({ id: '11', color: 'blue' })">
      <div *ngIf="hasBeenViewed('11')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%">
        <gt-animated-illustration-no-image
          [illustrationURL]="'/assets/images/calendar/animations/11.svg'"
          [illustrationStyle]="{ width: '100%', height: '100%', marginLeft: '-0' }"
        ></gt-animated-illustration-no-image>
      </div>
    </div>
    <div style="grid-column: 6/8; grid-row: 1/2" class="calendar__item calendar__item--28" (click)="openDialog({ id: '28', color: 'yellow' })">
      <div *ngIf="hasBeenViewed('28')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%">
        <gt-animated-illustration-no-image
          [illustrationURL]="'/assets/images/calendar/animations/28.svg'"
          [illustrationStyle]="{ width: '85%', height: '100%' }"
        ></gt-animated-illustration-no-image>
      </div>
    </div>
    <div style="grid-column: 8/10; grid-row: 1/2" class="calendar__item calendar__item--20" (click)="openDialog({ id: '20', color: 'pink' })">
      <div *ngIf="hasBeenViewed('20')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%; margin-top: 10%">
        <gt-animated-illustration-no-image
          [illustrationURL]="'/assets/images/calendar/animations/20.svg'"
          [illustrationStyle]="{ width: '90%', height: '100%', marginLeft: '10%' }"
        ></gt-animated-illustration-no-image>
      </div>
    </div>
    <div style="grid-column: 10/14; grid-row: 1/3" class="calendar__item calendar__item--24" (click)="openDialog({ id: '24', color: 'green' })">
      <div *ngIf="hasBeenViewed('24')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%">
        <gt-animated-illustration-no-image
          [illustrationURL]="'/assets/images/calendar/animations/24.svg'"
          [illustrationStyle]="{ width: '100%', height: '100%', marginLeft: '-10%' }"
        ></gt-animated-illustration-no-image>
      </div>
    </div>
    <div style="grid-column: 14/15; grid-row: 1/3" class="calendar__item calendar__item--18" (click)="openDialog({ id: '18', color: 'orange' })">
      <div *ngIf="hasBeenViewed('18')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%">
        <gt-animated-illustration-no-image
          [illustrationURL]="'/assets/images/calendar/animations/18.svg'"
          [illustrationStyle]="{ width: '150%', height: '100%', marginLeft: '-30%' }"
        ></gt-animated-illustration-no-image>
      </div>
    </div>
    <div
      style="grid-column: 15/17; grid-row: 1/3"
      class="calendar__item calendar__item--22"
      (click)="openDialog({ id: '22', color: 'purple', hasVideo: true })"
    >
      <div *ngIf="hasBeenViewed('22')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%">
        <gt-animated-illustration-no-image
          [illustrationURL]="'/assets/images/calendar/animations/22.svg'"
          [illustrationStyle]="{ width: '120%', height: '100%', marginLeft: '-10%' }"
        ></gt-animated-illustration-no-image>
      </div>
    </div>
    <div style="grid-column: 17/19; grid-row: 1/3" class="calendar__item calendar__item--6" (click)="openDialog({ id: '6', color: 'pink' })">
      <div *ngIf="hasBeenViewed('6')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%">
        <gt-animated-illustration-no-image
          [illustrationURL]="'/assets/images/calendar/animations/6.svg'"
          [illustrationStyle]="{ width: '150%', height: '100%', marginLeft: '-20%' }"
        ></gt-animated-illustration-no-image>
      </div>
    </div>

    <div style="grid-column: 2/4; grid-row: 2/4" class="calendar__item calendar__item--15" (click)="openDialog({ id: '15', color: 'orange', hasVideo: true })">
      <div *ngIf="hasBeenViewed('15')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%">
        <gt-animated-illustration-no-image
          [illustrationURL]="'/assets/images/calendar/animations/15.svg'"
          [illustrationStyle]="{ width: '120%', height: '100%', marginLeft: '-5%' }"
        ></gt-animated-illustration-no-image>
      </div>
    </div>
    <div style="grid-column: 4/6; grid-row: 3/4" class="calendar__item calendar__item--23" (click)="openDialog({ id: '23', color: 'yellow' })">
      <div *ngIf="hasBeenViewed('23')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%">
        <gt-animated-illustration-no-image
          [illustrationURL]="'/assets/images/calendar/animations/23.svg'"
          [illustrationStyle]="{ width: '100%', height: '100%', marginLeft: '6%' }"
        ></gt-animated-illustration-no-image>
      </div>
    </div>
    <div style="grid-column: 6/7; grid-row: 2/4" class="calendar__item calendar__item--2" (click)="openDialog({ id: '2', color: 'purple' })">
      <div *ngIf="hasBeenViewed('2')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%">
        <gt-animated-illustration-no-image
          [illustrationURL]="'/assets/images/calendar/animations/2.svg'"
          [illustrationStyle]="{ width: '120%', height: '100%', marginLeft: '-5%' }"
        ></gt-animated-illustration-no-image>
      </div>
    </div>
    <div style="grid-column: 7/8; grid-row: 2/4" class="calendar__item calendar__item--9" (click)="openDialog({ id: '9', color: 'orange', hasVideo: true })">
      <div *ngIf="hasBeenViewed('9')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%">
        <gt-animated-illustration-no-image
          [illustrationURL]="'/assets/images/calendar/animations/9.svg'"
          [illustrationStyle]="{ width: '120%', height: '100%', marginLeft: '-10%' }"
        ></gt-animated-illustration-no-image>
      </div>
    </div>
    <div style="grid-column: 8/10; grid-row: 2/3" class="calendar__item calendar__item--13" (click)="openDialog({ id: '13', color: 'blue' })">
      <div *ngIf="hasBeenViewed('13')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%">
        <gt-animated-illustration-no-image
          [illustrationURL]="'/assets/images/calendar/animations/13.svg'"
          [illustrationStyle]="{ width: '84%', height: '100%', marginLeft: '20%' }"
        ></gt-animated-illustration-no-image>
      </div>
    </div>

    <div style="grid-column: 8/10; grid-row: 3/5" class="calendar__item calendar__item--19" (click)="openDialog({ id: '19', color: 'yellow' })">
      <div *ngIf="hasBeenViewed('19')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%">
        <gt-animated-illustration-no-image
          [illustrationURL]="'/assets/images/calendar/animations/19.svg'"
          [illustrationStyle]="{ width: '150%', height: '100%', marginLeft: '-25%' }"
        ></gt-animated-illustration-no-image>
      </div>
    </div>
    <div style="grid-column: 10/14; grid-row: 3/4" class="calendar__item calendar__item--21" (click)="openDialog({ id: '21', color: 'purple' })">
      <div *ngIf="hasBeenViewed('21')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%">
        <gt-animated-illustration-no-image
          [illustrationURL]="'/assets/images/calendar/animations/21.svg'"
          [illustrationStyle]="{ width: '50%', height: '100%', marginLeft: '30%' }"
        ></gt-animated-illustration-no-image>
      </div>
    </div>
    <div style="grid-column: 14/18; grid-row: 3/5" class="calendar__item calendar__item--16" (click)="openDialog({ id: '16', color: 'blue' })">
      <div *ngIf="hasBeenViewed('16')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%">
        <gt-animated-illustration-no-image
          [illustrationURL]="'/assets/images/calendar/animations/16.svg'"
          [illustrationStyle]="{ width: '90%', height: '100%', marginLeft: '-5%' }"
        ></gt-animated-illustration-no-image>
      </div>
    </div>
    <div style="grid-column: 18/19; grid-row: 3/5" class="calendar__item calendar__item--7" (click)="openDialog({ id: '7', color: 'orange' })">
      <div *ngIf="hasBeenViewed('7')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%">
        <gt-animated-illustration-no-image
          [illustrationURL]="'/assets/images/calendar/animations/7.svg'"
          [illustrationStyle]="{ width: '150%', height: '100%', marginLeft: '-25%' }"
        ></gt-animated-illustration-no-image>
      </div>
    </div>

    <div style="grid-column: 2/6; grid-row: 4/5" class="calendar__item calendar__item--5" (click)="openDialog({ id: '5', color: 'blue' })">
      <div *ngIf="hasBeenViewed('5')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%">
        <gt-animated-illustration-no-image
          [illustrationURL]="'/assets/images/calendar/animations/5.svg'"
          [illustrationStyle]="{ width: '50%', height: '100%', marginLeft: '25%' }"
        ></gt-animated-illustration-no-image>
      </div>
    </div>
    <div style="grid-column: 6/8; grid-row: 4/6" class="calendar__item calendar__item--8" (click)="openDialog({ id: '8', color: 'green' })">
      <div *ngIf="hasBeenViewed('8')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%">
        <gt-animated-illustration-no-image
          [illustrationURL]="'/assets/images/calendar/animations/8.svg'"
          [illustrationStyle]="{ width: '100%', height: '100%' }"
        ></gt-animated-illustration-no-image>
      </div>
    </div>
    <div style="grid-column: 10/12; grid-row: 4/5" class="calendar__item calendar__item--29" (click)="openDialog({ id: '29', color: 'pink' })">
      <div *ngIf="hasBeenViewed('29')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%">
        <gt-animated-illustration-no-image
          [illustrationURL]="'/assets/images/calendar/animations/29.svg'"
          [illustrationStyle]="{ width: '100%', height: '100%' }"
        ></gt-animated-illustration-no-image>
      </div>
    </div>
    <div style="grid-column: 12/14; grid-row: 4/5" class="calendar__item calendar__item--27" (click)="openDialog({ id: '27', color: 'yellow' })">
      <div *ngIf="hasBeenViewed('27')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%">
        <gt-animated-illustration-no-image
          [illustrationURL]="'/assets/images/calendar/animations/27.svg'"
          [illustrationStyle]="{ width: '85%', height: '100%' }"
        ></gt-animated-illustration-no-image>
      </div>
    </div>

    <div style="grid-column: 2/4; grid-row: 5/7" class="calendar__item calendar__item--30" (click)="openDialog({ id: '30', color: 'pink', hasVideo: true })">
      <div *ngIf="hasBeenViewed('30')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%">
        <gt-animated-illustration-no-image
          [illustrationURL]="'/assets/images/calendar/animations/30.svg'"
          [illustrationStyle]="{ width: '160%', height: '100%', marginLeft: '-25%' }"
        ></gt-animated-illustration-no-image>
      </div>
    </div>
    <div style="grid-column: 4/6; grid-row: 5/6" class="calendar__item calendar__item--14" (click)="openDialog({ id: '14', color: 'purple', hasVideo: true })">
      <div *ngIf="hasBeenViewed('14')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%">
        <gt-animated-illustration-no-image
          [illustrationURL]="'/assets/images/calendar/animations/14.svg'"
          [illustrationStyle]="{ width: '90%', height: '100%' }"
        ></gt-animated-illustration-no-image>
      </div>
    </div>
    <div style="grid-column: 8/12; grid-row: 5/7" class="calendar__item calendar__item--12" (click)="openDialog({ id: '12', color: 'green' })">
      <div *ngIf="hasBeenViewed('12')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%">
        <gt-animated-illustration-no-image
          [illustrationURL]="'/assets/images/calendar/animations/12.svg'"
          [illustrationStyle]="{ width: '100%', height: '100%' }"
        ></gt-animated-illustration-no-image>
      </div>
    </div>
    <div style="grid-column: 12/14; grid-row: 5/7" class="calendar__item calendar__item--31" (click)="openDialog({ id: '31', color: 'pink' })">
      <div *ngIf="hasBeenViewed('31')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%">
        <gt-animated-illustration-no-image
          [illustrationURL]="'/assets/images/calendar/animations/31.svg'"
          [illustrationStyle]="{ width: '130%', height: '100%', marginLeft: '-20%' }"
        ></gt-animated-illustration-no-image>
      </div>
    </div>
    <div style="grid-column: 15/19; grid-row: 5/6" class="calendar__item calendar__item--4" (click)="openDialog({ id: '4', color: 'yellow' })">
      <div *ngIf="hasBeenViewed('4')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%">
        <gt-animated-illustration-no-image
          [illustrationURL]="'/assets/images/calendar/animations/4.svg'"
          [illustrationStyle]="{ width: '50%', height: '100%', marginLeft: '25%' }"
        ></gt-animated-illustration-no-image>
      </div>
    </div>

    <div style="grid-column: 4/8; grid-row: 6/7" class="calendar__item calendar__item--17" (click)="openDialog({ id: '17', color: 'yellow', hasVideo: true })">
      <div *ngIf="hasBeenViewed('17')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%">
        <gt-animated-illustration-no-image
          [illustrationURL]="'/assets/images/calendar/animations/17.svg'"
          [illustrationStyle]="{ width: '40%', height: '100%', marginLeft: '20%' }"
        ></gt-animated-illustration-no-image>
      </div>
    </div>
    <div style="grid-column: 14/15; grid-row: 5/7" class="calendar__item calendar__item--25" (click)="openDialog({ id: '25', color: 'purple' })">
      <div *ngIf="hasBeenViewed('25')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%">
        <gt-animated-illustration-no-image
          [illustrationURL]="'/assets/images/calendar/animations/25.svg'"
          [illustrationStyle]="{ width: '100%', height: '100%' }"
        ></gt-animated-illustration-no-image>
      </div>
    </div>
    <div style="grid-column: 15/16; grid-row: 6/7" class="calendar__item calendar__item--10" (click)="openDialog({ id: '10', color: 'green', hasVideo: true })">
      <div *ngIf="hasBeenViewed('10')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%; margin-top: 15px">
        <gt-animated-illustration-no-image
          [illustrationURL]="'/assets/images/calendar/animations/10.svg'"
          [illustrationStyle]="{ width: '100%', height: '100%' }"
        ></gt-animated-illustration-no-image>
      </div>
    </div>
    <div style="grid-column: 16/18; grid-row: 6/7" class="calendar__item calendar__item--26" (click)="openDialog({ id: '26', color: 'pink' })">
      <div *ngIf="hasBeenViewed('26')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%">
        <gt-animated-illustration-no-image
          [illustrationURL]="'/assets/images/calendar/animations/26.svg'"
          [illustrationStyle]="{ width: '120%', height: '100%', marginLeft: '-20%' }"
        ></gt-animated-illustration-no-image>
      </div>
    </div>
    <div style="grid-column: 18/19; grid-row: 6/7" class="calendar__item calendar__item--3" (click)="openDialog({ id: '3', color: 'blue' })">
      <div *ngIf="hasBeenViewed('3')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%">
        <gt-animated-illustration-no-image
          [illustrationURL]="'/assets/images/calendar/animations/3.svg'"
          [illustrationStyle]="{ width: '100%', height: '100%' }"
        ></gt-animated-illustration-no-image>
      </div>
    </div>
  </div>
</section>
