import { Component, Input, input } from '@angular/core';

@Component({
  selector: 'gt-wizard-completion-badge',
  templateUrl: './wizard-completion-badge.component.html',
  styleUrl: './wizard-completion-badge.component.scss',
})
export class WizardCompletionBadgeComponent {
  @Input() progress: number;
  @Input() size: 'big' | 'small';
}
