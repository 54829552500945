import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gt-round-list-card',
  templateUrl: './round-list-card.component.html',
  styleUrls: ['./round-list-card.component.scss'],
})
export class RoundListCardComponent {
  @Input() color: string;
  @Input() listType: 'bullet' | 'check' = 'check';
  @Input() scheme: 'light' | 'sand' = 'light';
  @Input() sectionData: {
    title?: string;
    titleAnim?: string;
    cards: {
      imgUrl: string;
      imgAltText?: string;
      title?: string;
      points: string[];
      linkText?: string;
      linkUrl?: string;
    }[];
  };

  constructor(public translateService: TranslateService) {}
}
