import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gt-hint',
  templateUrl: './hint.component.html',
  styleUrls: ['./hint.component.scss'],
})
export class HintComponent implements OnInit {
  @Input() imgUrl: string;
  @Input() color: 'orange' | 'blue' | 'green';
  @Input() desc: string;

  constructor() {}

  ngOnInit(): void {}
}
