<gt-header *ngIf="showMGheader"></gt-header>
<gt-bb-header *ngIf="showBBheader"></gt-bb-header>
<gt-leaders-header *ngIf="showLeadersHeader"></gt-leaders-header>
<gt-application-header
  *ngIf="showSelfReflectionHeader"
  [headerTitle]="'srj.headerTitle'"
  [headerTitleRoute]="'srj.headerTitleRoute'"
  [availableLanguages]="selfReflectionJourneyLanguages"
  [closeApplicationBtnLabel]="'shared.saveAndClose'"
  (applicationClosed)="selfReflectionJourneyClosed()"
></gt-application-header>
<gt-application-header
  *ngIf="showEthicalSelfReflectionHeader"
  [headerTitle]="'esrj.headerTitle'"
  [headerTitleRoute]="'esrj.headerTitleRoute'"
  [availableLanguages]="ethicalSelfReflectionJourneyLanguages"
  [closeApplicationBtnLabel]="'shared.close'"
  (applicationClosed)="ethicalSelfReflectionJourneyClosed()"
></gt-application-header>
<div class="content-container" [class.disableScroll]="showLoadingIndicator">
  <div class="loading-indicator-container" *ngIf="showLoadingIndicator">
    <gt-loading-indicator></gt-loading-indicator>
  </div>
  <div (click)="closeSearch()">
    <router-outlet></router-outlet>
    <div class="survey-btn-container" *ngIf="showSurveyBtn">
      <button class="btn btn-primary survey-btn" (click)="showSurvey(true)" [matTooltip]="'surveys.btnTooltip' | translate"><span></span></button>
    </div>
  </div>
</div>
<gt-footer *ngIf="showFooter"></gt-footer>
