import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
@Injectable()
export class FilterPipe<T> implements PipeTransform {
  transform(items: any[], field: string, value: string[]): any[] {
    if (!items) return [];
    if (!value || value.length == 0) return items;
    return items.filter(it => it[field] && value.indexOf(it[field]) != -1);
  }
}
