import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime } from 'rxjs/operators';
import { NewsRss } from 'src/app/shared/interfaces/news-rss';
import { LanguageService } from 'src/app/shared/services/language.service';
import { MyGrowthTeaserService } from 'src/app/shared/services/my-growth-teaser.service';

const CLASSNAME = 'MygrowthHomeTeasersComponent';

@Component({
  selector: 'gt-myGrowth-home-teasers',
  templateUrl: './myGrowth-home-teasers.component.html',
  styleUrls: ['./myGrowth-home-teasers.component.scss'],
})
export class MygrowthHomeTeasersComponent implements OnInit {
  rssData: NewsRss = {};

  constructor(
    private myGrowthTeaserService: MyGrowthTeaserService,
    private translateService: TranslateService,
    private languageService: LanguageService,
  ) {}

  ngOnInit(): void {
    this.initTeasers('');
    this.translateService.onLangChange.pipe(debounceTime(100)).subscribe(langChange => {
      this.languageService.getLanguages().then(languages => {
        const currentLang = languages.filter(s => s.code === langChange.lang)[0];
        this.initTeasers(currentLang ? currentLang.rssCode : '');
      });
    });
  }

  private initTeasers(rssLang: string) {
    const teasersCount = 3;

    this.myGrowthTeaserService.getTeasers(rssLang).subscribe(res => {
      const parser = require('fast-xml-parser');
      const he = require('he');

      const options = {
        attributeNamePrefix: '@_',
        attrNodeName: 'attr',
        textNodeName: '#text',
        ignoreAttributes: false,
        ignoreNameSpace: true,
        allowBooleanAttributes: false,
        parseNodeValue: true,
        parseAttributeValue: false,
        trimValues: true,
        cdataTagName: '__cdata',
        cdataPositionChar: '\\c',
        parseTrueNumberOnly: false,
        numParseOptions: {
          hex: true,
          leadingZeros: true,
        },
        arrayMode: false,
        attrValueProcessor: (val, attrName) => he.decode(val, { isAttributeValue: true }),
        tagValueProcessor: (val, tagName) => he.decode(val),
        stopNodes: ['parse-me-as-string'],
      };

      try {
        let rssData: NewsRss = parser.parse(res, options, true);
        let indexStart = this.getRandomIntInclusive(0, rssData.rss.channel.item.length - teasersCount);
        rssData.rss.channel.item = rssData.rss.channel.item.slice(indexStart, indexStart + teasersCount);
        rssData.rss.channel.item.forEach(it => {
          let enclosureReadable = JSON.parse(JSON.stringify(it.enclosure).replace('@', ''));
          it.imageUrl = enclosureReadable.attr?._url ? enclosureReadable.attr?._url : '';
        });
        this.rssData = rssData;
        this.rssData.rss.channel.item.forEach(item => {
          if (item.title.length > 50) {
            item.titleDisplay = item.title.slice(0, 50);
            item.titleDisplay = item.titleDisplay.concat('...');
          }
          if (item.description.__cdata.length > 240) {
            item.description.__cdata = item.description.__cdata.slice(0, 240);
            item.description.__cdata = item.description.__cdata.concat('...');
          }
        });
      } catch (error) {
        console.log(CLASSNAME, error.message);
      }
    });
  }

  private getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
