<div class="dialog-container" [class.mobile]="isMobile">
  <button class="close-btn" (click)="close()"><span class="icon-Close"></span></button>
  <div class="leftSide">
    <div #calendarContainer class="calendar"></div>
    <div class="content">
      <p [innerHTML]="'calendar22.day' + data.step + '.text' | translate"></p>
      <button
        class="btn btn-hero"
        *ngIf="
          translate.instant('calendar22.day' + data.step + '.cta') !== 'calendar22.day' + data.step + '.cta' &&
          translate.instant('calendar22.day' + data.step + '.cta')
        "
        [disabled]="!translate.instant('calendar22.day' + data.step + '.ctaLink')"
        (click)="btnClicked(translate.instant('calendar22.day' + data.step + '.ctaLink'))"
      >
        {{ "calendar22.day" + data.step + ".cta" | translate }}
      </button>
      <button
        class="btn btn-hero"
        *ngIf="
          translate.instant('calendar22.day' + data.step + '.cta2') !== 'calendar22.day' + data.step + '.cta2' &&
          translate.instant('calendar22.day' + data.step + '.cta2')
        "
        [disabled]="!translate.instant('calendar22.day' + data.step + '.cta2Link')"
        (click)="btnClicked(translate.instant('calendar22.day' + data.step + '.cta2Link'))"
      >
        {{ "calendar22.day" + data.step + ".cta2" | translate }}
      </button>
      <!-- <button class="calendar-btn" (click)="btnClicked()">
          <div #btnBg class="btn-Bg"></div>
          {{'calendar22.day' + data.step + '.cta' | translate}}</button> -->
      <div *ngIf="dayHasVideo()" class="video-container">
        <gt-videoplayer [videolink]="videoUrl" [autoplay]="true"></gt-videoplayer>
        <div class="loading-indicator" *ngIf="!videoUrl">
          <mat-spinner [diameter]="50" [strokeWidth]="5"></mat-spinner>
        </div>
      </div>
    </div>
  </div>
  <div #animContainer class="dialogAnim"></div>
</div>
