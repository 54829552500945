import { SRJ_DIMENSIONS } from 'src/app/shared/enums/srj_dimensions.enum';
import { SRJ_TOPIC } from 'src/app/shared/enums/srj_topic.enum';

export const srjTopics: { topicTitle: SRJ_TOPIC; dimension?: SRJ_DIMENSIONS }[] = [
  {
    topicTitle: SRJ_TOPIC.who_i_am,
    dimension: SRJ_DIMENSIONS.exploring,
  },
  {
    topicTitle: SRJ_TOPIC.what_motivates_me,
    dimension: SRJ_DIMENSIONS.exploring,
  },
  {
    topicTitle: SRJ_TOPIC.what_is_important,
    dimension: SRJ_DIMENSIONS.exploring,
  },
  {
    topicTitle: SRJ_TOPIC.growth_potential,
    dimension: SRJ_DIMENSIONS.exploring,
  },
  {
    topicTitle: SRJ_TOPIC.mental_health,
    dimension: SRJ_DIMENSIONS.balancing,
  },
  {
    topicTitle: SRJ_TOPIC.good_balance,
    dimension: SRJ_DIMENSIONS.balancing,
  },
  {
    topicTitle: SRJ_TOPIC.difficult_situations,
    dimension: SRJ_DIMENSIONS.balancing,
  },
  {
    topicTitle: SRJ_TOPIC.be_aware,
    dimension: SRJ_DIMENSIONS.balancing,
  },
  {
    topicTitle: SRJ_TOPIC.be_open,
    dimension: SRJ_DIMENSIONS.growing,
  },
  {
    topicTitle: SRJ_TOPIC.drive_change,
    dimension: SRJ_DIMENSIONS.growing,
  },
  {
    topicTitle: SRJ_TOPIC.constantly_learn,
    dimension: SRJ_DIMENSIONS.growing,
  },
  {
    topicTitle: SRJ_TOPIC.adapt,
    dimension: SRJ_DIMENSIONS.growing,
  },
  {
    topicTitle: SRJ_TOPIC.my_skills,
    dimension: SRJ_DIMENSIONS.performing,
  },
  {
    topicTitle: SRJ_TOPIC.my_capabilities,
    dimension: SRJ_DIMENSIONS.performing,
  },
  {
    topicTitle: SRJ_TOPIC.work_well,
    dimension: SRJ_DIMENSIONS.performing,
  },
  {
    topicTitle: SRJ_TOPIC.good_contact,
    dimension: SRJ_DIMENSIONS.performing,
  },
  {
    topicTitle: SRJ_TOPIC.next_steps,
    dimension: SRJ_DIMENSIONS.striving,
  },
  {
    topicTitle: SRJ_TOPIC.make_decisions,
    dimension: SRJ_DIMENSIONS.striving,
  },
  {
    topicTitle: SRJ_TOPIC.determine_direction,
    dimension: SRJ_DIMENSIONS.striving,
  },
  {
    topicTitle: SRJ_TOPIC.achieve_goals,
    dimension: SRJ_DIMENSIONS.striving,
  },
  {
    topicTitle: SRJ_TOPIC.none,
  },
];
