import { ICategory } from '../shared/interfaces/IBrandbuilderData';

export const BRAND_BUILDER_CONFIG: ICategory[] = [
  {
    id: 'introduction',
    time: '5 min',

    steps: [
      { id: 'introduction._1' },
      // { id: 'introduction._2' },
      // { id: 'introduction._3' }
    ],
    illustration: './assets/images/illustrations/bb_introduction.svg',
  },
  {
    id: 'brand',
    time: '40 min',

    steps: [{ id: 'brand', overview: true }],
    illustration: './assets/images/illustrations/bb_brand.svg',
  },
  {
    id: 'drivers',
    time: '10 min',
    // icon: 'baloon',
    parent: 'brand',

    steps: [
      {
        id: 'drivers.selection',
        selection: {
          count: 5,
          options: [
            {
              id: 'belonging',
              adjectives: [
                'a non-judgemental environment',
                'the acceptance of others',
                'the understanding of others',
                'the ability to be myself',
                'diversity and inclusion',
                'a supportive environment',
              ],
            },
            { id: 'flexibility', adjectives: ['working independently', 'making my own decisions', 'realising my own ideas', 'keeping myself accountable'] },
            {
              id: 'goal',
              adjectives: [
                'balancing success with failure',
                'creating personal goals',
                'long-terms goals',
                'short-term goals',
                'the satisfaction of achieving a goal',
                'achieving business goals',
              ],
            },
            { id: 'status', adjectives: ['recognition from my peers', 'the appreciation for my work', 'gaining respect from others', 'making myself heard'] },
            {
              id: 'honor',
              adjectives: ['delivering work i can be proud of', 'the trust others put in me', 'my personal pride', 'earning the respect of others'],
            },
            {
              id: 'power',
              adjectives: ['having influence', 'making my voice heard', 'the need to be a leader', 'a strong self-esteem', 'being a front runner'],
            },
            {
              id: 'structuredness',
              adjectives: [
                'having the right processes',
                'a well-managed environment',
                'being disciplined',
                'getting things done',
                'a clear and structured work environment',
              ],
            },
            {
              id: 'curiosity',
              adjectives: [
                'discovering new things',
                'my interest to learn new things',
                'new ideas and thoughts',
                'the excitement for something new',
                'asking questions',
                'my growth mindset',
              ],
            },
            { id: 'challenge', adjectives: ['being challenged in my work', 'a competitive workplace', 'expanding my abilities', 'my desire to progress'] },
            {
              id: 'impact',
              adjectives: [
                'the need to raise the bar',
                'doing the best I can',
                'contributing to business success',
                'making a difference',
                'putting the customer at the center',
                'being a partner of choice for our customers',
                'growing company value',
              ],
            },
            {
              id: 'purpose',
              adjectives: [
                'doing the right thing',
                'my personal values',
                'being a role model',
                'transforming the everyday',
                'shaping a sustainable future',
                'creating meaningful products and services',
                'adding value to society',
              ],
            },
            {
              id: 'culture',
              adjectives: [
                'a social culture at work',
                'my ability to speak freely',
                'my desire to help others',
                'feedback from colleagues',
                'having fun at work',
                'a friendly atmosphere at work',
              ],
            },
            {
              id: 'innovativeness',
              adjectives: [
                'my open-mindedness',
                'creating new things',
                'developing innovative products and services',
                'doing things differently',
                'pushing boundaries',
              ],
            },
          ],
        },
      },
      { id: 'drivers.comment', comment: true },
    ],
    illustration: './assets/images/illustrations/bb_drivers.svg',
  },
  {
    id: 'strengths',
    time: '10 min',
    icon: 'pencil',
    parent: 'brand',

    steps: [
      {
        id: 'strengths.selection',
        selection: {
          count: 5,
          options: [
            { id: 'courage', adjectives: ['courageous', 'challenging', 'fearless', 'resolute', 'strong-minded'] },
            { id: 'emotional-control', adjectives: ['calm', 'poised', 'stabilising', 'controlled', 'balanced'] },
            { id: 'enthusiasm', adjectives: ['enthusiastic', 'passionate', 'keen', 'energetic', 'supportive'] },
            { id: 'optimism', adjectives: ['optimistic', 'positive', 'hopeful', 'constructive', 'affirming'] },
            { id: 'resilience', adjectives: ['resilient', 'persistent', 'tenacious', 'quick to recover', 'tough'] },
            { id: 'self-confidence', adjectives: ['self-assured', 'assertive', 'confident', 'self-belief', 'assured'] },
            { id: 'decisiveness', adjectives: ['conclusive', 'clear', 'decisive', 'quick-thinking', 'determined'] },
            { id: 'efficiency', adjectives: ['efficient', 'planful', 'productive', 'organized', 'structured'] },
            { id: 'flexibility', adjectives: ['flexible', 'agile', 'accommodating', 'adaptive', 'open-minded'] },
            { id: 'initiative', adjectives: ['proactive', 'self-starting', 'independent', 'action-focused', 'dynamic'] },
            { id: 'results-focus', adjectives: ['results-driven', 'achieving', 'accomplishing', 'driven', 'focused'] },
            { id: 'self-improvement', adjectives: ['self-developing', 'curious', 'learning', 'open-minded', 'knowledge-seeking'] },
            { id: 'collaboration', adjectives: ['collaborative', 'team-oriented', 'team-player', 'cooperative', 'seeking harmony'] },
            { id: 'compassion', adjectives: ['compassionate', 'kind', 'sensitive', 'caring', 'sympathetic'] },
            // developing-others
            // empathy
            { id: 'persuasiveness', adjectives: ['influential', 'convincing', 'compelling', 'credible', 'eloquent'] },
            { id: 'relationship-building', adjectives: ['connecting', 'synergistic', 'networker', 'social', 'associative'] },
            // leading
            { id: 'critical-thinking', adjectives: ['questioning', 'analysing', 'objective', 'problem-solving', 'critiquing'] },
            { id: 'common-sense', adjectives: ['no nonesense', 'pragmatic', 'practical', 'logical', 'evidence-based'] },
            { id: 'creativity', adjectives: ['creative', 'imaginative', 'innovative', 'inventive', 'progressive'] },
            { id: 'detail-orientation', adjectives: ['thorough', 'meticulous', 'precise', 'accurate', 'detail-oriented'] },
            { id: 'strategic-mindedness', adjectives: ['strategic', 'forward-looking', 'visionary', 'future-oriented', 'big picture-focused'] },
            { id: 'leading', adjectives: ['motivating', 'co-ordinating', 'energising', 'directing', 'galvanising'] },
            { id: 'empathy', adjectives: ['empathetic', 'understanding', 'insightfiul', 'appreciative', 'interested'] },
            { id: 'developing-others', adjectives: ['supportive', 'developing', 'helping', 'mentoring', 'facilitating'] },
          ],
        },
      },
      { id: 'strengths.comment', comment: true },
    ],
    illustration: './assets/images/illustrations/bb_strengths.svg',
  },
  {
    id: 'expertise',
    time: '10 min',
    icon: 'energy-yellow',
    parent: 'brand',

    steps: [
      {
        id: 'expertise',
        inputs: {
          title: 'brandbuilder.categories.expertise.steps.expertise.inputTitle',
          inputs: ['field1', 'field2'],
          prompts: ['prompt1', 'prompt2'],
        },
      },
    ],
    illustration: './assets/images/illustrations/bb_expertise.svg',
  },
  {
    id: 'statement',
    time: '10 min',
    icon: 'fingertip',
    parent: 'brand',

    steps: [
      { id: 'statement.description' },
      {
        id: 'statement.editor',
        statementBuilder: {
          maxCounts: {
            strengths: 3,
            drivers: 2,
          },
          templates: [
            {
              id: 0,
            },
            {
              id: 1,
            },
            {
              id: 2,
            },
          ],
        },
      },
    ],
    illustration: './assets/images/illustrations/bb_statement.svg',
  },
  {
    id: 'do-more',
    icon: 'bulb',
    time: '5 min',

    steps: [{ id: 'do-more' }],
    illustration: './assets/images/illustrations/bb_do-more.svg',
  },
];
