export enum SRJ_TOPIC {
  who_i_am = 'who_i_am',
  what_motivates_me = 'what_motivates_me',
  what_is_important = 'what_is_important',
  growth_potential = 'growth_potential',
  mental_health = 'mental_health',
  good_balance = 'good_balance',
  difficult_situations = 'difficult_situations',
  be_aware = 'be_aware',
  be_open = 'be_open',
  drive_change = 'drive_change',
  constantly_learn = 'constantly_learn',
  adapt = 'adapt',
  my_skills = 'my_skills',
  my_capabilities = 'my_capabilities',
  work_well = 'work_well',
  good_contact = 'good_contact',
  next_steps = 'next_steps',
  make_decisions = 'make_decisions',
  determine_direction = 'determine_direction',
  achieve_goals = 'achieve_goals',
  none = 'none',
}
