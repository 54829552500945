<div class="close-btn" [ngClass]="'close-btn--' + data.color" (click)="closeDialog()"></div>
<div class="bg" [ngClass]="'bg--' + data.color">
  <div *ngIf="true" class="content-container calendar">
    <div class="content" [class.noAnimation]="data.noAnim && !data.videoUrl">
      <div class="day" *ngIf="data.type === 'calendar'">{{ "calendar.box" + data.id + ".day" | translate }}</div>
      <gt-animated-underline [illustrationURL]="'/assets/images/calendar/animations/underline-' + data.color + '.svg'">
        <div class="number">{{ getDate() }}</div></gt-animated-underline
      >
      <div>
        <div class="title strong" *ngIf="data.type === 'lessonsLearned'">{{ "lessonsLearned.box" + data.id + ".title" | translate }}</div>
        <div class="desc" [class.small]="data.videoUrl" [innerHTML]="data.type + '.box' + data.id + '.description' | translate"></div>
        <div
          class="link"
          *ngIf="hasLink(1)"
          [ngClass]="data.color"
          (click)="trackClick(1)"
          [innerHTML]="data.type + '.box' + data.id + '.link1' | translate"
        ></div>
        <div class="connector" *ngIf="hasLink(2) && data.type === 'calendar'" [ngClass]="data.color">
          {{ data.type + ".box" + data.id + ".connector" | translate }}
        </div>
        <div
          class="link"
          [ngClass]="data.color"
          *ngIf="hasLink(2)"
          (click)="trackClick(2)"
          [innerHTML]="data.type + '.box' + data.id + '.link2' | translate"
        ></div>
        <div
          class="link"
          [ngClass]="data.color"
          *ngIf="hasLink(3)"
          (click)="trackClick(3)"
          [innerHTML]="data.type + '.box' + data.id + '.link3' | translate"
        ></div>
      </div>
    </div>
    <div *ngIf="data.videoUrl" class="video-container">
      <gt-videoplayer [videolink]="data.videoUrl" [autoplay]="true"></gt-videoplayer>
    </div>
    <div class="animation" *ngIf="!data.noAnim">
      <gt-animated-illustration-no-image
        [illustrationURL]="'/assets/images/' + data.type + '/animations/' + data.id + '.svg'"
        [illustrationStyle]="{ width: '150%', height: '100%' }"
      ></gt-animated-illustration-no-image>
    </div>
  </div>
  <!-- <div *ngIf="data.type === 'lessonsLearned'" class="content-container lessonsLearned">
    <div class="content">
      <gt-animated-underline [illustrationURL]="'/assets/images/calendar/animations/underline-' + data.color + '.svg'"> <div class="number">{{getDate()}}</div></gt-animated-underline>
      <div>
      <div class="title strong" >{{'lessonsLearned.box' +data.id + '.title' | translate}}</div>
      <div class="text" [ngClass]="data.color" [innerHTML]="data.type + '.box' +data.id + '.description' | translate"></div>
    </div>
  </div>
</div> -->
</div>
