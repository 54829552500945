import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, fromEvent } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SEARCH_CATEGORIES } from 'src/app/shared/enums/search_categories.enum';
import { TRACKING_ITEM } from 'src/app/shared/enums/tracking_item.enum';
import { ISearchOption } from 'src/app/shared/interfaces/ISearchOption';
import { ISearchSuggestion } from 'src/app/shared/interfaces/ISearchSuggestion';
import { SearchService } from 'src/app/shared/services/search.service';
import { TrackingService } from 'src/app/shared/services/tracking.service';

@Component({
  selector: 'gt-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  @ViewChild('searchInput', { static: false }) set input(searchInput: ElementRef) {
    if (searchInput) {
      fromEvent(searchInput.nativeElement, 'input')
        .pipe(map((event: Event) => (event.target as HTMLInputElement).value))
        .pipe(debounceTime(1000))
        .pipe(distinctUntilChanged())
        .subscribe(data => this.searchTermChanged());
    }
  }
  private subs: Subscription[] = [];
  protected searchCategories: SEARCH_CATEGORIES[] = Object.values(SEARCH_CATEGORIES);
  protected selectedCategory: SEARCH_CATEGORIES = SEARCH_CATEGORIES.all;
  protected SEARCH_CATEGORIES = SEARCH_CATEGORIES;
  protected searchTerm: string;
  protected searchResults: ISearchOption[] = [];
  protected relatedTopics: ISearchOption[] = [];
  protected isLeadersView: boolean = false;
  protected searchSuggestions: ISearchSuggestion[] = [];
  protected searchLinks: { url: string; translationKey: string }[] = [
    { url: '', translationKey: 'search.recommendations.link1' },
    { url: '', translationKey: 'search.recommendations.link2' },
    { url: '', translationKey: 'search.recommendations.link3' },
  ];

  constructor(
    private searchService: SearchService,
    private router: Router,
    private translateService: TranslateService,
    private trackingService: TrackingService,
  ) {
    this.subs.push(
      this.searchService.searchTermCurrent.subscribe(value => {
        this.searchTerm = value;
      }),
    );
    this.subs.push(
      this.searchService.searchResultsCurrent.subscribe(value => {
        this.searchResults = value;
      }),
    );
    this.subs.push(
      this.searchService.relatedTopicsCurrent.subscribe(value => {
        this.relatedTopics = value;
      }),
    );
  }

  ngOnInit(): void {
    this.isLeadersView = this.router.url.startsWith('/leaders');
    this.getSearchSuggestionsByLang(this.translateService.currentLang ? this.translateService.currentLang : 'en');
  }

  private async getSearchSuggestionsByLang(lang: string): Promise<void> {
    this.searchSuggestions = await this.searchService.getSearchSuggestionsByLang(lang);
  }

  public searchSuggestionClicked(value: string): void {
    this.searchService.updateSearchTerm(value);
    this.searchService.updateSearchState(true);
  }

  public changeCategory(newCategory: SEARCH_CATEGORIES): void {
    this.selectedCategory = newCategory;
  }

  public searchTermChanged(): void {
    this.searchService.updateSearchTerm(this.searchTerm);
    this.trackingService.addTrackingItem(TRACKING_ITEM.manualSearch, 'searchBy:' + this.searchTerm);
  }

  public searchTermClicked(): void {
    this.searchService.updateSearchTerm('');
    this.searchService.updateSearchState(false);
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => {
      sub.unsubscribe();
    });
  }
}
