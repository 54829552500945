<div
  #el
  class="sgt-animated-illustration"
  [attr.data-align]="align"
  [attr.data-illustration-url]="illustrationURL"
  [attr.data-illustration-top]="illustrationStyle.top"
  [attr.data-illustration-left]="illustrationStyle.left"
  [attr.data-illustration-offset-x]="illustrationStyle.offsetX"
  [attr.data-illustration-offset-y]="illustrationStyle.offsetY"
  [attr.data-illustration-width]="illustrationStyle.width"
  [attr.data-illustration-height]="illustrationStyle.height"
  [attr.data-illustration-color]="illustrationStyle.color"
  [attr.data-illustration-stroke-size]="illustrationStyle.strokeSize"
>
  <ng-content></ng-content>
</div>
