<section class="whats-next" [attr.data-scheme]="theme">
  <div class="content">
    <div *ngIf="layout === 'sectionChange'">
      <gt-animated-illustration *ngIf="illustrationUrl" [illustrationURL]="illustrationUrl" [illustrationStyle]="illustrationStyle">
        <div class="subtitle">{{ "myGrowth.shared.whats-next-subtitle-v1" | translate }}</div>
        <div class="h2">{{ title | translate }}</div>
        <gt-custom-link [trackingString]="'myGrowth.shared.whats-next-button'" [url]="btnLink + '#' + 'top-header'">
          <div class="btn btn-hero">
            <span>{{ "myGrowth.shared.whats-next-button" | translate }}</span>
          </div></gt-custom-link
        >
      </gt-animated-illustration>
    </div>
    <div *ngIf="layout === 'subsectionChange'">
      <gt-animated-illustration *ngIf="illustrationUrl" [illustrationURL]="illustrationUrl" [illustrationStyle]="illustrationStyle">
        <div class="subtitle">{{ "myGrowth.shared.whats-next-subtitle-v2" | translate }}</div>
        <div class="h3">{{ title | translate }}</div>
        <gt-custom-link [trackingString]="'myGrowth.shared.whats-next-button'" [url]="btnLink + '#' + 'subnav'">
          <div class="btn btn-hero">
            <span>{{ "myGrowth.shared.whats-next-button" | translate }}</span>
          </div></gt-custom-link
        >
      </gt-animated-illustration>
    </div>
  </div>
</section>
