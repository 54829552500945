import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public isAuthenticated$: Observable<boolean>;

  constructor(private oidcSecurityService: OidcSecurityService) {
    this.isAuthenticated$ = this.oidcSecurityService.checkAuth().pipe(map(auth => auth.isAuthenticated));
  }

  public login(): void {
    const url = window.location.pathname + window.location.search + window.location.hash;
    window.localStorage.setItem('redirectUrl', url);
    this.oidcSecurityService.authorize();
  }

  public logout(): void {
    this.oidcSecurityService.logoff();
  }

  public getIdToken(): Observable<string> {
    return this.oidcSecurityService.getIdToken();
  }

  public getAccessToken(): Observable<string> {
    return this.oidcSecurityService.getAccessToken();
  }

  public getAuthInfo(): Observable<{ isAuthenticated: boolean; userData; accessToken: string; idToken: string }> {
    return this.oidcSecurityService.checkAuth();
  }

  public isForbiddenByOrgCode(orgCode): boolean {
    return !!environment.forbiddenOrgCodes.find(forbiddenOrgCode => orgCode?.startsWith(forbiddenOrgCode));
  }
}
