<section class="sgt-round-cards" [attr.data-scheme]="scheme">
  <div class="content" [class.no-animation]="!animationUrl">
    <div class="h3" *ngIf="sectionData.subtitle">{{ sectionData.subtitle | translate }}</div>
    <div class="h2">
      <span [innerHTML]="sectionData.title1 | translate"></span>
      <gt-animated-underline *ngIf="translateService.currentLang === 'en'" [color]="sectionData.animationColor"
        ><span class="strong"> {{ sectionData.titleAnim | translate }}</span></gt-animated-underline
      >
      <span *ngIf="translateService.currentLang !== 'en'"
        ><span class="strong">{{ sectionData.titleAnim | translate }}</span></span
      >
      <span [innerHTML]="sectionData.title2 | translate"></span>
    </div>
    <p [innerHTML]="sectionData.desc | translate"></p>
    <ul [attr.data-columns]="columns">
      <li *ngFor="let card of sectionData.cards">
        <img [src]="card.imgUrl" [alt]="card.imgAltText" />
        <div class="h3" style="min-height: 100px" [attr.data-text-align]="sectionData.titleAlign" *ngIf="card.title">{{ card.title | translate }}</div>
        <p [innerHTML]="card.text | translate"></p>
        <gt-custom-link *ngIf="card.linkText" [trackingString]="card.linkText" [url]="translateService.instant(card.linkUrl)">
          <div class="textLink">
            <span>{{ card.linkText | translate }}</span>
            <span class="material-icons">chevron_right</span>
          </div>
        </gt-custom-link>
        <gt-custom-link *ngIf="card.linkText2" [trackingString]="card.linkText2" [url]="translateService.instant(card.linkUrl2)">
          <div class="textLink">
            <span>{{ card.linkText2 | translate }}</span>
            <span class="material-icons">chevron_right</span>
          </div>
        </gt-custom-link>
        <gt-custom-link *ngIf="card.linkText3" [trackingString]="card.linkText3" [url]="translateService.instant(card.linkUrl3)">
          <div class="textLink">
            <span>{{ card.linkText3 | translate }}</span>
            <span class="material-icons">chevron_right</span>
          </div>
        </gt-custom-link>
      </li>
    </ul>
    <p class="afterCards-p" *ngIf="sectionData.afterCardsText">{{ sectionData.afterCardsText | translate }}</p>
    <gt-custom-link *ngIf="sectionData.buttonLink" [trackingString]="sectionData.prompt" [url]="sectionData.buttonLink">
      <div class="btn btn-hero">
        <span>{{ sectionData.prompt | translate }}</span>
      </div>
    </gt-custom-link>
    <gt-custom-link
      *ngIf="sectionData.buttonLinkExternal"
      [trackingString]="sectionData.prompt"
      [url]="translateService.instant(sectionData.buttonLinkExternal)"
    >
      <div class="btn btn-hero">
        <span>{{ sectionData.prompt | translate }}</span>
      </div>
    </gt-custom-link>
    <gt-custom-link *ngIf="sectionData.textLink" [trackingString]="sectionData.textLink" [url]="translateService.instant(sectionData.textLinkUrl)">
      <div class="textLink">
        <span>{{ sectionData.textLink | translate }}</span>
        <span class="rearIcon icon-Arrowhead-right"></span>
      </div>
    </gt-custom-link>
    <ng-content></ng-content>
  </div>
  <div class="illustration-container" *ngIf="animationUrl">
    <gt-animated-illustration-no-image [illustrationURL]="animationUrl" [illustrationStyle]="illustrationStyle"></gt-animated-illustration-no-image>
  </div>
</section>
