import { POPUP_TYPE } from '../enums/popup_type.enum';
import { IPopupConfig } from '../interfaces/IPopupConfig';

export const popUpConfigList: IPopupConfig[] = [
  {
    startDate: new Date('2024-06-17'),
    expirationDate: new Date('2024-06-21'),
    videoId: 'Self_Reflection_Focus_Week',
    type: POPUP_TYPE.focus_week_popup,
    language: ['de', 'en', 'es', 'zh', 'pl'],
    translationKey: 'focusWeekPopup',
  },
  {
    startDate: new Date('2023-06-01'),
    expirationDate: new Date('2024-07-31'),
    videoId: 'Make_Growth_Talks_an_Everyday_Practice',
    type: POPUP_TYPE.growth_talks_popup,
    language: ['de', 'en'],
    translationKey: 'growthTalksVideo',
  },
  {
    startDate: null,
    expirationDate: new Date('2024-03-28'),
    videoId: 'Siemens_MyGrowth_SGES_Video',
    type: POPUP_TYPE.growth_talks_popup,
    language: ['de', 'en'],
    translationKey: 'growthTalksVideo',
  },
];
