import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TrackingService } from 'src/app/shared/services/tracking.service';
import { environment } from 'src/environments/environment';
import { gsap } from 'gsap';
import { TRACKING_ITEM } from 'src/app/shared/enums/tracking_item.enum';

@Component({
  selector: 'gt-calendar23-dialog',
  templateUrl: './calendar23-dialog.component.html',
  styleUrls: ['./calendar23-dialog.component.scss'],
})
export class Calendar23DialogComponent implements OnInit {
  @ViewChild('animContainer') animContainer: ElementRef;
  tl: gsap.core.Timeline;
  daysWithVideo = ['8', '12'];
  videoUrl: string;
  public isReady = false;

  constructor(
    public dialogRef: MatDialogRef<Calendar23DialogComponent>,
    public translate: TranslateService,
    private trackingService: TrackingService,
    private http: HttpClient,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: { day: string; color: string },
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.dayHasVideo()) {
      this.videoUrl = await this.getVideoUrl(this.data.day);
    }
  }

  async ngAfterViewInit(): Promise<void> {
    const dialogAnimSVG = await this.http.get('assets/images/calendar23/dialog/day' + this.data.day + '.svg', { responseType: 'text' }).toPromise();
    this.isReady = true;
    this.animContainer.nativeElement.innerHTML = dialogAnimSVG;
    const _dialogAnimSvgPaths = Array.from(this.animContainer.nativeElement.querySelectorAll('.animation>path'));
    this.tl = gsap.timeline();
    this.tl.fromTo(
      _dialogAnimSvgPaths,
      {
        drawSVG: '0',
      },
      {
        duration: 1,
        delay: 1,
        drawSVG: '100%',
      },
    );
  }

  ngOnDestroy(): void {
    this.tl?.kill();
  }

  private async getVideoUrl(id: string): Promise<string> {
    const url = await this.http.get<string>(environment.backendUrl + 'calendar/calendar23day' + id).toPromise();
    return url;
  }

  public dayHasVideo(): boolean {
    return this.daysWithVideo.includes(this.data.day);
  }

  public btnClicked(url: string): void {
    this.trackingService.addTrackingItem(
      TRACKING_ITEM.calendar,
      'day:' + this.data.day + ' - button - ' + this.trackingService.getEngTranslation('calendar23.day' + this.data.day + '.cta') + ':[from]/:[to]' + url,
    );
    if (url.startsWith('/')) {
      this.router.navigate([url], { queryParamsHandling: 'preserve' });
    } else {
      window.open(url, '_blank');
    }
  }

  public close(): void {
    this.dialogRef.close();
  }
}
