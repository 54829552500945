import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TrackingService } from '../../services/tracking.service';
import { DownloadsService } from '../../services/downloads.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { TRACKING_ITEM } from '../../enums/tracking_item.enum';

@Component({
  selector: 'gt-custom-link',
  templateUrl: './custom-link.component.html',
  styleUrls: ['./custom-link.component.scss'],
})
export class CustomLinkComponent {
  @Input() set url(value: string) {
    this._url = value;
    this.setUp(value);
  }
  get url() {
    return this._url;
  }
  @Input() ariaLabel: string;
  @Input() trackingString: string;
  @Input() doTracking: boolean = true;
  @Input() queryParams: { lang?: string; filter?: string; asset?: string };
  @Input() trackingEvent: TRACKING_ITEM = TRACKING_ITEM.button;
  @Output() linkClicked = new EventEmitter<void>();

  fragment: string;
  isInternalRoute: boolean = false;
  formatedURl: string = '';
  private _url: string;

  constructor(
    private trackingService: TrackingService,
    private router: Router,
    private downloadsService: DownloadsService,
    public translate: TranslateService,
  ) {}

  ngOnInit(): void {}

  setUp(url: string): void {
    this.formatedURl = url;
    if (url.startsWith('/')) {
      this.isInternalRoute = true;
      let newUrl = url;
      const index = url.indexOf('#');
      if (index > -1) {
        this.fragment = url.split('#').pop().split('?')[0];
        newUrl = url.split('#')[0];
      }
      if (url.includes('asset=')) {
        this.queryParams = { ...this.queryParams, asset: newUrl.split('asset=').pop() };
        newUrl = newUrl.split('?')[0];
      }
      this.formatedURl = newUrl;
    }
  }

  private getAssetFromUrl(): string {
    return this.url.indexOf('#') > -1 ? this.url.split('asset=').pop().split('#')[0] : this.url.split('asset=').pop();
  }

  itemClicked(event) {
    event.preventDefault();
    event.stopPropagation();
    this.linkClicked.emit();
    if (this.doTracking) {
      this.trackClick();
    }
    this.navigate();
  }

  async trackClick(): Promise<void> {
    let trackingModifiedString = this.trackingString ? this.trackingService.getEngTranslation(this.trackingString) : '';
    if (this.trackingEvent === TRACKING_ITEM.button || this.trackingEvent === TRACKING_ITEM.menu) {
      trackingModifiedString =
        trackingModifiedString + ':[from]' + this.trackingService.cleanRoute(this.router.url) + ':[to]' + this.trackingService.cleanRoute(this.formatedURl);
    } else if (this.trackingEvent === 'downloads') {
      trackingModifiedString =
        '[preview]:' +
        (await this.downloadsService.getTrackingIDOfDownload(this.getAssetFromUrl())) +
        ':[from]' +
        this.trackingService.cleanRoute(this.router.url);
    }
    this.trackingService.addTrackingItem(this.trackingEvent, trackingModifiedString);
  }

  navigate(): void {
    if (this.isInternalRoute) {
      if (this.fragment) {
        this.router.navigate([this.formatedURl], {
          fragment: this.fragment,
          queryParamsHandling: this.queryParams ? 'merge' : 'preserve',
          queryParams: this.queryParams,
        });
      } else {
        this.router.navigate([this.formatedURl], {
          queryParamsHandling: this.queryParams ? 'merge' : 'preserve',
          queryParams: this.queryParams,
        });
      }
    } else {
      window.open(this.url, '_blank');
    }
  }
}
