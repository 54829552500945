import { ComponentType } from '@angular/cdk/portal';
import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

export interface DialogItem {
  dialogContent: ComponentType<unknown> | TemplateRef<unknown>;
  config: MatDialogConfig;
}

@Injectable({
  providedIn: 'root',
})
export class PopupHandlerService {
  private _popupsQueue: DialogItem[] = [];
  private _dialogOpen = false;

  constructor(private _dialog: MatDialog) {}

  public openDialog(dialogItem: DialogItem): void {
    if (this._dialogOpen) {
      this._popupsQueue.push(dialogItem);
      return;
    }
    this._dialogOpen = true;
    const dialogRef = this._dialog.open(dialogItem.dialogContent, dialogItem.config);

    dialogRef.afterClosed().subscribe(() => {
      this._dialogOpen = false;
      if (this._popupsQueue.length > 0) {
        this.openDialog(this._popupsQueue.shift());
      }
    });
  }
}
