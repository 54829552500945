<div class="add-user-container">
  <span class="icon-Plus"></span>
  <button (click)="onAdd()" class="strong">{{ "admin.userManagement.addNew" | translate }}</button>
</div>

<table class="admin-table">
  <thead>
    <tr>
      <th>
        <div class="user-sort-container mouse-pointer" (click)="onToggleSort()">
          <span>{{ "admin.userManagement.table.username" | translate }}</span>
          <span *ngIf="sortAsc" class="icon-Sort-down"></span>
          <span *ngIf="!sortAsc" class="icon-Sort-up"></span>
        </div>
      </th>
      <th>{{ "admin.userManagement.table.email" | translate }}</th>
      <th>{{ "admin.userManagement.table.roles" | translate }}</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let user of users">
      <td>
        <span class="strong">{{ user.lastName }}, {{ user.firstName }}</span>
      </td>
      <td>{{ user.email }}</td>
      <td>
        <span *ngFor="let role of user.roles" class="role">{{ "admin.userManagement.roles." + role | translate }}</span>
      </td>
      <td>
        <div class="edit-container">
          <span class="icon-Edit mouse-pointer" (click)="onEdit(user.gid)"></span>
          <span class="icon-Trash mouse-pointer" (click)="onDelete(user.gid)"></span>
        </div>
      </td>
    </tr>
  </tbody>
</table>

<div *ngIf="users.length === 0" class="loading-container">
  <img src="../../../../assets/icons/loading-petrol.gif" alt="loading" />
</div>
