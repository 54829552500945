<div id="top-header" class="sgt-menubar" data-scheme="sand">
  <div class="header-container">
    <h1 class="logoSection bb" role="banner">
      <gt-custom-link [doTracking]="false" url="/" class="logo desktop"
        ><img src="assets/images/sie-logo-petrol-rgb.svg" [alt]="'Siemens logo'"
      /></gt-custom-link>
      <gt-custom-link [doTracking]="false" url="/" class="logo mobile"
        ><img src="assets/images/sie-logo-white-rgb.svg" [alt]="'Siemens logo'"
      /></gt-custom-link>
      <div class="intra desktop"><img src="assets/icons/intranet_logo.svg" alt="Intranet logo" /></div>
      <div class="intra mobile"><img src="assets/icons/intranet_logo_white.svg" alt="Intranet logo" /></div>
      <gt-custom-link [doTracking]="false" [url]="headerTitleRoute | translate" class="appName">{{ headerTitle | translate }}</gt-custom-link>
    </h1>
    <div class="header-right bb">
      <div class="lang-switch" [matMenuTriggerFor]="langMenu">
        <span class="material-icons">language</span>
      </div>
    </div>
    <mat-menu #langMenu="matMenu" [xPosition]="'after'" [class]="'langSwitchPanel'">
      <div>
        <button mat-menu-item *ngFor="let lang of languages" [class.active]="lang.code === currentLang.code" (click)="changeLanguage(lang.code)">
          {{ lang.name }}
        </button>
      </div>
    </mat-menu>
  </div>
  <div *ngIf="showCloseBtn" class="header-btn-container">
    <button class="btn btn-secondary" (click)="close()">
      <span>{{ closeApplicationBtnLabel | translate }}</span>
      <span class="rearIcon icon-Logout"></span>
    </button>
  </div>
</div>
