import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IScdGIDItem, IScdItem } from '../interfaces/IScdItem';

@Injectable({
  providedIn: 'root',
})
export class ScdService {
  constructor(private http: HttpClient) {}

  public searchUserInSCD(firstName?: string, surName?: string, eMail?: string, gid?: string): Observable<IScdItem[]> {
    return this.http.get(environment.scdUrl + 'persons' + this.buildUrlParameters(firstName, surName, eMail, gid), {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': environment.scdApiKey,
      },
    }) as Observable<IScdItem[]>;
  }

  public searchUserByGID(gid: string): Observable<IScdGIDItem[]> {
    return this.http.get(environment.scdUrl + 'persons/' + gid, {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': environment.scdApiKey,
      },
    }) as Observable<IScdGIDItem[]>;
  }

  private buildUrlParameters(firstName: string, surName: string, eMail: string, gid: string): string {
    const params = [
      ['firstName', firstName],
      ['surName', surName],
      ['mail', eMail],
      ['gid', gid],
    ].flatMap(([key, value]) => (value ? `${key}=${value.trim().toLowerCase()}*` : []));
    return params.length ? `?${params.join('&')}` : '';
  }
}
