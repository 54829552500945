import { SRJ_DIMENSIONS } from '../../enums/srj_dimensions.enum';
import { SRJ_DURATION } from '../../enums/srj_duration.enum';
import { SRJ_TOPIC } from '../../enums/srj_topic.enum';
import { SRJ_VALUES } from '../../enums/srj_values.enum';
import { ISrjCompletedStep } from '../../interfaces/ISrjCompeltedStep';

export class GetSRJData {
  static readonly type = '[SelfReflectionJourney] Get User Data';
}

export class UpdateSRJCompletedSteps {
  static readonly type = '[SelfReflectionJourney] Update Completed Steps';
  constructor(public newStep: ISrjCompletedStep) {}
}

export class UpdateSRJSelectedTopic {
  static readonly type = '[SelfReflectionJourney] Update Selected Topic';
  constructor(public topic: SRJ_TOPIC | string) {}
}

export class UpdateSRJSelectedValues {
  static readonly type = '[SelfReflectioJourney] Update Selected Values';
  constructor(public values: SRJ_VALUES[]) {}
}

export class UpdateSRJHelpfulValue {
  static readonly type = '[SelfReflectioJourney] Update Helpful Value';
  constructor(public value: SRJ_VALUES) {}
}

export class UpdateSRJUnhelpfulValue {
  static readonly type = '[SelfReflectioJourney] Update Unhelpful Value';
  constructor(public value: SRJ_VALUES) {}
}

export class UpdateSRJSelectedDimension {
  static readonly type = '[SelfReflectionJourney] Update Selected Dimension';
  constructor(public dimension: SRJ_DIMENSIONS) {}
}

export class UpdateSRJGoal {
  static readonly type = '[SelfReflectionJourney] Update Goal';
  constructor(public goal: string) {}
}

export class UpdateSRJMnemonicPhrases {
  static readonly type = '[SelfReflectionJourney] Update Mnemonic Phrases';
  constructor(public phrases: string[]) {}
}

export class UpdateSRJDuration {
  static readonly type = '[SelfReflectionJourney] Update Duration';
  constructor(public duration: SRJ_DURATION | string) {}
}

export class UpdateSRJTimeSlot {
  static readonly type = '[SelfReflectionJourney] Update Time slot';
  constructor(public timeSlot: string) {}
}

export class ResetStatus {
  static readonly type = '[SelfReflectionJourney] Reset Record';
}

export class ResetSteps {
  static readonly type = '[SelfReflectionJourney] Reset Steps';
  constructor(public newStepsArray: ISrjCompletedStep[]) {}
}
