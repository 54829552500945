import { Component } from '@angular/core';

@Component({
  selector: 'gt-admin-back-button',
  templateUrl: './admin-back-button.component.html',
  styleUrls: ['./admin-back-button.component.scss'],
})
export class AdminBackButtonComponent {
  constructor() {}
}
