import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LanguageGuard {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const allowedLang = route.data['languages'];
    if (allowedLang.length > 0 && route.queryParams && route.queryParams.lang) {
      if (allowedLang.includes(route.queryParams.lang)) {
        return true;
      } else {
        this.router.navigate([route.data.redirectUrl], { queryParamsHandling: 'merge', queryParams: route.queryParams });
      }
    }
    return true;
  }
}
