import { Injectable } from '@angular/core';
import { IMyGrowthUserProfile } from '../interfaces/IMyGrowthProfile';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IUserConfig } from '../interfaces/IUserConfig';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  public getMyGrotwhUserProfile(): Observable<IMyGrowthUserProfile> {
    return this.http.get<IMyGrowthUserProfile>(environment.backendUrl + 'user');
  }

  public getUserConfig(userGid: string): Observable<IUserConfig> {
    return this.http.get<IUserConfig>(environment.backendUrl + 'user/' + userGid + '/config');
  }

  public saveUserConfig(userGid: string, userConfig: IUserConfig): Observable<IUserConfig> {
    return this.http.patch<IUserConfig>(environment.backendUrl + 'user/' + userGid + '/config', userConfig);
  }

  public createUserConfig(userGid: string, userConfig: IUserConfig): Observable<IUserConfig> {
    return this.http.post<IUserConfig>(environment.backendUrl + 'user/' + userGid + '/config', userConfig);
  }
}
