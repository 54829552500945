<div class="admin-dialog">
  <div class="content">
    <div class="dialog-header">
      <h4 class="heading strong">{{ "admin.translations.addItemDialog.heading" | translate }}</h4>
      <span class="icon-Close" (click)="cancel()"></span>
    </div>
    <form [formGroup]="newItemForm">
      <mat-form-field class="form-field">
        <mat-label>{{ "admin.translations.addItemDialog.form.key" | translate }}</mat-label>
        <input matInput formControlName="keyName" required />
        <mat-error *ngIf="newItemForm.controls.keyName.invalid">{{ "admin.translations.addItemDialog.form.requiredError" | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field class="form-field">
        <mat-label>{{ "admin.translations.addItemDialog.form.value" | translate }}</mat-label>
        <input matInput formControlName="valueName" />
      </mat-form-field>
      <br />
      <div class="action-area">
        <button class="btn btn-secondary" (click)="cancel()">{{ "admin.translations.addItemDialog.cancelButton" | translate }}</button>
        <button class="btn btn-primary" (click)="save()">{{ "admin.translations.addItemDialog.addButton" | translate }}</button>
      </div>
    </form>
  </div>
</div>
