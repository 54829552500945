import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MYG_LANGUAGE_CODE } from 'src/app/shared/enums/language-code.enum';
import { TRACKING_ITEM } from 'src/app/shared/enums/tracking_item.enum';
import { ILanguage, LanguageService } from 'src/app/shared/services/language.service';
import { LoadingIndicatorService } from 'src/app/shared/services/loading-indicator.service';
import { SearchService } from 'src/app/shared/services/search.service';
import { TrackingService } from 'src/app/shared/services/tracking.service';

@Component({
  selector: 'gt-leaders-header',
  templateUrl: './leaders-header.component.html',
  styleUrls: ['./leaders-header.component.scss'],
})
export class LeadersHeaderComponent implements OnInit {
  private subs: Subscription[] = [];

  protected currentLang: ILanguage = { name: 'English', code: MYG_LANGUAGE_CODE.EN, rssCode: '', visible: true };
  protected languages: ILanguage[] = [];
  protected mobileNavOpen = false;
  protected showSearch = false;
  protected TRACKING_ITEM = TRACKING_ITEM;
  protected headerLinks = [
    { link: '/leaders/self-reflection', name: 'self-reflection' },
    { link: '/leaders/learning', name: 'learning' },
    { link: '/leaders/career', name: 'career' },
    { link: '/leaders/growth-talks', name: 'growth-talks' },
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    public languageService: LanguageService,
    private searchService: SearchService,
    private trackingService: TrackingService,
    private loadingIndicator: LoadingIndicatorService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.getLanguages();
    this.subs.push(
      this.route.queryParams.pipe(debounceTime(200)).subscribe(params => {
        this.loadingIndicator.updateLoadingIndicatorStatus(true);
        if (params['lang']) {
          this.setLanguage(params['lang']);
        } else {
          this.translate.use('en');
          this.loadingIndicator.updateLoadingIndicatorStatus(false);
        }
      }),
    );
    this.subs.push(
      this.searchService.isSearchOpenCurrent.subscribe(value => {
        this.showSearch = value;
      }),
    );
  }

  private setLanguage(lang: string): void {
    const langCode = this.languages.filter(s => s.code === lang);
    const defaultObject = { name: 'English', code: MYG_LANGUAGE_CODE.EN, rssCode: '', visible: true };
    this.currentLang = langCode && langCode.length ? langCode[0] : defaultObject;
    this.translate.use(this.currentLang.code);
    this.loadingIndicator.updateLoadingIndicatorStatus(false);
  }

  private async getLanguages(): Promise<void> {
    this.languages = (await this.languageService.getLanguages()).filter(s => s.code === 'en' || s.code === 'de');
  }

  public changeLanguage(lang: string) {
    this.translate.use(lang);
    this.currentLang = this.languages.filter(s => s.code === lang)[0];
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { lang: this.currentLang.code },
    });
  }

  ngOnDestroy() {
    this.subs.forEach(sub => {
      sub.unsubscribe();
    });
  }

  public goTo(view: 'leaders' | 'general'): void {
    if (view === 'leaders') {
      this.trackingService.addTrackingItem(
        TRACKING_ITEM.menu,
        'MyGrowth Leaders View:[from]' + this.trackingService.cleanRoute(this.router.url) + ':[to]' + '/leaders',
      );
      this.router.navigate(['/leaders'], { queryParamsHandling: 'preserve' });
    } else {
      this.trackingService.addTrackingItem(
        TRACKING_ITEM.menu,
        'MyGrowth General View:[from]' + this.trackingService.cleanRoute(this.router.url) + ':[to]' + '/',
      );
      this.router.navigate(['/'], { queryParamsHandling: 'preserve' });
    }
  }

  public toggleSearch(): void {
    this.searchService.updateSearchState(!this.showSearch);
  }
}
