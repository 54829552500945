<section class="sgt-subnav" data-scheme="light">
  <div class="sgt-subnav-menubutton" (click)="mobileNavOpen = !mobileNavOpen">
    <div class="sgt-subnav-menubutton-show" style="transform: translate(0%, 0px)"></div>
    <div class="sgt-subnav-menubutton-close" style="transform: translate(0%, 0px)"></div>
  </div>
  <h2>
    <gt-custom-link [doTracking]="false" [url]="'/' + section + '#subnav'">{{ "myGrowth.navigation." + section | translate }}</gt-custom-link>
  </h2>
  <nav aria-label="secondary" class="sgt-subnav-static">
    <ul>
      <li *ngFor="let item of subNavLinks" routerLinkActive="active" [routerLink]="item.link">
        <gt-custom-link [trackingEvent]="TRACKING_ITEM.menu" [trackingString]="item.name" [url]="item.link" fragment="subnav">{{
          item.name | translate
        }}</gt-custom-link>
      </li>
    </ul>
  </nav>
  <nav aria-label="secondary" class="sgt-subnav-flyout" [class.open]="mobileNavOpen">
    <section>
      <h2>
        <gt-custom-link [doTracking]="false" [url]="'/' + section + '#subnav'">{{ "myGrowth.navigation." + section | translate }}</gt-custom-link>
      </h2>
      <ul>
        <li *ngFor="let item of subNavLinks">
          <gt-custom-link [doTracking]="false" fragment="subnav" [url]="item.link">{{ item.name | translate }}</gt-custom-link>
        </li>
      </ul>
    </section>
  </nav>
</section>
