import { Component, Input, OnInit } from '@angular/core';
import { AnimatedIllustrationStyle } from '../../models/animated-illustration-style.model';

@Component({
  selector: 'gt-animated-underline',
  templateUrl: './animated-underline.component.html',
  styleUrls: ['./animated-underline.component.scss'],
})
export class AnimatedUnderlineComponent implements OnInit {
  @Input() strokeType: 'z' | 'linear' | 'wavy' = 'z';
  @Input() strokeSize: number;
  @Input() color: string;

  @Input() illustrationURL: string;
  @Input() illustrationStyle: AnimatedIllustrationStyle;

  constructor() {}

  ngOnInit(): void {
    if (!this.illustrationURL) {
      switch (this.strokeType) {
        case 'z':
        default:
          this.illustrationURL = '/assets/images/underline-stroke-z-illustration.svg';
          // require("CONTENT/generic/underline-stroke-z-illustration.svg").default;
          this.illustrationStyle = {
            top: '0.8em',
            left: '0%',
            color: this.color,
            strokeSize: this.strokeSize,
          };
          break;
        case 'linear':
          this.illustrationURL = '/assets/images/underline-stroke-illustration.svg';
          // require("CONTENT/generic/underline-stroke-illustration.svg").default;
          this.illustrationStyle = {
            top: '0.7em',
            left: '0%',
            color: this.color,
            strokeSize: this.strokeSize,
          };
          break;
        case 'wavy':
          this.illustrationURL = '/assets/images/underline-stroke-wave-illustration.svg';
          // require("CONTENT/generic/underline-stroke-illustration.svg").default;
          this.illustrationStyle = {
            top: '0.7em',
            left: '0%',
            color: this.color,
            strokeSize: this.strokeSize,
          };
      }
    } else {
      this.illustrationStyle = {
        top: '40px',
        left: '0%',
      };
    }
  }
}
