<section *ngIf="rssData.rss" class="sgt-myGrowth-home-teasers">
  <div class="h2">
    {{ "myGrowth.home.teasers.title" | translate }}
  </div>
  <ul>
    <li *ngFor="let item of rssData.rss?.channel?.item">
      <img [src]="item.imageUrl ? item.imageUrl : ''" alt="" />
      <div>
        <div
          class="h3"
          *ngIf="item.titleDisplay"
          data-text-align="left"
          [matTooltip]="item.title"
          matTooltipPosition="above"
          [matTooltipClass]="'sie-tooltip'"
          [matTooltipHideDelay]="500"
        >
          {{ item.titleDisplay }}
        </div>
        <div class="h3" *ngIf="!item.titleDisplay && item.title" data-text-align="left">{{ item.title }}</div>
        <p>{{ item.description.__cdata }}</p>
        <gt-custom-link [trackingString]="'Discover more'" [url]="item.link">
          <span>{{ "Discover more" | translate }}</span>
          <span class="icon icon-Arrowhead-right"></span>
        </gt-custom-link>
      </div>
    </li>
  </ul>
</section>
