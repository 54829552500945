<div class="dialog-container">
  <div class="title">{{ data.title }}</div>
  <div class="viewer-container">
    <ngx-doc-viewer [url]="data.link" viewer="google" disableContent="popout" style="width: 100%; height: 50vh" (loaded)="documentLoaded()"></ngx-doc-viewer>
    <div class="spinner-container" *ngIf="!isReady">
      <mat-spinner></mat-spinner>
    </div>
    <div [innerHTML]="'growthTalks.resources.hintDownload' | translate"></div>
  </div>
  <div class="btns-container">
    <button class="btn btn-secondary" (click)="close()">Close</button>
    <button class="btn btn-primary" (click)="download()">Download</button>
  </div>
</div>
