<section class="sgt-content-section marginBottom sand-section">
  <div class="expert-list">
    <div *ngFor="let expert of sectionData.strengthsExpertsList" class="expert">
      <img [src]="expert.img" />
      <div class="expert-name-desc">
        <gt-animated-underline [color]="'#00D7A0'" *ngIf="expert.animation === 'line'">
          <div class="h3">{{ expert.name | translate }}</div>
        </gt-animated-underline>
        <gt-animated-rounded-marker *ngIf="expert.animation === 'circle'" [illustrationStyle]="{ top: '-85%', height: '4.5em', left: '-6%', width: '110%' }">
          <div class="h3">{{ expert.name | translate }}</div>
        </gt-animated-rounded-marker>
        <div class="expert-desc">{{ expert.desc | translate }}</div>
      </div>
    </div>
  </div>
  <div class="experts-description">
    <div class="h2" [innerHTML]="sectionData.title | translate"></div>
    <p class="experts-description-paragraphs" [innerHTML]="sectionData.desc | translate"></p>
    <gt-custom-link [trackingString]="sectionData.btnText" [url]="translateService.instant(sectionData.btnLink)">
      <div class="btn btn-hero">
        <span>{{ sectionData.btnText | translate }}</span>
      </div>
    </gt-custom-link>
    <p class="experts-description-paragraphs padding-top20" [innerHTML]="sectionData.workshopInfo | translate"></p>
    <gt-custom-link [trackingString]="sectionData.btn2Text" [url]="translateService.instant(sectionData.btn2Link)">
      <div class="btn btn-hero">
        <span>{{ sectionData.btn2Text | translate }}</span>
      </div>
    </gt-custom-link>
  </div>
</section>
