import { Component, Input, OnInit } from '@angular/core';
import { AnimatedIllustrationStyle } from '../../models/animated-illustration-style.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gt-animated-strokes-around',
  templateUrl: './animated-strokes-around.component.html',
  styleUrls: ['./animated-strokes-around.component.scss'],
})
export class AnimatedStrokesAroundComponent implements OnInit {
  @Input()
  set color(color: string) {
    this.illustrationStyle = {
      top: '-75%',
      left: '-41%',
      width: '186%',
      height: '3.2em',
      color,
    };
  }

  illustrationURL = '/assets/images/tips_strokesaround_illustration.svg';
  illustrationStyle: AnimatedIllustrationStyle = {};

  constructor(private translateService: TranslateService) {
    this.translateService.onLangChange.subscribe(langChange => {
      if (langChange.lang !== 'en' && langChange.lang !== 'de') {
        this.illustrationURL = '';
      } else {
        this.illustrationURL = '/assets/images/tips_strokesaround_illustration.svg';
      }
    });
  }

  ngOnInit(): void {}
}
