import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MYG_LANGUAGE_CODE } from '../enums/language-code.enum';

export type ILanguage = {
  code: MYG_LANGUAGE_CODE;
  name: string;
  rssCode?: string;
  visible: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(private http: HttpClient) {}

  private languagesCache: ILanguage[] = [];

  public async updateLanguage(language: ILanguage): Promise<any> {
    return this.http.post(environment.backendUrl + 'languages/update', language).toPromise();
  }

  public async getLanguages(): Promise<ILanguage[]> {
    return new Promise((resolve, reject) => {
      if (this.languagesCache.length) {
        resolve(this.languagesCache);
      } else {
        this.http
          .get<ILanguage[]>(environment.backendUrl + 'languages')
          .toPromise()
          .then(languages => {
            this.languagesCache = languages;
            resolve(languages);
          })
          .catch(reject);
      }
    });
  }

  //to relplace the above
  public getLanguagesObservable(): Observable<ILanguage[]> {
    if (this.languagesCache.length) {
      return of(this.languagesCache);
    } else {
      return this.http.get<ILanguage[]>(environment.backendUrl + 'languages').pipe(tap(languages => (this.languagesCache = languages)));
    }
  }
}
