import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ISurveyConfig, ISurveyConfigBasic } from '../interfaces/ISurveyConfig';
import { ISurveyAnswer } from '../interfaces/ISurveyAnswer';
import { HttpClient } from '@angular/common/http';
import { catchError, take, tap } from 'rxjs/operators';
import { IUserData } from '../interfaces/IUserData';
import { Select } from '@ngxs/store';
import { UserState } from '../state-management/user/user.state';

@Injectable({
  providedIn: 'root',
})
export class SurveyService {
  @Select(UserState.loggedUser) loggedUser$: Observable<IUserData>;
  private showSurveySource = new BehaviorSubject(false);
  showSurveyBtn = this.showSurveySource.asObservable();
  validCostCenterLocationsForFeedback = ['7092'];

  constructor(private http: HttpClient) {}

  public updateShowSurveyBtn(value: boolean): void {
    this.showSurveySource.next(value);
  }

  public getSurveyConfigById(surveyId: string): Promise<ISurveyConfig> {
    return this.http.get<ISurveyConfig>(environment.backendUrl + 'survey/' + surveyId).toPromise();
  }

  public getAllSurveyConfig(): Observable<ISurveyConfig[]> {
    return this.http.get<ISurveyConfig[]>(environment.backendUrl + 'survey').pipe(catchError(this.handleError<any>('getAllSurveyConfig')));
  }

  public getActiveSurveyConfig(): Observable<ISurveyConfig> {
    return this.http.get<ISurveyConfig>(environment.backendUrl + 'survey/active').pipe(catchError(this.handleError<any>('getActiveSurveyConfig')));
  }

  public postSurveyAnswer(body: ISurveyAnswer): Observable<ISurveyAnswer> {
    return this.http
      .post<ISurveyAnswer>(environment.backendUrl + 'survey/' + body.id + '/survey-answers', body)
      .pipe(catchError(this.handleError<any>('postSurveyAnswer')));
  }

  public getSurveyAnswers(surveyId: string): Observable<ISurveyAnswer[]> {
    return this.http
      .get<ISurveyAnswer[]>(environment.backendUrl + 'survey/' + surveyId + '/survey-answers')
      .pipe(catchError(this.handleError<any>('getSurveyAnswers')));
  }

  public createSurvey(data: ISurveyConfigBasic): Observable<ISurveyConfig> {
    return this.http.post<ISurveyConfig>(environment.backendUrl + 'survey', data).pipe(catchError(this.handleError<any>('createSurvey')));
  }

  public updateSurvey(surveyId: string, update: ISurveyConfigBasic) {
    return this.http.patch(environment.backendUrl + 'survey/' + surveyId, update).pipe(catchError(this.handleError<any>('updateSurvey')));
  }

  async deleteSurvey(surveyId: string) {
    await this.http.delete(environment.backendUrl + 'survey/' + surveyId).toPromise();
  }

  private handleError<T>(operation: string): (error: any) => Observable<T> {
    return (error: any): any => {
      console.error(`Error when running ${operation}: ${error}`);
      return of(error as Observable<any>);
    };
  }

  public userCostCenterValid(costCenter: string): boolean {
    return this.validCostCenterLocationsForFeedback.includes(costCenter) || this.loggedUserIsTester();
  }

  private loggedUserIsTester(): boolean {
    let isTester = false;

    this.loggedUser$
      .pipe(
        take(1),
        tap((user: IUserData) => {
          isTester = environment.testerGIDs.includes(user.gid);
          if (isTester) {
            console.log('You are a tester!');
          }
        }),
      )
      .subscribe();

    return isTester;
  }
}
