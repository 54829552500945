import { ISearchOption } from 'src/app/shared/interfaces/ISearchOption';

export const searchOptionsEn: ISearchOption[] = [
  {
    value: 'MyGrowth',
    url: '/',
    section: 'general',
  },
  {
    value: 'MyGrowth',
    url: '/leaders',
    section: 'leaders',
  },
  {
    value: 'Self-Reflection',
    url: '/self-reflection/',
    section: 'general',
  },
  {
    value: 'Self-Reflection',
    url: '/leaders/self-reflection/',
    section: 'leaders',
  },
  {
    value: 'Learning',
    url: '/learning/',
    section: 'general',
  },
  {
    value: 'Learning',
    url: '/leaders/learning/',
    section: 'leaders',
  },
  {
    value: 'Career',
    url: '/career/',
    section: 'general',
  },
  {
    value: 'Career',
    url: '/leaders/career/',
    section: 'leaders',
  },
  {
    value: 'Self-Care',
    url: '/self-reflection/why-self-reflection#self-care',
    section: 'general',
  },
  {
    value: 'Stress-Satisfaction-Check',
    url: '/self-reflection/why-self-reflection#self-care',
    section: 'general',
  },
  {
    value: 'My Strengths',
    url: '/self-reflection/strengths#subnav',
    section: 'general',
  },
  {
    value: 'My Strengths',
    url: '/leaders/self-reflection/strengths#subnav',
    section: 'leaders',
  },
  {
    value: 'My Feedback',
    url: '/self-reflection/feedback#subnav',
    section: 'general',
  },
  {
    value: 'Multi-Source Feedback',
    url: '/self-reflection/feedback#subnav',
    section: 'general',
  },
  {
    value: 'Multi-Source Feedback',
    url: '/leaders/self-reflection/feedback#subnav',
    section: 'leaders',
  },
  {
    value: 'Strengthscope',
    url: '/self-reflection/strengths#subnav',
    section: 'general',
  },
  {
    value: 'My Skills',
    url: '/self-reflection/skills#subnav',
    section: 'general',
  },
  {
    value: 'My Skills',
    url: '/leaders/self-reflection/skills#subnav',
    section: 'leaders',
  },
  {
    value: 'Skills',
    url: '/self-reflection/skills#subnav',
    section: 'general',
  },
  {
    value: 'My Team Skills',
    url: '/leaders/self-reflection/skills#subnav',
    section: 'leaders',
  },
  {
    value: 'Strength-based leadership',
    url: '/leaders/self-reflection/strengths#subnav',
    section: 'leaders',
  },
  {
    value: 'My Strengths team workshops',
    url: '/leaders/self-reflection/strengths#subnav',
    section: 'leaders',
  },
  {
    value: 'Skill Management',
    url: '/self-reflection/skills#subnav',
    section: 'general',
  },
  {
    value: 'Coaching',
    url: '/self-reflection/coaching#subnav',
    section: 'general',
  },
  {
    value: 'My Learning World',
    url: '/learning/',
    section: 'general',
  },
  {
    value: 'MyGrowth presentation',
    url: '/leaders?asset=Intro_MyGrowth_EN.pptx',
    section: 'leaders',
  },
  {
    value: 'Resources',
    url: '/learning/how-to-start',
    section: 'general',
  },
  {
    value: 'Knowledge Boards',
    url: '/learning/how-to-start',
    section: 'general',
  },
  {
    value: 'Learning Channels',
    url: '/learning/how-to-start',
    section: 'general',
  },
  {
    value: 'Core Learning Paths',
    url: '/learning/how-to-start',
    section: 'general',
  },
  {
    value: 'MyGrowth E-Learning',
    url: '/learning/how-to-start#subnav',
    section: 'general',
  },
  {
    value: 'MyGrowth E-Learning',
    url: '/leaders#diveDeeper',
    section: 'leaders',
  },
  {
    value: 'Growth Talks E-Learning',
    url: '/growth-talks/how-it-works#subnav',
    section: 'general',
  },
  {
    value: 'Growth Talks E-Learning',
    url: '/leaders/growth-talks/role#subnav',
    section: 'leaders',
  },
  {
    value: 'Growth Talks about skills',
    url: '/leaders/self-reflection/skills#subnav',
    section: 'leaders',
  },
  {
    value: 'MyGrowth Learning Channel',
    url: '/learning/how-to-start#subnav',
    section: 'general',
  },
  {
    value: 'Growth Talks learning Channel',
    url: '/growth-talks/how-it-works#subnav',
    section: 'general',
  },
  {
    value: 'Growth Talks learning Channel',
    url: '/leaders/growth-talks/role#subnav',
    section: 'leaders',
  },
  {
    value: 'How to start learning',
    url: '/learning/how-to-start#subnav',
    section: 'general',
  },
  {
    value: 'Learning hours',
    url: '/leaders/learning/learning-hours#subnav',
    section: 'leaders',
  },
  {
    value: 'My Community Learning',
    url: '/learning/features#subnav',
    section: 'general',
  },
  {
    value: 'My Community Learning',
    url: '/leaders/learning/community-learning#subnav',
    section: 'leaders',
  },
  {
    value: 'Content Assignments',
    url: '/leaders/learning/content-assignments#subnav',
    section: 'leaders',
  },
  {
    value: 'Training',
    url: '/leaders/learning/content-assignments#subnav',
    section: 'leaders',
  },
  {
    value: 'Learning Recommendation',
    url: '/leaders/learning/content-assignments#subnav',
    section: 'leaders',
  },
  {
    value: 'Tracking Learning Progress',
    url: '/leaders/learning/content-assignments#subnav',
    section: 'leaders',
  },
  {
    value: 'Training for Leaders',
    url: '/leaders/learning/content-assignments#subnav',
    section: 'leaders',
  },
  {
    value: 'Achievements',
    url: '/learning/features#subnav',
    section: 'general',
  },
  {
    value: 'Learning Playlists',
    url: '/learning/features#subnav',
    section: 'general',
  },
  {
    value: 'Learning Playlists',
    url: '/leaders/learning/learning-playlists#subnav',
    section: 'leaders',
  },
  {
    value: 'Interests',
    url: '/learning/features#subnav',
    section: 'general',
  },
  {
    value: 'Learning Partners',
    url: '/learning/partners#subnav',
    section: 'general',
  },
  {
    value: 'LinkedIn Learning',
    url: '/learning/partners#subnav',
    section: 'general',
  },
  {
    value: 'Harvard Business Review',
    url: '/learning/partners#subnav',
    section: 'general',
  },
  {
    value: 'GetAbstract',
    url: '/learning/partners#subnav',
    section: 'general',
  },
  {
    value: 'GoodHabitz',
    url: '/learning/partners#subnav',
    section: 'general',
  },
  {
    value: 'Coursera',
    url: '/learning/partners#subnav',
    section: 'general',
  },
  {
    value: 'Thrive',
    url: '/learning/partners#subnav',
    section: 'general',
  },
  {
    value: 'Learnlight',
    url: '/learning/partners#subnav',
    section: 'general',
  },
  {
    value: 'Shadowing',
    url: '/career/grow#shadowing',
    section: 'general',
  },
  {
    value: 'Shadowing',
    url: '/leaders/career/shadowing#subnav',
    section: 'leaders',
  },
  {
    value: 'Mentoring',
    url: '/career/grow#mentoring',
    section: 'general',
  },
  {
    value: 'Mentoring',
    url: '/leaders/career/mentoring#subnav',
    section: 'leaders',
  },
  {
    value: 'Open Job Market',
    url: '/career/move#jobMarket',
    section: 'general',
  },
  {
    value: 'Job Tagging',
    url: '/career/move#jobTagging',
    section: 'general',
  },
  {
    value: 'Job Tagging',
    url: '/leaders/career/job-tagging#subnav',
    section: 'leaders',
  },
  {
    value: 'React to a job tag',
    url: '/leaders/career/job-tagging#subnav',
    section: 'leaders',
  },
  {
    value: 'People Profile',
    url: '/career/move#profile',
    section: 'general',
  },
  {
    value: 'People Profile',
    url: '/leaders/career/people-profile#subnav',
    section: 'leaders',
  },
  {
    value: 'Potential Development Programs',
    url: '/career/grow#subnav',
    section: 'general',
  },
  {
    value: 'Growth Talks',
    url: '/growth-talks/introduction',
    section: 'general',
  },
  {
    value: 'Growth Talks',
    url: '/leaders/growth-talks/role#subnav',
    section: 'leaders',
  },
  {
    value: 'My Brand Builder',
    url: '/career/visible#subnav',
    section: 'general',
  },
  {
    value: 'My Brand Builder',
    url: '/leaders/career/people-profile#subnav',
    section: 'leaders',
  },
  {
    value: 'Personal branding',
    url: '/leaders/career/people-profile#subnav',
    section: 'leaders',
  },
  {
    value: 'Growth Talks FAQs',
    url: '/help',
    section: 'general',
  },
  {
    value: 'SGES and Growth Talks',
    url: '/leaders/growth-talks/sges#subnav',
    section: 'leaders',
  },
  {
    value: 'How Growth Talks work',
    url: '/growth-talks/how-it-works/0#subnav',
    section: 'general',
  },
  {
    value: 'How Growth Talks work',
    url: 'leaders/growth-talks/role#top-header',
    section: 'leaders',
  },
  {
    value: 'Continuously aligned Expectations',
    url: '/growth-talks/how-it-works/everyone/0#talks-tabs',
    section: 'general',
  },
  {
    value: 'Continuously aligned Expectations',
    url: '/leaders/growth-talks/how#subnav',
    section: 'leaders',
  },
  {
    value: 'Growth Perspective',
    url: '/growth-talks/how-it-works/everyone/1#talks-tabs',
    section: 'general',
  },
  {
    value: 'Growth Perspective',
    url: '/leaders/growth-talks/how#subnav',
    section: 'leaders',
  },
  {
    value: 'Everyday Learning',
    url: '/growth-talks/how-it-works/everyone/2#talks-tabs',
    section: 'general',
  },
  {
    value: 'Everyday Learning',
    url: '/leaders/growth-talks/how#subnav',
    section: 'leaders',
  },
  {
    value: 'Timely Recognition',
    url: '/growth-talks/how-it-works/everyone/3#talks-tabs',
    section: 'general',
  },
  {
    value: 'Timely Recognition',
    url: '/leaders/growth-talks/how#subnav',
    section: 'leaders',
  },
  {
    value: 'Growth Talks 1-1 on expectations',
    url: '/growth-talks/how-it-works#subnav',
    section: 'general',
  },
  {
    value: 'Growth Talks 1-1 on expectations',
    url: '/leaders/growth-talks/how#subnav',
    section: 'leaders',
  },
  {
    value: 'Growth Talks as team discussion',
    url: '/growth-talks/how-it-works#subnav',
    section: 'general',
  },
  {
    value: 'Growth Talks as team discussion',
    url: '/leaders/growth-talks/how#subnav',
    section: 'leaders',
  },
  {
    value: 'Growth Talks and self-reflection',
    url: '/growth-talks/how-it-works#subnav',
    section: 'general',
  },
  {
    value: 'Growth Talks and self-reflection',
    url: '/leaders/growth-talks/how#subnav',
    section: 'leaders',
  },
  {
    value: 'Growth Talks on personal development',
    url: '/growth-talks/how-it-works#subnav',
    section: 'general',
  },
  {
    value: 'Growth Talks on personal development',
    url: '/leaders/growth-talks/how#subnav',
    section: 'leaders',
  },
  {
    value: 'Development Forum',
    url: '/leaders/growth-talks/how#subnav',
    section: 'leaders',
  },
  {
    value: 'Growth Talks 2x3',
    url: '/growth-talks/resources#subnav',
    section: 'general',
  },
  {
    value: 'Growth Talks 2x3',
    url: '/leaders/growth-talks/how#subnav',
    section: 'leaders',
  },
  {
    value: 'Shadowing',
    url: '/career/grow#shadowing',
    section: 'general',
  },
  {
    value: 'Shadowing',
    url: '/leaders/career/shadowing',
    section: 'leaders',
  },
  {
    value: 'Job posting',
    url: '/career/move#jobMarket',
    section: 'general',
  },
  {
    value: 'Be a coach',
    url: '/self-reflection/coaching#subnav',
    section: 'general',
  },
  {
    value: 'Find a mentor',
    url: '/career/grow#mentoring',
    section: 'general',
  },
  {
    value: 'Be a mentor',
    url: '/career/grow#mentoring',
    section: 'general',
  },
  {
    value: 'Be a mentor',
    url: '/leaders/career/mentoring',
    section: 'leaders',
  },
  {
    value: 'Mentee',
    url: '/career/grow#mentoring',
    section: 'general',
  },
  {
    value: 'Strengthscope access code',
    url: '/self-reflection/strengths#subnav',
    section: 'general',
  },
  {
    value: 'Own Your Career',
    url: '/career/shape-career',
    section: 'general',
  },
  {
    value: 'OYC',
    url: '/career/shape-career',
    section: 'general',
  },
  {
    value: 'Competency',
    url: '/self-reflection/skills#subnav',
    section: 'general',
  },
  {
    value: 'Downloads for Growth Talks',
    url: '/growth-talks/resources#subnav',
    section: 'general',
  },
  {
    value: 'Downloads for Growth Talks',
    url: 'leaders/growth-talks/how',
    section: 'leaders',
  },
  {
    value: 'Find a job',
    url: '/career/move#jobMarket',
    section: 'general',
  },
  {
    value: 'Support',
    url: '/help',
    section: 'general',
  },
  {
    value: 'Badges',
    url: '/learning/features#subnav',
    section: 'general',
  },
  {
    value: 'Learning event',
    url: '/learning/features#subnav',
    section: 'general',
  },
  {
    value: 'Learning session',
    url: '/learning/features#subnav',
    section: 'general',
  },
  {
    value: 'PDP',
    url: '/career/grow#subnav',
    section: 'general',
  },
  {
    value: 'Growth Talks Tips',
    url: '/growth-talks/how-it-works#subnav',
    section: 'general',
  },
  {
    value: 'Growth Talks Tips',
    url: '/leaders/growth-talks/how#subnav',
    section: 'leaders',
  },
  {
    value: 'Strengthscope® workshop material',
    url: '/leaders/self-reflection/strengths#StrengthscopeWorkshopMaterial ',
    section: 'leaders',
  },
  {
    value: 'My Growth image video',
    url: '/leaders#MyGrowthImageVideo',
    section: 'leaders',
  },
];
