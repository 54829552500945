export class IAssetPreview {
  id?: string;
  title: string;
  link: string;
  key: string;

  constructor(value) {
    this.title = value.title;
    this.link = value.link;
    this.key = value.key;
    if (value?.id) {
      this.id = value.id;
    }
  }
}
