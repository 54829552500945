import { Overlay } from '@angular/cdk/overlay';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AssetDialogComponent } from '../components/asset-dialog/asset-dialog.component';
import { IAssetPreview } from '../interfaces/IAssetPreview';
import { LoadingIndicatorService } from './loading-indicator.service';
import { DownloadsService } from './downloads.service';
import { ISingleDownloadItem } from '../interfaces/IDownloadItem';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class DocumentPreviewService {
  private lang: string = 'en';

  constructor(
    private dialog: MatDialog,
    private overlay: Overlay,
    private router: Router,
    private http: HttpClient,
    private loadingIndicator: LoadingIndicatorService,
    private route: ActivatedRoute,
    private downloadService: DownloadsService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
  ) {
    this.route.queryParams.subscribe(async value => {
      if (value.lang) {
        this.lang = value.lang;
      }
    });
  }

  public async assetPreview(assetId: string): Promise<void> {
    this.loadingIndicator.updateLoadingIndicatorStatus(true);
    let assetPreview: IAssetPreview;
    const downloadMappingItem = this.downloadService.checkIfAssetMapped(assetId, this.lang);
    if (!downloadMappingItem.length && !this.downloadService.isUUID(assetId)) {
      try {
        const singleDownload: ISingleDownloadItem = await this.downloadService.getSingleDownloadItemByTrackingId(this.lang, assetId);
        assetPreview = new IAssetPreview({
          id: singleDownload.download.trackingId,
          title: singleDownload.download.title,
          link: singleDownload.fileLink,
          key: singleDownload.fileKey,
        });
      } catch (err) {
        console.error('No download item found by tracking id.', err);
        try {
          const url = await this.http.get<string>(environment.backendUrl + 'items/' + this.lang + '/' + assetId).toPromise();
          assetPreview = {
            title: '',
            link: url,
            id: assetId,
            key: this.lang + '/singleDownloads/' + assetId,
          };
        } catch (err) {
          console.error('presigned url could not be fetched for ', assetId, err);
          this.snackBar.open(this.translateService.instant('admin.errors.single-download-not-found'), 'X', { duration: 5000 });
          this.loadingIndicator.updateLoadingIndicatorStatus(false);
        }
      }
    } else {
      if (downloadMappingItem.length) {
        assetId = downloadMappingItem[0].downloadId;
      }
      const singleDownload = await this.downloadService.getSingleDownloadItemById(this.lang, assetId);
      assetPreview = new IAssetPreview({
        id: singleDownload.download.trackingId,
        title: singleDownload.download.title,
        link: singleDownload.fileLink,
        key: singleDownload.fileLink.split('/').slice(3).join('/').split('?')[0],
      });
    }
    if (assetPreview) {
      this.openPreview(assetPreview);
    } else {
      this.removeAssetParam();
    }
  }
  public openPreview(item: IAssetPreview): void {
    const dialogRef = this.dialog.open(AssetDialogComponent, {
      width: '80vh',
      data: item,
      scrollStrategy: this.overlay.scrollStrategies.noop(),
    });
    this.loadingIndicator.updateLoadingIndicatorStatus(false);
    dialogRef.afterClosed().subscribe(res => {
      this.router.navigate([], {
        queryParams: {
          asset: null,
        },
        queryParamsHandling: 'merge',
      });
    });
  }

  private removeAssetParam(): void {
    this.router.navigate([], {
      queryParams: {
        asset: null,
      },
      queryParamsHandling: 'merge',
    });
  }
}
