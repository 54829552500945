<section class="admin-min-height">
  <main>
    <gt-admin-back-button></gt-admin-back-button>
    <h2 class="admin-heading">{{ "admin.translations.heading" | translate }}</h2>
    <div>
      <div class="space-between">
        <div *ngIf="isLoadingLanguages" class="loading-container">
          <img src="../../../../assets/icons/loading-petrol.gif" alt="loading" />
        </div>
        <ul *ngIf="!isLoadingLanguages" class="language-selector">
          <li *ngFor="let language of availableLanguages" (click)="onLanguageSelect(language.code)" [ngClass]="{ active: selectedLanguage === language.code }">
            <div [style]="'background-image: url(../../../assets/flags/' + language.code + '.svg)'" class="country-flag"></div>
            <span *ngIf="language.visible" class="prod-banner">PROD</span>
          </li>
        </ul>
        <div class="btn-container">
          <button class="btn btn-secondary" (click)="onShowVisabilityDialog()" *ngIf="isAdmin">{{ "admin.translations.langVisibility" | translate }}</button>
          <button class="btn btn-secondary" (click)="onAddLanguage()" *ngIf="isAdmin">{{ "admin.translations.addLang" | translate }}</button>
        </div>
      </div>
    </div>

    <mat-tab-group [selectedIndex]="selectedTab" (selectedTabChange)="onTabChange($event)">
      <mat-tab [label]="'admin.translations.tab1' | translate">
        <br />
        <div class="space-between">
          <div class="filter-container">
            <div class="search">
              <input type="search" (change)="onSearch($event)" />
              <span class="icon-Search icon"></span>
            </div>
            <mat-checkbox [checked]="showOnlyEmpty" (change)="toggleOnlyEmpty($event)" [disabled]="noEmptyValuesExist()">
              <span>{{ "admin.translations.showOnlyEmpty" | translate }}</span>
            </mat-checkbox>
          </div>

          <!-- <button class="btn btn-secondary" (click)="onDownload()">Download language</button> -->
          <button class="btn btn-primary" [disabled]="changes.length === 0" (click)="onSave()">
            {{ "admin.translations." + (isSaving ? "saving" : "save") | translate }}
          </button>
        </div>
        <br />
        <p *ngIf="!selectedLanguage" class="select-language">{{ "admin.translations.selectLang" | translate }}</p>
        <div *ngIf="isLoading" class="loading-container">
          <img src="../../../../assets/icons/loading-petrol.gif" alt="loading" />
        </div>
        <div *ngIf="!isLoading && selectedLanguage">
          <gt-admin-recursive-translation
            [data]="translationObject"
            [orig]="englishOrig"
            [canEdit]="isAdmin && canAddKeys && selectedLanguage === 'en'"
            [currentLang]="selectedLanguage"
            (topic)="onAddTopic($event)"
            (item)="onAddItem($event)"
            path=""
            (onChange)="handleChange($event)"
            (removeItem)="onRemoveItem($event)"
            (removeTopic)="onRemoveTopic($event)"
          ></gt-admin-recursive-translation>
          <div class="add-container" *ngIf="canAddKeys && selectedLanguage === 'en'">
            <button class="btn btn-primary" (click)="onAddTopic('')">{{ "admin.translations.addTopic" | translate }}</button>
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="'admin.translations.tab2' | translate">
        <div *ngIf="isLoadingUpdates" class="loading-container">
          <img src="../../../../assets/icons/loading-petrol.gif" alt="loading" />
        </div>
        <div *ngIf="!isLoadingUpdates">
          <div class="translations-update-container">
            <div class="stage-container">
              <h4 class="strong">{{ "admin.translations.devNotProd" | translate }}</h4>
              <h6>{{ "admin.translations.willAdd" | translate }}</h6>
              <button *ngIf="areAllDevSelected() === false" class="btn btn-secondary" (click)="onSelectAddAll()">
                <span>{{ "admin.translations.selectAll" | translate }}</span>
                <span class="icon-Plus-small"></span>
              </button>
              <button *ngIf="areAllDevSelected() === true" class="btn btn-secondary" (click)="onDeselectAddAll()">
                <span>{{ "admin.translations.deselectAll" | translate }}</span>
                <span class="icon-Minus-small"></span>
              </button>
              <gt-admin-recursive-translation-changes [data]="existsInDev" path="" (select)="onSelectAdd($event)" [selected]="addToProd">
              </gt-admin-recursive-translation-changes>
            </div>
            <div class="stage-container">
              <h4 class="strong">{{ "admin.translations.prodNotDev" | translate }}</h4>
              <h6>{{ "admin.translations.willDelete" | translate }}</h6>
              <button *ngIf="areAllProdSelected() === false" class="btn btn-secondary" (click)="onSelectDeleteAll()">
                <span>{{ "admin.translations.selectAll" | translate }}</span>
                <span class="icon-Plus-small"></span>
              </button>
              <button *ngIf="areAllProdSelected() === true" class="btn btn-secondary" (click)="onDeselectDeleteAll()">
                <span>{{ "admin.translations.deselectAll" | translate }}</span>
                <span class="icon-Minus-small"></span>
              </button>
              <gt-admin-recursive-translation-changes [data]="existsInProd" path="" (select)="onSelectDelete($event)" [selected]="deleteFromProd">
              </gt-admin-recursive-translation-changes>
            </div>
          </div>
          <div class="add-container" *ngIf="isTranslationManager || isAdmin">
            <button class="btn btn-primary" (click)="onUpdate()">{{ "admin.translations.update" | translate }}</button>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </main>
</section>
