<section class="lessonsLearned-section">
  <div class="text-container">
    <div class="h1">{{ "lessonsLearned.header" | translate }}</div>
    <p>{{ "lessonsLearned.desc" | translate }}</p>
  </div>
  <div class="calendar">
    <div style="grid-column: 2/6; grid-row: 1/3" class="grid__item grid__item--1" (click)="openDialog({ id: '1', color: 'pink' })">
      <div *ngIf="hasBeenViewed('1')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%">
        <!-- <gt-animated-illustration-no-image [illustrationURL]="'/assets/images/calendar/animations/1.svg'" [illustrationStyle]="{ width: '75%', height:'100%', marginLeft: '10%'}"></gt-animated-illustration-no-image> -->
      </div>
    </div>
    <div style="grid-column: 6/10; grid-row: 1/5" class="grid__item grid__item--4" (click)="openDialog({ id: '4', color: 'green' })">
      <div *ngIf="hasBeenViewed('4')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%">
        <!-- <gt-animated-illustration-no-image [illustrationURL]="'/assets/images/calendar/animations/11.svg'" [illustrationStyle]="{ width: '100%', height:'100%', marginLeft: '-0'}"></gt-animated-illustration-no-image> -->
      </div>
    </div>
    <div style="grid-column: 10/12; grid-row: 1/3" class="grid__item grid__item--10" (click)="openDialog({ id: '10', color: 'yellow' })">
      <div *ngIf="hasBeenViewed('10')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%">
        <!-- <gt-animated-illustration-no-image [illustrationURL]="'/assets/images/calendar/animations/28.svg'" [illustrationStyle]="{ width: '85%', height:'100%' }"></gt-animated-illustration-no-image> -->
      </div>
    </div>
    <div style="grid-column: 12/17; grid-row: 1/3" class="grid__item grid__item--5" (click)="openDialog({ id: '5', color: 'pink' })">
      <div *ngIf="hasBeenViewed('5')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%; margin-top: 10%">
        <!-- <gt-animated-illustration-no-image [illustrationURL]="'/assets/images/calendar/animations/20.svg'" [illustrationStyle]="{ width: '90%', height:'100%', marginLeft: '10%'}"></gt-animated-illustration-no-image> -->
      </div>
    </div>
    <div
      style="grid-column: 17/19; grid-row: 1/5"
      class="grid__item grid__item--7"
      (click)="openDialog({ id: '7', color: 'orange', hasVideo: true, noAnim: true })"
    >
      <div *ngIf="hasBeenViewed('7')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%">
        <!-- <gt-animated-illustration-no-image [illustrationURL]="'/assets/images/calendar/animations/24.svg'" [illustrationStyle]="{ width: '100%', height:'100%', marginLeft: '-10%'}"></gt-animated-illustration-no-image> -->
      </div>
    </div>

    <div style="grid-column: 2/4; grid-row: 3/7" class="grid__item grid__item--3" (click)="openDialog({ id: '3', color: 'purple' })">
      <div *ngIf="hasBeenViewed('3')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%">
        <!-- <gt-animated-illustration-no-image [illustrationURL]="'/assets/images/calendar/animations/15.svg'" [illustrationStyle]="{ width: '120%', height:'100%', marginLeft: '-5%'}"></gt-animated-illustration-no-image> -->
      </div>
    </div>
    <div style="grid-column: 4/6; grid-row: 3/5" class="grid__item grid__item--9" (click)="openDialog({ id: '9', color: 'blue' })">
      <div *ngIf="hasBeenViewed('9')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%">
        <!-- <gt-animated-illustration-no-image [illustrationURL]="'/assets/images/calendar/animations/23.svg'" [illustrationStyle]="{ width: '100%', height:'100%', marginLeft: '6%' }"></gt-animated-illustration-no-image> -->
      </div>
    </div>
    <div style="grid-column: 10/14; grid-row: 3/7" class="grid__item grid__item--2" (click)="openDialog({ id: '2', color: 'blue' })">
      <div *ngIf="hasBeenViewed('2')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%">
        <!-- <gt-animated-illustration-no-image [illustrationURL]="'/assets/images/calendar/animations/2.svg'" [illustrationStyle]="{ width: '120%', height:'100%', marginLeft: '-5%'}"></gt-animated-illustration-no-image> -->
      </div>
    </div>
    <div style="grid-column: 14/17; grid-row: 3/5" class="grid__item grid__item--11" (click)="openDialog({ id: '11', color: 'purple', noAnim: true })">
      <div *ngIf="hasBeenViewed('11')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%">
        <!-- <gt-animated-illustration-no-image [illustrationURL]="'/assets/images/calendar/animations/9.svg'" [illustrationStyle]="{ width: '120%', height:'100%', marginLeft: '-10%'}"></gt-animated-illustration-no-image> -->
      </div>
    </div>

    <div style="grid-column: 4/10; grid-row: 5/7" class="grid__item grid__item--8" (click)="openDialog({ id: '8', color: 'orange' })">
      <div *ngIf="hasBeenViewed('8')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%">
        <!-- <gt-animated-illustration-no-image [illustrationURL]="'/assets/images/calendar/animations/19.svg'" [illustrationStyle]="{ width: '150%', height:'100%', marginLeft: '-25%'}"></gt-animated-illustration-no-image> -->
      </div>
    </div>
    <div style="grid-column: 14/16; grid-row: 5/7" class="grid__item grid__item--6" (click)="openDialog({ id: '6', color: 'pink', noAnim: true })">
      <div *ngIf="hasBeenViewed('6')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%">
        <!-- <gt-animated-illustration-no-image [illustrationURL]="'/assets/images/calendar/animations/21.svg'" [illustrationStyle]="{ width: '50%', height:'100%', marginLeft: '30%'}"></gt-animated-illustration-no-image> -->
      </div>
    </div>
    <div
      style="grid-column: 16/19; grid-row: 5/7"
      class="grid__item grid__item--12"
      (click)="openDialog({ id: '12', color: 'yellow', hasVideo: true, noAnim: true })"
    >
      <div *ngIf="hasBeenViewed('12')" style="display: flex; flex-direction: column; justify-content: center; margin: auto; height: 100%">
        <!-- <gt-animated-illustration-no-image [illustrationURL]="'/assets/images/calendar/animations/16.svg'" [illustrationStyle]="{ width: '90%', height:'100%', marginLeft: '-5%'}"></gt-animated-illustration-no-image> -->
      </div>
    </div>
  </div>
</section>
