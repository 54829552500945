import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TestemonialComponent } from './components/testemonial/testemonial.component';
import { AnimatedIllustrationComponent } from './components/animated-illustration/animated-illustration.component';
import { AnimatedUnderlineComponent } from './components/animated-underline/animated-underline.component';
import { AnimatedRoundedMarkerComponent } from './components/animated-rounded-marker/animated-rounded-marker.component';
import { AnimatedStrokesAroundComponent } from './components/animated-strokes-around/animated-strokes-around.component';
import { SubnavigationComponent } from './components/subnavigation/subnavigation.component';
import { TranslateModule } from '@ngx-translate/core';
import { SubpageHeroComponent } from './components/subpage-hero/subpage-hero.component';
import { RouterModule } from '@angular/router';
import { RoundCardsComponent } from './components/round-cards/round-cards.component';
import { CenteredCardComponent } from './components/centered-card/centered-card.component';
import { ContentSectionComponent } from './components/content-section/content-section.component';
import { RoundListCardComponent } from './components/round-list-card/round-list-card.component';
import { TilesListComponent } from './components/tiles-list/tiles-list.component';
import { WhatsNextComponent } from './components/whats-next/whats-next.component';
import { StrengthsExpertsComponent } from './components/strengths-experts/strengths-experts.component';
import { HintComponent } from './components/hint/hint.component';
import { TagsComponent } from './components/tags/tags.component';
import { AnimatedIllustrationNoImageComponent } from './components/animated-illustration-no-image/animated-illustration-no-image.component';
import { CustomLinkComponent } from './components/custom-link/custom-link.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminLinkComponent } from './components/admin-link/admin-link.component';
import { AdminUserTableComponent } from './components/admin-user-table/admin-user-table.component';
import { HighlightPipe } from './services/highlight.pipe';
import { AdminRecursiveTranslationComponent } from './components/admin-recursive-translation/admin-recursive-translation.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { AdminRecursiveTranslationChangesComponent } from './components/admin-recursive-translation-changes/admin-recursive-translation-changes.component';
import { CharacterHighlightPipe } from './services/character-highlight.pipe';
import { AdminBackButtonComponent } from './components/admin-back-button/admin-back-button.component';
import { AnimatedStrokesTopRightComponent } from './components/animated-strokes-top-right/animated-strokes-top-right.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { MatIconModule } from '@angular/material/icon';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { FileDropComponent } from './components/file-drop/file-drop.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { SurveyDialogComponent } from './components/survey/survey-dialog/survey-dialog.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatRadioModule } from '@angular/material/radio';
import { StarRatingComponent } from './components/survey/star-rating/star-rating.component';
import { FilterPipe } from './services/filter.pipe';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { VideoSectionComponent } from './components/video-section/video-section.component';
import { VideoplayerComponent } from './components/calendar/videoplayer/videoplayer.component';
import { GifplayerComponent } from './components/gifplayer/gifplayer.component';
import { VideoDialogComponent } from './components/video-dialog/video-dialog.component';
import { WizardSidenavComponent } from './components/wizard-sidenav/wizard-sidenav.component';
import { WizardCompletionBadgeComponent } from './components/wizard-completion-badge/wizard-completion-badge.component';
import { AudioPlayerComponent } from './components/audio-player/audio-player.component';
import { PeopleHighlightQuoteComponent } from './components/people-highlight-quote/people-highlight-quote.component';

const components = [
  TestemonialComponent,
  AnimatedIllustrationComponent,
  AnimatedUnderlineComponent,
  AnimatedRoundedMarkerComponent,
  AnimatedStrokesAroundComponent,
  SubnavigationComponent,
  SubpageHeroComponent,
  RoundCardsComponent,
  CenteredCardComponent,
  ContentSectionComponent,
  VideoSectionComponent,
  RoundListCardComponent,
  TilesListComponent,
  WhatsNextComponent,
  StrengthsExpertsComponent,
  HintComponent,
  TagsComponent,
  AnimatedIllustrationNoImageComponent,
  CustomLinkComponent,
  AdminLinkComponent,
  AdminUserTableComponent,
  AdminRecursiveTranslationComponent,
  AdminRecursiveTranslationChangesComponent,
  AdminBackButtonComponent,
  AnimatedStrokesTopRightComponent,
  LoadingIndicatorComponent,
  FileDropComponent,
  VideoplayerComponent,
  GifplayerComponent,
  WizardSidenavComponent,
  WizardCompletionBadgeComponent,
  AudioPlayerComponent,
  PeopleHighlightQuoteComponent,
];

const modules = [
  TranslateModule,
  FormsModule,
  ReactiveFormsModule,
  MatTooltipModule,
  MatMenuModule,
  MatSnackBarModule,
  MatProgressSpinnerModule,
  MatExpansionModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatTabsModule,
  NgxDocViewerModule,
  ClipboardModule,
  MatIconModule,
  NgxFileDropModule,
  MatDialogModule,
  MatStepperModule,
  MatRadioModule,
  MatDatepickerModule,
  MatNativeDateModule,
];

@NgModule({
  declarations: [
    components,
    HighlightPipe,
    CharacterHighlightPipe,
    FilterPipe,
    StrengthsExpertsComponent,
    LoadingIndicatorComponent,
    SurveyDialogComponent,
    StarRatingComponent,
    VideoDialogComponent,
    WizardCompletionBadgeComponent,
  ],
  imports: [modules, RouterModule, CommonModule],
  exports: [components, modules, HighlightPipe, FilterPipe, CharacterHighlightPipe],
})
export class SharedModule {}
