import { IEsrjCompletedStep } from '../../interfaces/IEsrjCompeltedStep';
import { JourneyMultipleChoiceItem } from '../../interfaces/IJourneyMultipleChoice';
import { JourneyRatingData } from '../../interfaces/IJourneyRating';
import { IUserOwnDilemmaInput } from '../../interfaces/IUserInput';

export class UpdateESRJCompletedSteps {
  static readonly type = '[EthicalSelfReflectionJourney] Update Completed Steps';
  constructor(public newStep: IEsrjCompletedStep) {}
}

export class UpdateESRJAspectsOfEthics {
  static readonly type = '[EthicalSelfReflectionJourney] Update Selected Aspects Of Ethics';
  constructor(public aspectsOfEthics: JourneyMultipleChoiceItem[]) {}
}

export class UpdateESRJEthicalSelectedDilemma {
  static readonly type = '[EthicalSelfReflectionJourney] Update Selected Dilemma';
  constructor(public ethicalSelectedDilemma: JourneyMultipleChoiceItem[]) {}
}

export class UpdateESRJEthicalBehaviorResponses {
  static readonly type = '[EthicalSelfReflectionJourney] Update Ethical Behavior Responses';
  constructor(public ethicalBehaviorResponses: JourneyRatingData) {}
}

export class UpdateESRJEthicalMindfulResponses {
  static readonly type = '[EthicalSelfReflectionJourney] Update Ethical Mindful Responses';
  constructor(public ethicalMindfulResponses: JourneyRatingData) {}
}

export class UpdateESRJEthicalLeadershipResponses {
  static readonly type = '[EthicalSelfReflectionJourney] Update Ethical Leadership Responses';
  constructor(public ethicalLeadershipResponses: JourneyRatingData) {}
}

export class UpdateESRJEthicalClimateResponses {
  static readonly type = '[EthicalSelfReflectionJourney] Update Ethical Climate Responses';
  constructor(public ethicalClimateResponses: JourneyRatingData) {}
}

export class UpdateESRJEthicalOwnDilemma {
  static readonly type = '[EthicalSelfReflectionJourney] Update Ethical Own Dilemma Responses';
  constructor(public ethicalOwnDilemma: IUserOwnDilemmaInput) {}
}

export class UpdateESRJEthicalSelectedDilemmaTextInput {
  static readonly type = '[EthicalSelfReflectionJourney] Update Ethical Selected Dilemma Text Responses';
  constructor(public ethicalSelectedDilemmaTextInput: IUserOwnDilemmaInput) {}
}

export class ResetESRJStatus {
  static readonly type = '[EthicalSelfReflectionJourney] Reset Ethical Self-Reflection Record';
}

export class ResetESRJSteps {
  static readonly type = '[EthicalSelfReflectionJourney] Reset Ethical Self-Reflection Steps';
  constructor(public newStepsArray: IEsrjCompletedStep[]) {}
}
