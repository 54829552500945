<div class="admin-dialog">
  <div class="content">
    <div class="dialog-header">
      <h4 class="heading strong">{{ "admin.translations.deleteItemDialog.heading" | translate }}</h4>
    </div>
    <div>
      <p [innerHTML]="'admin.translations.deleteItemDialog.message' | translate"></p>
    </div>
    <div class="action-area">
      <button class="btn btn-secondary" (click)="onClose(false)">{{ "admin.translations.deleteItemDialog.cancelButton" | translate }}</button>
      <button class="btn btn-danger" (click)="onClose(true)">{{ "admin.translations.deleteItemDialog.confirmButton" | translate }}</button>
    </div>
  </div>
</div>
