import { Component, OnInit, Input } from '@angular/core';
import { AnimatedIllustrationStyle } from '../../models/animated-illustration-style.model';

@Component({
  selector: 'gt-animated-rounded-marker',
  templateUrl: './animated-rounded-marker.component.html',
  styleUrls: ['./animated-rounded-marker.component.scss'],
})
export class AnimatedRoundedMarkerComponent implements OnInit {
  illustrationURL = '/assets/images/growthtalks_circle_illustration.svg';
  @Input() illustrationStyle: AnimatedIllustrationStyle = {
    top: '-50%',
    left: '-3%',
    width: '105%',
    height: '2.5em',
  };

  constructor() {}

  ngOnInit(): void {}
}
