import { IUserData } from '../interfaces/IUserData';

export class UserOperations {
  public static authServiceUserDataToUserData(authServiceUserData): IUserData {
    const data: IUserData = {
      aud: authServiceUserData.aud || '',
      company: authServiceUserData['custom:company'],
      country: authServiceUserData['custom:country'],
      exp: authServiceUserData.exp,
      given_name: authServiceUserData['given_name'] || '',
      iat: authServiceUserData.iat,
      iss: authServiceUserData.iss,
      family_name: authServiceUserData['family_name'] || '',
      email: authServiceUserData.email || '',
      nickname: authServiceUserData.nickname || '',
      nonce: authServiceUserData.nonce,
      orgCode: authServiceUserData['custom:org_code'],
      gid: authServiceUserData['custom:gid'],
    };
    return data;
  }
}
