<div class="search-section" [class.dark]="isLeadersView">
  <div class="search-input-container">
    <mat-form-field class="search-input" appearance="outline" subscriptSizing="dynamic">
      <input matInput [placeholder]="'search.placeholder' | translate" aria-label="Search" #searchInput [(ngModel)]="searchTerm" />
      <button mat-button matPrefix [matMenuTriggerFor]="searchMenu" class="search-input-btn">
        <span>{{ "search.categories." + selectedCategory | translate }}</span
        ><span class="material-icons">expand_more</span>
      </button>
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
    <mat-menu #searchMenu="matMenu" [class]="'search-menu'">
      <button mat-menu-item *ngFor="let category of searchCategories" (click)="changeCategory(category)">
        {{ "search.categories." + category | translate }}
      </button>
    </mat-menu>
  </div>
  <div *ngIf="!searchTerm">
    <div>
      <div class="title">{{ "search.recommendations.title" | translate | uppercase }}</div>
      <div *ngFor="let item of searchSuggestions">
        <a (click)="searchSuggestionClicked(item.value)">{{ item.value }}</a>
      </div>
    </div>
    <div class="recommnedations-column">
      <div class="categories">
        <div class="title">{{ "search.recommendations.pickCategory" | translate | uppercase }}</div>
        <ul>
          <li>
            <div class="search-category">{{ "search.categories." + SEARCH_CATEGORIES.general_view | translate }}</div>
          </li>
          <li>
            <div class="search-category">{{ "search.categories." + SEARCH_CATEGORIES.leaders_view | translate }}</div>
          </li>
        </ul>
      </div>
      <div>
        <div>
          <span class="icon material-icons">lightbulb</span>
          <span class="title">{{ "search.tip.title" | translate | uppercase }}</span>
        </div>
        <span>{{ "search.tip.text" | translate }}</span>
      </div>
    </div>
  </div>
  <div class="search-results" *ngIf="searchResults.length > 0">
    <div *ngIf="selectedCategory === SEARCH_CATEGORIES.all || selectedCategory === SEARCH_CATEGORIES.general_view">
      <div class="search-category">General View</div>
      <div *ngFor="let item of searchResults | filter: 'section' : ['general']">
        <gt-custom-link [doTracking]="false" [url]="item.url" (linkClicked)="searchTermClicked()">
          <span [innerHTML]="item.value | highlight: searchTerm"></span
        ></gt-custom-link>
      </div>
    </div>
    <div *ngIf="selectedCategory === SEARCH_CATEGORIES.all || selectedCategory === SEARCH_CATEGORIES.leaders_view">
      <div class="search-category">Leaders View</div>
      <div *ngFor="let item of searchResults | filter: 'section' : ['leaders']">
        <gt-custom-link [doTracking]="false" [url]="item.url" (linkClicked)="searchTermClicked()">
          <span [innerHTML]="item.value | highlight: searchTerm"></span
        ></gt-custom-link>
      </div>
    </div>
  </div>
  <div>
    <div class="no-results" *ngIf="searchTerm && !searchResults.length">
      <p>We didn't find what you are looking for.</p>
      <p>You could try some of the following:</p>
      <div *ngFor="let item of searchSuggestions">
        <a (click)="searchSuggestionClicked(item.value)">{{ item.value }}</a>
      </div>
    </div>
    <div *ngIf="relatedTopics.length > 0 && searchResults.length === 0">
      <div class="title">{{ "Related topics" | translate | uppercase }}</div>
      <div *ngFor="let item of relatedTopics">
        <gt-custom-link [doTracking]="false" [url]="item.url" (linkClicked)="searchTermClicked()">
          <span [innerHTML]="item.value | characterHighlight: searchTerm"></span
        ></gt-custom-link>
      </div>
    </div>
  </div>
</div>
