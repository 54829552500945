import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './new-auth.service';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TokenInjector implements HttpInterceptor {
  constructor(private auth: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('authproxy')) {
      return next.handle(request);
    } else if (
      (request.url.includes('execute-api') || request.url.includes('siemens') || request.url.includes('localhost:3000')) &&
      !request.url.startsWith('https://dcapis.siemens') &&
      !request.url.startsWith('https://translations.') &&
      !request.url.startsWith(environment.translationServiceUrl)
    ) {
      return this.auth.getIdToken().pipe(
        switchMap(token => {
          if (!!token) {
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${token}`,
              },
            });
          }
          return next.handle(request);
        }),
      );
    } else {
      return next.handle(request);
    }
  }
}
