import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BRAND_BUILDER_CONFIG } from 'src/app/brandbuilder/brand-builder-config';
import { environment } from 'src/environments/environment';
import { BrandBuilderData, ICategory, IStatementModel, ITrait, StatementAdjective, StatementSelection, StatementStatus } from '../interfaces/IBrandbuilderData';
import { Option } from 'src/app/shared/interfaces/IBrandbuilderData';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class BrandbuilderService {
  data: BrandBuilderData;
  isLoading = true;

  get options() {
    return {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('id_token')}`,
      },
    };
  }

  constructor(
    private http: HttpClient,
    private _snackBar: MatSnackBar,
  ) {}

  async load() {
    this.isLoading = true;
    // console.log('fetching bb data ', this.options);
    let data: BrandBuilderData;
    try {
      const result = await this.http.get(environment.backendUrl + 'brand-builder', this.options).toPromise();
      console.log('result', result);
      data = result as BrandBuilderData;
    } catch (err) {
      console.log(err);
      if (err.status !== 404) {
        this._snackBar.open(err.message || 'Could not load saved data! Creating a new statement now will overwrite any existing statements!', undefined, {
          duration: 5000,
        });
      }
    }
    // const data: BrandBuilderData = JSON.parse(localStorage.getItem(LS_KEY));
    if (data) {
      this.data = data;
    } else {
      // init
      this.data = this.getDefault();
    }
    this.isLoading = false;
  }

  async reset() {
    this.data = this.getDefault();
    await this.save();
  }

  private getDefault() {
    return {
      strengths: [],
      strengthsComment: undefined,
      drivers: [],
      driversComment: undefined,
      expertise: {},
      expertiseComment: undefined,

      statementSelection: undefined,
      statementOption: 0,
      statement: undefined,

      introduction: 0,
      createBrand: false,
      doMore: false,
    };
  }

  getStatus(): StatementStatus {
    const status: Partial<StatementStatus> = {};
    const keys = ['introduction', 'brand', 'drivers', 'strengths', 'expertise', 'statement', 'do-more'];
    const average = [];
    keys.forEach((key, index) => {
      const prg = this.getProgress(key);
      status[key] = {
        progress: prg,
        active: index === 0 ? true : status[keys[index - 1]].done,
        done: prg === 100,
      };
      if (key !== 'brand') {
        // exclude brand as it is the parent of drivers, strengths, expertise and statement
        average.push(prg);
      }
      if (key === 'drivers') {
        status.drivers.active = status.brand.active; // drivers should be enabled although brand not yet completed as is first step
      }
    });
    status.progress = Math.min(Math.round(average.reduce((a, b) => a + b, 0) / average.length), 100);
    return status as any;
  }

  async save() {
    // localStorage.setItem(LS_KEY, JSON.stringify(this.data));
    try {
      await this.http.post(environment.backendUrl + 'brand-builder', this.data, this.options).toPromise();
    } catch (err) {
      this._snackBar.open(err.message || 'Failed to save data!', undefined, { duration: 2000 });
    }
  }

  /**
   * Returns the progress for the specified category as a percentage
   * @param id Category.id
   */
  getProgress(id: string): number {
    switch (id) {
      case 'introduction':
        // One steps in introduction: data.introduction / 1 steps
        return this.data.introduction ? Math.min(this.data.introduction * 100, 100) : 0;
      case 'do-more':
        // Do-more is set => 100% otherwise 0%
        return this.data.doMore ? 100 : 0;
      case 'brand':
        // All brand sections combined: drivers + strengths + expertise + statement
        return Math.min(
          Math.round((this.getProgress('drivers') + this.getProgress('strengths') + this.getProgress('expertise') + this.getProgress('statement')) / 4),
          100,
        );
      case 'drivers':
        // Selected items / (5 in total)
        return Math.min((this.data[id].length / 5) * 100, 100);
      case 'strengths':
        // Selected items / (7 in total)
        return Math.min((this.data[id].length / 7) * 100, 100);
      case 'expertise':
        // Two expertise fields one equals 50%
        return (this.data.expertise.field1 ? 50 : 0) + (this.data.expertise.field2 ? 50 : 0);
      case 'statement':
        // Statement is set => 100% otherwise 0%
        return this.data.statement ? 100 : 0;
    }
  }

  getBrandbuilderConfig(): ICategory[] {
    return BRAND_BUILDER_CONFIG;
  }

  getStatementModel(): IStatementModel {
    const strengthTraits = this.data.strengths.map(this.conv);
    const driverTraits = this.data.drivers.map(this.conv);

    let selection: StatementSelection = this.data.statementSelection;
    if (!selection) {
      selection = this.generateRandomSelection(strengthTraits, driverTraits);
    }

    // select adjectives of selection
    this.setSelection(strengthTraits, selection.strengths);
    this.setSelection(driverTraits, selection.drivers);

    return {
      strengths: strengthTraits,
      drivers: driverTraits,
      selection,
    };
  }

  private conv(trait: Option): ITrait {
    return {
      id: trait.id,
      adjectives: trait.adjectives.map(title => ({ title, trait: trait.id, selected: false })),
    };
  }

  private setSelection(traits: ITrait[], adjs: StatementAdjective[]) {
    for (const adj of adjs) {
      const trait = traits.find(t => t.id === adj.trait);
      if (trait) {
        trait.selected = true;
        trait.adjectives.find(a => adj.title === a.title).selected = true;
      } else {
        // TODO trait was deselected, maybe choose a new random adjective?
      }
    }
  }

  /**
   * Generates a random selection for the statement with the specified strengths and drivers
   */
  private generateRandomSelection(strengths: ITrait[], drivers: ITrait[]): StatementSelection {
    const strengthsCopy = strengths.slice(); // keep original
    const driversCopy = drivers.slice(); // keep original
    const selection: StatementSelection = {
      strengths: [this.getRandomAdjective(strengthsCopy), this.getRandomAdjective(strengthsCopy)],
      drivers: [this.getRandomAdjective(driversCopy)],
    };
    return selection;
  }

  private getRandomAdjective(arr: ITrait[]): StatementAdjective {
    const trait = this.selectRandom(arr);
    const adj = this.selectRandom(trait.adjectives.slice());
    return {
      title: adj.title,
      trait: trait.id,
    };
  }

  private selectRandom<T>(arr: T[]): T {
    const index = Math.random() * arr.length;
    return arr.splice(index, 1)[0];
  }
}
