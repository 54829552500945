<div *ngFor="let item of data | keyvalue; let i = index">
  <mat-expansion-panel *ngIf="isObject(item.value)" class="translations-expand-container mat-expansion-panel-admin" (closed)="onClose(i)" (opened)="onOpen(i)">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span class="strong">{{ item.key }}</span>
        <span *ngIf="openedIndexes.includes(i)" (click)="onToggleHideOrig($event, i)" class="toggle-original strong">{{
          "admin.translations." + (!hideOrigIndexes.includes(i) ? "hide" : "show") | translate
        }}</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <div class="content">
        <gt-admin-recursive-translation
          [data]="item.value"
          [orig]="orig"
          [hideOrig]="!hideOrigIndexes.includes(i)"
          [canEdit]="canEdit"
          [path]="path + '.' + item.key"
          [currentLang]="currentLang"
          (onChange)="passEvent($event)"
          (topic)="onAddTopic($event)"
          (item)="onAddItem($event)"
          (removeItem)="passEventRemoveItem($event)"
          (removeTopic)="passEventRemoveTopic($event)"
        ></gt-admin-recursive-translation>
        <div class="add-container" *ngIf="canEdit">
          <button class="btn btn-danger" (click)="onRemoveTopic(path + '.' + item.key, item.value)">{{ "admin.translations.removeTopic" | translate }}</button>
          <button class="btn btn-primary" (click)="onAddItem(path + '.' + item.key)">{{ "admin.translations.addItem" | translate }}</button>
          <button class="btn btn-primary" (click)="onAddTopic(path + '.' + item.key)">{{ "admin.translations.addTopic" | translate }}</button>
        </div>
      </div>
    </ng-template>
  </mat-expansion-panel>

  <div *ngIf="!isObject(item.value)" class="translation-edit-container">
    <!-- <input type="text" [value]="key" (change)="onRename(path + '.' + key, $event)"/> -->
    <span class="key-name">{{ item.key }}</span>
    <span *ngIf="hideOrig" class="original-translation">{{ getOriginalValue(path + "." + item.key) }}</span>
    <mat-form-field class="form-field value-input">
      <textarea
        matInput
        type="text"
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1"
        [value]="item.value"
        (change)="onValueChange(path + '.' + item.key, $event)"
      ></textarea>
      <span matSuffix class="input-suffix-icon">
        @if (getOriginalValue(path + "." + item.key)) {
          @if (isTranslating[i]) {
            <span>
              <mat-spinner diameter="18"></mat-spinner>
            </span>
          } @else {
            @if (currentLang !== mygLanguageCode.EN && !item.value) {
              <span
                class="icon-Chat icon"
                [matTooltip]="'admin.translations.translationTooltip' | translate"
                (click)="translateFromEnglish(path + '.' + item.key, i)"
              ></span>
            }
          }
        }
      </span>
    </mat-form-field>
    <span *ngIf="canEdit" class="icon-Trash mouse-pointer" (click)="onValueDelete(path + '.' + item.key)"></span>
  </div>
</div>
