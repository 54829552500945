import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { gsap } from 'gsap';
import { TrackingService } from 'src/app/shared/services/tracking.service';
import { Calendar22DialogComponent } from './calendar22-dialog/calendar22-dialog.component';
import { TRACKING_ITEM } from 'src/app/shared/enums/tracking_item.enum';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';
import { IUserConfig } from 'src/app/shared/interfaces/IUserConfig';
import { UserState } from 'src/app/shared/state-management/user/user.state';
import { UpdateUserConfigCalendar } from 'src/app/shared/state-management/user/user.actions';

@Component({
  selector: 'gt-calendar22',
  templateUrl: './calendar22.component.html',
  styleUrls: ['./calendar22.component.scss'],
})
export class Calendar22Component implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('animContainer') animContainer: ElementRef;
  @Select(UserState.userConfig) userConfig$: Observable<IUserConfig>;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  tl: gsap.core.Timeline;
  viewedItems: string[];

  constructor(
    public dialog: MatDialog,
    private trackingService: TrackingService,
    public translate: TranslateService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.getUserConfig();
  }

  async ngAfterViewInit(): Promise<void> {
    const animPathes1 = Array.from(
      this.animContainer.nativeElement.querySelectorAll('#initialAnim > path, .visited > .borderVisited, .visited > g>.animation'),
    );
    this.tl = gsap.timeline();
    this.tl.to(animPathes1, { opacity: 1 });
    this.tl.fromTo(
      animPathes1,
      {
        drawSVG: '0',
      },
      {
        duration: 1,
        drawSVG: '100%',
      },
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.tl?.kill();
  }

  private getUserConfig(): void {
    this.userConfig$
      .pipe(
        tap(userConfig => {
          this.viewedItems = userConfig.calendar['202212'] ? userConfig.calendar['202212'].map(String) : [];
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  public clicked(step: string): void {
    this.adjustClasses(step);
    this.trackClick(step);
    this.saveViewedItem(step);
    this.animateStep(step);
    this.openDialog(step);
  }

  private openDialog(step: string): void {
    const dialogRef = this.dialog.open(Calendar22DialogComponent, {
      width: '100vw',
      maxHeight: '900px',
      panelClass: 'calendar22Dialog',
      data: {
        step: step,
      },
    });
  }

  private animateStep(step: string): void {
    const querySelector = '#box' + step + ' > .border, #box' + step + ' >.steps, #box' + step + ' >g>.animation';
    const animPathes2 = Array.from(this.animContainer.nativeElement.querySelectorAll(querySelector));
    this.tl.staggerFromTo(
      animPathes2,
      0.2,
      {
        drawSVG: 0,
      },
      {
        drawSVG: '100%',
        ease: 'linear',
      },
      0.2,
    );
  }

  private saveViewedItem(step: string): void {
    if (!this.viewedItems.includes(step)) {
      this.viewedItems.push(step);
      this.store.dispatch(new UpdateUserConfigCalendar({ ['202212']: this.viewedItems.map(Number) }));
    }
  }

  private adjustClasses(step: string): void {
    const activeBox = document.getElementsByClassName('box active')[0];
    if (activeBox) {
      activeBox.classList.add('visited');
      activeBox.classList.remove('active');
    }
    document.getElementById('box' + step).classList.remove('visited');
    document.getElementById('box' + step).classList.toggle('active');
  }

  private trackClick(step: string): void {
    this.trackingService.addTrackingItem(TRACKING_ITEM.calendar, 'day:' + step);
  }
}
