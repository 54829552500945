import { UIComponent } from '@webfruits/core';

/******************************************************************
 * AbstractUIController
 *
 *
 *****************************************************************/

export class UIController<T extends HTMLElement = HTMLElement> extends UIComponent {
  /******************************************************************
   * Properties
   *****************************************************************/

  // no properties yet

  /******************************************************************
   * Constructor
   *****************************************************************/

  constructor(view: T) {
    super(view as T);
    requestAnimationFrame(() => {
      this.updateStyles();
    });
  }

  /******************************************************************
   * Public Methodes
   *****************************************************************/

  public getElementByClass(className: string): HTMLElement {
    return this.view.querySelectorAll('.' + className)[0] as HTMLElement;
  }

  public getElementsByClass(className: string): HTMLElement[] {
    return Array.from(this.view.querySelectorAll('.' + className)) as HTMLElement[];
  }

  public getElementByTag(name: string): HTMLElement {
    return this.view.querySelectorAll(name)[0] as HTMLElement;
  }

  public getElementsByTag(name: string): HTMLElement[] {
    return Array.from(this.view.querySelectorAll(name)) as HTMLElement[];
  }

  public getElementByID(id: string): HTMLElement {
    return this.view.querySelectorAll('#' + id)[0] as HTMLElement;
  }

  /******************************************************************
   * Private Methodes
   *****************************************************************/

  // no private methodes yet

  /******************************************************************
   * Events
   *****************************************************************/

  // no events yet
}
