<section class="sgt-testemonials sgt-slider" [ngClass]="sectionBackground">
  <div class="header-container">
    <div class="header" id="header">
      <span class="h2" *ngIf="title1">{{ title1 | translate }}</span>
      <gt-animated-underline [strokeType]="strokeType" [color]="animationColor" *ngIf="titleAnim"
        ><span class="h2"> {{ titleAnim | translate }}</span></gt-animated-underline
      >
      <span class="h2" *ngIf="title2">{{ title2 | translate }}</span>
    </div>
    <p *ngIf="desc">{{ desc | translate }}</p>
  </div>

  <div class="carousel-container" role="region">
    <div class="carousel" aria-roledescription="carousel" aria-labelledby="header" aria-live="polite">
      <button type="button" *ngIf="items.length > 1" class="btn btn-hero previous" aria-label="Previous slide" (click)="changeSlide('previous')">
        <span class="icon icon-Arrowhead-left"></span>
      </button>
      <div
        class="slide"
        *ngFor="let item of items; let i = index"
        [class.active]="i === activeSlide"
        [ngClass]="cardBackground"
        [id]="'slide' + i"
        [attr.aria-hidden]="i !== activeSlide"
        role="group"
        aria-roledescription="slide"
        [attr.aria-label]="'slide' + (i + 1)"
      >
        <div
          class="photo-container"
          style.background-image="url('{{ item.img }}')"
          style="background-repeat: no-repeat; background-position: center; background-size: cover"
        ></div>
        <div class="content">
          <div>
            <blockquote [class.long]="translate.instant(item.text).length > 350" [class.purple]="animationColor === '#805cff'">
              <span class="block-text" [innerHTML]="item.text | translate"></span>
            </blockquote>
            <p>{{ item.name | translate }}<br />{{ item.topic | translate }} <br />{{ item.country | translate }}</p>
          </div>
          <div class="illustration-container" *ngIf="hasIllustration">
            <img [src]="'/assets/images/computer-clock-illustration.svg'" />
          </div>
        </div>
      </div>
      <button type="button" *ngIf="items.length > 1" class="btn btn-hero next" aria-label="Next slide" (click)="changeSlide('next')">
        <span class="icon icon-Arrowhead-right"></span>
      </button>
    </div>
  </div>
</section>
