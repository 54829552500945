import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { TRACKING_ITEM } from 'src/app/shared/enums/tracking_item.enum';
import { ISurveyConfig } from 'src/app/shared/interfaces/ISurveyConfig';
import { QUESTION_TYPE } from 'src/app/shared/enums/question_type.enum';
import { SurveyService } from 'src/app/shared/services/survey.service';
import { TrackingService } from 'src/app/shared/services/tracking.service';
import { UpdateUserConfigSurvey } from 'src/app/shared/state-management/user/user.actions';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'gt-survey-dialog',
  templateUrl: './survey-dialog.component.html',
  styleUrls: ['./survey-dialog.component.scss'],
})
export class SurveyDialogComponent implements OnInit, OnDestroy {
  public surveyForm: FormGroup;
  public questionTypeEnum = QUESTION_TYPE;
  public questionsFormArray: FormArray;
  private subs: Subscription[] = [];

  constructor(
    public dialogRef: MatDialogRef<SurveyDialogComponent>,
    private surveyService: SurveyService,
    private router: Router,
    private trackingService: TrackingService,
    public translateService: TranslateService,
    private fb: FormBuilder,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public data: ISurveyConfig,
  ) {
    this.surveyService.updateShowSurveyBtn(false);
    this.setUpForm();
    this.questionsFormArray = this.surveyForm.controls['questions'] as FormArray;
  }

  ngOnInit(): void {
    this.trackLanguageChanges();
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => {
      sub.unsubscribe();
    });
  }

  private trackLanguageChanges(): void {
    this.subs.push(
      this.translateService.onLangChange
        .pipe(
          tap(val => {
            if (val.lang !== 'en' && val.lang !== 'de') {
              this.hideSurvey('later');
            }
          }),
        )
        .subscribe(),
    );
  }

  private setUpForm(): void {
    this.surveyForm = this.fb.group({
      questions: this.fb.array(this.data.questions.map(item => this.fb.group({ questionId: item.questionId, type: item.type, answer: null, comment: null }))),
    });
  }

  public hideSurvey(option: 'later' | 'no'): void {
    this.trackClick(option);
    this.surveyService.updateShowSurveyBtn(true);
    this.store.dispatch(new UpdateUserConfigSurvey({ [this.data.id]: { status: option } }));
    this.dialogRef.close();
  }

  public submitSurvey(): void {
    this.trackClick('Survey:submit');
    this.store.dispatch(new UpdateUserConfigSurvey({ [this.data.id]: { status: 'completed' } }));
    const answers: Record<string, string | number | boolean> = {};
    const comments: Record<string, string> = {};
    this.surveyForm.value.questions.forEach(({ questionId, type, answer, comment }) => {
      answers[questionId] = type === QUESTION_TYPE.yes_no ? answer === 'yes' : answer;
      comments[questionId] = comment;
    });
    const surveyAnswer = {
      id: this.data.id,
      answers: answers,
      comments: comments,
      language: this.translateService.currentLang,
    };
    this.surveyService.postSurveyAnswer(surveyAnswer).subscribe();
  }

  public trackClick(detail: string): void {
    this.trackingService.addTrackingItem(TRACKING_ITEM.button, 'Survey:' + detail + '[from]:' + this.trackingService.cleanRoute(this.router.url));
  }
}
