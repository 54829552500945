import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gt-tiles-list',
  templateUrl: './tiles-list.component.html',
  styleUrls: ['./tiles-list.component.scss'],
})
export class TilesListComponent {
  @Input() sectionContent: {
    title1?: string;
    desc?: string;
    titleAnim?: string;
    tiles: { title: string; imgAltText?: string; desc: string; linkUrl?: string; btnText?: string; linkText?: string; linkTextUrl?: string; imgUrl: string }[];
  };

  @Input() layout: 'cards' | 'columns';
  @Input() animationColor: string;
  @Input() fragment: string = '';

  @Input() theme: 'dark' | 'sand';

  constructor(public translate: TranslateService) {}

  isLarge(txt: string): boolean {
    return this.stripHtml(txt).length > 20;
  }

  stripHtml(html: string): string {
    let tmp = document.createElement('div');
    tmp.innerHTML = html;
    tmp.style.display = 'none';
    return tmp.textContent || tmp.innerText || '';
  }
}
