import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ILanguage } from 'src/app/shared/services/language.service';

@Component({
  selector: 'gt-visibility-dialog',
  templateUrl: './visibility-dialog.component.html',
  styleUrls: ['./visibility-dialog.component.scss'],
})
export class VisibilityDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<VisibilityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { languages: ILanguage[] },
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this.dialogRef.close(this.data);
  }

  onChange(index: number, event: any): void {
    this.data.languages[index].visible = event.checked;
  }
}
