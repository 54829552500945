import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Scroll } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ILanguage, LanguageService } from 'src/app/shared/services/language.service';
import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject, Subscription } from 'rxjs';
import { SearchService } from 'src/app/shared/services/search.service';
import { environment } from 'src/environments/environment';
import { TrackingService } from 'src/app/shared/services/tracking.service';
import { LoadingIndicatorService } from 'src/app/shared/services/loading-indicator.service';
import { TRACKING_ITEM } from 'src/app/shared/enums/tracking_item.enum';
import { UserRolesEnum } from 'src/app/shared/enums/user_roles.enum';
import { Select } from '@ngxs/store';
import { UserState } from 'src/app/shared/state-management/user/user.state';
import { MYG_LANGUAGE_CODE } from 'src/app/shared/enums/language-code.enum';

@Component({
  selector: 'gt-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Select(UserState.roles) roles$: Observable<UserRolesEnum[]>;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  public TRACKING_ITEM = TRACKING_ITEM;

  currentLang: ILanguage = { name: 'English', code: MYG_LANGUAGE_CODE.EN, rssCode: '', visible: true };
  languages: ILanguage[] = [];
  mobileNavOpen = false;
  isAdmin = false;
  isAdminRoute = false;
  adminBanner = 'Admin';
  searchTerm = null;
  protected showSearch = false;
  isProd = environment.production;

  siemensImage = 'deepblue';

  headerLinks = [
    { link: '/self-reflection', name: 'self-reflection' },
    { link: '/learning', name: 'learning' },
    { link: '/career', name: 'career' },
    { link: '/growth-talks', name: 'growth-talks' },
    { link: '/help', name: 'help' },
  ];

  subs: Subscription[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    public languageService: LanguageService,
    private searchService: SearchService,
    private trackingService: TrackingService,
    private loadingIndicator: LoadingIndicatorService,
  ) {
    this.router.events
      .pipe(
        tap(event => {
          if (event instanceof NavigationEnd) {
            this.isAdminRoute = event.url.startsWith('/admin');
          } else if (event instanceof Scroll && event.routerEvent instanceof NavigationEnd) {
            this.isAdminRoute = event.routerEvent.url.startsWith('/admin');
          }
        }),
      )
      .subscribe();
  }

  async ngOnInit(): Promise<void> {
    this.languages = (await this.languageService.getLanguages()).filter(s => s.visible);

    this.subs.push(
      this.route.queryParams.pipe(debounceTime(200)).subscribe(params => {
        this.loadingIndicator.updateLoadingIndicatorStatus(true);
        if (params['lang']) {
          const langCode = this.languages.filter(s => s.code === params['lang']);
          const defaultObject = { name: 'English', code: MYG_LANGUAGE_CODE.EN, rssCode: '', visible: true };
          this.currentLang = langCode && langCode.length ? langCode[0] : defaultObject;
          this.translate.use(this.currentLang.code);
          this.loadingIndicator.updateLoadingIndicatorStatus(false);
        } else {
          this.translate.use('en');
          this.loadingIndicator.updateLoadingIndicatorStatus(false);
        }
      }),
    );
    this.subs.push(
      this.searchService.searchTermCurrent.subscribe(value => {
        this.searchTerm = value;
      }),
    );
    this.subs.push(
      this.searchService.isSearchOpenCurrent.subscribe(value => {
        this.showSearch = value;
      }),
    );
    this.getRoles();
  }

  private getRoles(): void {
    this.roles$
      .pipe(
        filter(roles => !!roles),
        tap(roles => {
          this.isAdmin = roles.length > 0;
          if (roles.length === 1 && roles.includes(UserRolesEnum.ADMIN)) {
            this.adminBanner = 'Admin';
          } else {
            // Prettify role names: admin -> Admin, translation-manager -> Translation Manager
            this.adminBanner = roles.map(role => role.split('-').map(this.capitalizeFirstLetter).join(' ')).join(' | ');
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  changeLanguage(lang: string): void {
    this.currentLang = this.languages.filter(s => s.code === lang)[0];
    this.trackingService.addTrackingItem(TRACKING_ITEM.button, 'Change language to ' + lang + '[from]:' + this.trackingService.cleanRoute(this.router.url));
    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: 'merge',
      queryParams: { lang: this.currentLang.code },
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.subs.forEach(sub => {
      sub.unsubscribe();
    });
  }

  linkMatch(link: string) {
    return window.location.pathname === link;
  }

  goTo(view: 'leaders' | 'general'): void {
    if (view === 'leaders') {
      this.trackingService.addTrackingItem(
        TRACKING_ITEM.menu,
        'MyGrowth Leaders View:[from]' + this.trackingService.cleanRoute(this.router.url) + ':[to]' + '/leaders',
      );
      this.router.navigate(['/leaders'], { queryParamsHandling: 'preserve' });
    } else {
      this.trackingService.addTrackingItem(
        TRACKING_ITEM.menu,
        'MyGrowth General View:[from]' + this.trackingService.cleanRoute(this.router.url) + ':[to]' + '/',
      );
      this.router.navigate(['/'], { queryParamsHandling: 'preserve' });
    }
  }

  getHeaderScheme(link: string) {
    switch (link) {
      case '/how-it-works':
        this.siemensImage = 'deepblue';
        return 'sand';
      case '/help':
        this.siemensImage = 'deepblue';
        return 'sand';
      default:
        this.siemensImage = 'white';
        return 'deepblue';
    }
  }

  public toggleSearch(): void {
    this.searchService.updateSearchState(!this.showSearch);
  }

  showLeadersView(): boolean {
    return (this.translate.currentLang === 'en' || this.translate.currentLang === 'de') && !this.isAdminRoute;
  }
}
